import React, {useState} from 'react';
import CloseIcon from "../assets/images/close-icon.png";
import { Collapse, Spin, Tooltip, Drawer, Progress, message, Modal, Button, Checkbox,Table, Select,Form,Col,Row, Input, DatePicker, } from "antd";

const HistoricalCommunication = (props) => {

  const {setIsCommunicationContent, historicalCommData, setIsHistoricalComm, communicationContentHandler} = props;

return  <>
            <div className='w-80 mt-16'>
              View Historical Communication Details
              <Drawer
                title="Profile"
                placement={"right"}
                width={500}
                // onClose={onClose}
                // open={isHistoricalComm}
                maskClosable={false}
                closeIcon={
                  <Tooltip title="Close">
                    <img src={CloseIcon} alt="" />
                  </Tooltip>
                }
              ></Drawer>
            </div>
            <table border className='table-hist' >
              <thead>
                <tr >
                  <th>Policy No</th>
                  <th>Communication Type</th>
                  <th>Sent On</th>
                  <th>View Content</th>
                  <th>Delivery Status</th>
                </tr>
              </thead>
              <tbody> 
                {
                  historicalCommData?.length > 0 ?
                  historicalCommData?.map((item, index) => {
                    return( <tr key={index}>
                            <td>{item?.policyno}</td>
                            <td>{item?.commuType}</td>
                            <td>{item?.sentOn}</td>
                            <td className='eye-icon' 
                              onClick={() => {
                                setIsCommunicationContent(true);
                                communicationContentHandler(item);
                              }
                              }
                            >
                              <i className="bi bi-eye fs-2">
                              </i>
                            </td>
                            <td>{item?.deliveryStatus}</td>
                          </tr>)
                  })   
                :
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>
                      No historical communication data available.
                    </td>
                  </tr>
                }
              </tbody>
            </table>
        </>
};

export default HistoricalCommunication;