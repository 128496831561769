import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import apiCalls from "../../api/apiCalls";
import PopupAlert from "../popupAlert";

const SalesInteractionTab = () => {
  const [form] = Form.useForm();
  const { serviceId } = useParams();
  const [srvReqID, setSrvReqID] = useState(0);
  const [alertData, setAlertData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [previousInteractionDetails, setPreviousInteractionDetails] = useState([]);
  const loginInfo = useSelector((state) => state);

  useEffect(() => {
    fetchCustomerInteractionData();
  }, []);

  const fetchCustomerInteractionData = async () => {
    try {
      const response = await apiCalls.getPOSIndividualData(serviceId);
      if (response?.data?.servRequestIntract) {
        setSrvReqID(response.data.srvReqID || 0);
        bindInteractionData(response.data.servRequestIntract);
      }
    } catch (error) {
      console.error("Error fetching customer interaction data:", error);
    }
  };

  const bindInteractionData = (interactionDetails) => {
    if (interactionDetails && Array.isArray(interactionDetails)) {
      const filteredData = interactionDetails.filter(
        (interaction) => interaction.interactionWith === 2
      );

      const previousData = filteredData.map((interaction) => ({
        srvReqID: interaction.srvReqID || "N/A",
        cnctPersonNum: interaction.cnctPersonNum || "N/A",
        nxtIntrActDt: interaction.nxt_IntrAct_Dt
          ? new Date(interaction.nxt_IntrAct_Dt).toLocaleString()
          : "N/A",
        dispoType: interaction.dispoType || "N/A",
        email_Id: interaction.email_Id || "N/A",
        intrAct_Commt: interaction.intrAct_Commt || "N/A",
      }));

      setPreviousInteractionDetails(previousData);
    }
  };

  const handleMiscSave = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const savePayload = {
          SrvReqRefNo: serviceId,
          SrvReqID: srvReqID,
          InteractionWith: 2,
          CnctPersonNum: values.contactNumber,
          Email_Id: values.emailID,
          IntrAct_Commt: values.description,
        };

        try {
          const response = await apiCalls.SaveComplaintsInteractionDetails(savePayload);
          if (response?.status === 200) {
            setAlertTitle(response?.data?.header || "Success");
            setAlertData(response?.data?.message || "Interaction saved successfully.");
            setShowAlert(true);
            form.resetFields();

            const newInteraction = {
              srvReqID: srvReqID,
              cnctPersonNum: values.contactNumber,
              email_Id: values.emailID,
              intrAct_Commt: values.description,
              nxtIntrActDt: "N/A",
              dispoType: "N/A",
            };

            setPreviousInteractionDetails((prev) => [...prev, newInteraction]);
            form.resetFields();
          }
        } catch (error) {
          console.error("Error saving interaction details:", error);
        }
      })
      .catch((error) => {
        console.log("Validation failed:", error);
      });
  };

  return (
    <Form form={form} layout="vertical" className="sales-interaction-form">
      <Row gutter={[16, 16]} className="mb-16">
        <Col xs={24} md={9}>
          <Form.Item
            label="Contact Number"
            name="contactNumber"
            rules={[{ required: true, message: "Enter Contact Number" }]}
          >
            <Input placeholder="Contact Number" maxLength={10} />
          </Form.Item>
        </Col>
        <Col xs={24} md={9}>
          <Form.Item
            label="Email ID"
            name="emailID"
            rules={[{ required: true, message: "Enter Email ID" }]}
          >
            <Input placeholder="Email ID" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label="Interaction Details"
            name="description"
            rules={[{ required: true, message: "Enter Description" }]}
          >
            <TextArea rows={4} maxLength={1000} placeholder="Interaction Details" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Button type="primary" onClick={handleMiscSave}>
            Save
          </Button>
        </Col>
        <Col xs={24}>
          <table className="responsive-table">
            <thead>
              <tr>
                <th>Contact Number</th>
                <th>Email ID</th>
                <th>Interaction Details</th>
              </tr>
            </thead>
            <tbody>
              {previousInteractionDetails.length > 0 ? (
                previousInteractionDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.cnctPersonNum}</td>
                    <td>{item.email_Id}</td>
                    <td>{item.intrAct_Commt}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
      {showAlert && (
        <PopupAlert alertData={alertData} title={alertTitle} setShowAlert={setShowAlert} />
      )}
    </Form>
  );
};

export default SalesInteractionTab;
