import React, { useState, useEffect, useRef,} from "react";
import { Form, Spin, Button,Row,Col,Checkbox,message,Modal,Upload,Tooltip, } from "antd";
import DetailsForm from "../../utils/DetailsForm";
import PopupAlert from "../popupAlert";
import { policyInquiry } from "../../mainconfig";
import moment from 'moment';
import { connect, useSelector } from "react-redux";
import apiCalls from "../../api/apiCalls";






const InquiryPolicy = (props) => {
  // const { selectedCallType, selectedSubType, customerData, details, policyDetails,POSContactData , requestModeLU} = props;
  const {selectedSubType,customerData,POSContactData,setSelectedSubType,revivalForm,selectedCallType,selectedSubTypeId,SelectedSubTypeVal,details,requestModeLU,clientEnquiryData,policyDetails,uwDecisionLU} = props;
  const [form] = Form.useForm();
   const loginInfo = useSelector((state) => state);
   const [alertTitle, setAlertTitle] = useState("");
   const [alertData, setAlertData] = useState("");
   const [navigateTo, setNavigateTo] = useState("");
   const [showAlert, setShowAlert] = useState(false);
   const [isLoader,setIsLoader] = useState(false);
   const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
const [paymentAmount, setpaymentAmount] = useState("");
const [paymentAmountDate, setpaymentAmountDate] = useState("");
const [totalFundValue, setTotalFundValue] = useState('');
  const [policyStatus, setPolicyStatus] = useState("");
  //  console.log("selectedSubType",selectedSubType)

  // const [callType, setCallType] = useState('Policy Inquiry');
  // const [subType, setSubType] = useState('Policy Status');
  // const [comments, setComments] = useState('');

  // const handleCallTypeChange = (event) => {
  //   setCallType(event.target.value);
  // };

  // const handleSubTypeChange = (event) => {
  //   setSubType(event.target.value);
  // };

  // const handleCommentsChange = (event) => {
  //   setComments(event.target.value);
  // };
  const policyStatusList = [
    { descItem: 'EL', shortDesc: 'Extd Life', longDesc: 'Extended Maturity' },
    { descItem: 'EX', shortDesc: 'Expiry', longDesc: 'Expiry' },
    { descItem: 'FA', shortDesc: 'FP DthAppv', longDesc: 'FullyPaid Death Approval' },
    { descItem: 'FD', shortDesc: 'FP Dth Reg', longDesc: 'FullyPaid Death Register' },
    { descItem: 'FL', shortDesc: 'Free Look', longDesc: 'Free Look Cancellation' },
    { descItem: 'FR', shortDesc: 'FL Regstr', longDesc: 'Free Look Cancel Register' },
    { descItem: 'HC', shortDesc: 'Health Clm', longDesc: 'Health Claim' },
    { descItem: 'HP', shortDesc: 'PH RestPnd', longDesc: 'PH Reinstatement Pending' },
    { descItem: 'IF', shortDesc: 'In Force', longDesc: 'In Force' },
    { descItem: 'LA', shortDesc: 'Lapsed', longDesc: 'Contract Lapsed' },
    { descItem: 'LM', shortDesc: 'Lapsed Mat', longDesc: 'Lapsed Maturity' },
    { descItem: 'MA', shortDesc: 'Matured', longDesc: 'Contract Matured' },
    { descItem: 'MD', shortDesc: 'Mat Death', longDesc: 'Maturity Death' },
    { descItem: 'MO', shortDesc: 'MO', longDesc: 'Maturity-Settlmnt (No admin)' },
    { descItem: 'MP', shortDesc: 'Modify prp', longDesc: 'Component changes - modify prp' },
    { descItem: 'MR', shortDesc: 'MA Dth Reg', longDesc: 'Maturity Death Register' },
    { descItem: 'MS', shortDesc: 'Mat Stl', longDesc: 'Maturity-Settlmnt opt' },
    { descItem: 'MX', shortDesc: 'Setlmt-End', longDesc: 'Setlmt-End' },
    { descItem: 'PM', shortDesc: 'Prm Matury', longDesc: 'Premium Maturity' },
    { descItem: 'PO', shortDesc: 'Postpone', longDesc: 'Contract Postponed' },
    { descItem: 'PS', shortDesc: 'Proposal', longDesc: 'Contract Proposal' },
    { descItem: 'PU', shortDesc: 'Paid Up', longDesc: 'Paid Up Contract' },
    { descItem: 'PW', shortDesc: 'Wfwd Pndg', longDesc: 'Windforward Pending' },
    { descItem: 'RC', shortDesc: 'Repudiate', longDesc: 'Repudiate Death Claim' },
    { descItem: 'RD', shortDesc: 'Reg Death', longDesc: 'Registered Death Claim' },
    { descItem: 'RP', shortDesc: 'Red paidup', longDesc: 'Reduced Paid Up Contract' },
    { descItem: 'RS', shortDesc: 'Repd Setlm', longDesc: 'Repudiation Settlement Option' },
    { descItem: 'SD', shortDesc: 'Er Sr Appv', longDesc: 'Early Surrender Approval' },
    { descItem: 'SR', shortDesc: 'Surr Reg', longDesc: 'Surrender Register' },
    { descItem: 'SU', shortDesc: 'Surrender', longDesc: 'Contract Surrendered' },
    { descItem: 'TM', shortDesc: 'Terminate', longDesc: 'Termination Health Claim' },
    { descItem: 'TR', shortDesc: 'Terminated', longDesc: 'Contract Terminated' },
    { descItem: 'UW', shortDesc: 'U/W', longDesc: 'Underwriting' },
    { descItem: 'VA', shortDesc: 'Vest Appr', longDesc: 'Vesting Approval' },
    { descItem: 'VP', shortDesc: 'Vest pendg', longDesc: 'Vesting Pending' },
    { descItem: 'VR', shortDesc: 'Reg Vested', longDesc: 'Vesting Registered' },
    { descItem: 'WD', shortDesc: 'Withdrawn', longDesc: 'Contract Withdrawn' },
    { descItem: 'AF', shortDesc: 'Foreclose', longDesc: 'Foreclosure' },
    { descItem: 'AP', shortDesc: 'Add prpsl', longDesc: 'Component change - Add prpsl' },
    { descItem: 'CD', shortDesc: 'CF DOB Chg', longDesc: 'Cancelld frm Inception DOB chg' },
    { descItem: 'CF', shortDesc: 'CFI', longDesc: 'Cancelled from Inception' },
    { descItem: 'CP', shortDesc: 'CP', longDesc: 'Certificate Printed' },
    { descItem: 'CR', shortDesc: 'Regstd HLT', longDesc: 'Health Claim Resgistred' },
    { descItem: 'DC', shortDesc: 'Declined', longDesc: 'Contract Declined' },
    { descItem: 'DD', shortDesc: 'Dth Disc R', longDesc: 'Dth-Discontinued Registered' },
    { descItem: 'DF', shortDesc: 'Disc Forc', longDesc: 'Discontinuance Foreclosure' },
    { descItem: 'DH', shortDesc: 'Death Clm', longDesc: 'Approved Death Claim' },
    { descItem: 'DI', shortDesc: 'Dth Disc I', longDesc: 'Dth - Discontinued Invalid' },
    { descItem: 'DS', shortDesc: 'Er Sur Reg', longDesc: 'Early Surrender' },
    { descItem: 'DU', shortDesc: 'Discont', longDesc: 'Policy Discontinued' },
  ];
  const getPremiumStatus = (status, stausList) => {
    if (status) {
      const lowerCaseStatus = status.toLowerCase();
      const filteredStatus = stausList?.find(
        (ele) => ele?.descItem?.toLocaleLowerCase() === lowerCaseStatus
      );
      return filteredStatus?.longDesc || ''; // Return an empty string if not found
    }
    return '';
  };
  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate, "YYYYMMDD").format("DD/MM/YYYY");
    return formattedDate;
  };
  const getTransactionData = async (values) => {
    try {
      let empID = loggedUser?.allRoles[0]?.employeeID;
  
      // Await the fund value before proceeding
      const totalFundValue = await getFundValue();
  
      const val = await apiCalls.GetReceiptEnquiryForPolicy(
        details?.policyDetailsObj?.identifiers?.policyNo,
        empID
      );
  
      if (val?.data) {
        setpaymentAmount(
          val?.data?.responseBody?.receiptEnquiryDetailsList1[0]?.origamt || []
        );
        setpaymentAmountDate(
          val?.data?.responseBody?.receiptEnquiryDetailsList1[0]?.trandate || []
        );
      }
  
      const ptd = details?.policyDetailsObj?.premiumDetails?.ptd;
      const premiumAmount =
        details?.policyDetailsObj?.premiumDetails?.modelPremiumAmount;
      const planName =
        details?.policyDetailsObj?.planAndStatus?.planName ||
        customerData?.planName;
      const policyStatus =
        details?.policyDetailsObj?.planAndStatus?.policyStatus ||
        customerData?.policyStatus;
        const newPolicyStatus = getPremiumStatus(details?.policyDetailsObj?.planAndStatus?.policyStatus, policyStatusList);
        setPolicyStatus(newPolicyStatus);
      // if (
      //   [
      //     "policystatus",
      //     "premiumduedate",
      //     "premiumdueamount",
      //     "others",
      //     "policydetails",
      //   ].includes(selectedSubType)
      // ) {
        if (selectedSubType === "policystatus"|| selectedSubType === "premiumduedate"|| selectedSubType === "premiumdueamount"|| selectedSubType === "others") {
          return [
            {
              Status: "Create",
              TagName: "UserComments",
              TagValue: values?.UserComments || "",
            },
           
          ];
        }

        if (selectedSubType === "policydetails") {
          return [
            {
              Status: "Create",
              TagName: "PROD_NAME",
              TagValue: planName || "",
            },
            {
              Status: "Create",
              TagName: "STATUS",
               TagValue:newPolicyStatus
            },
            {
              Status: "Create",
              TagName: "DUE_DT",
              TagValue:convertDate(ptd )|| "",
            },
            {
              Status: "Create",
              TagName: "DUE_AMT",
              TagValue: premiumAmount || "",
            },
            {
              Status: "Create",
              TagName: "PAYMENT_AMT",
              TagValue:
                val?.data?.responseBody?.receiptEnquiryDetailsList1[0]?.origamt ||
                "",
            },
            {
              Status: "Create",
              TagName: "PAYMENT_DT",
              TagValue:
              convertDate(val?.data?.responseBody?.receiptEnquiryDetailsList1[0]?.trandate) ||
                "",
            },
            {
              Status: "Create",
              TagName: "FUND_VALUE",
              TagValue: totalFundValue || "NA",
            },
          ];
        }
     
      return [];
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      return [];
    }
  };
  
  

  const handleSubmit = () => {
    const values = form.getFieldsValue();
     saveRequest(values);
    
  };

  const handleInputChange =(e,item)=>{
  
  }

  const saveRequest = async (values) => {
    try {
      // Await the transaction data before proceeding
      const transactionData = await getTransactionData(values);
      const obj = {
        CallType: props?.selectedCallType, // Required
        SubType: props?.selectedSubTypeId, // Required
        RequestSource: loginInfo?.userProfileInfo?.profileObj?.sourceId || 0, // Required
        RequestChannel:values.requestchannel, // Required
        Category: 1,
        ApplicationNo:
          details?.policyDetailsObj?.identifiers?.applicationNo ||
          customerData?.applicationNo,
        DOB: convertDate(customerData?.dob),
        PolicyNo:
          details?.policyDetailsObj?.identifiers?.policyNo ||
          customerData?.policyNo, // Required
        CustomerId: 456,
        CustRole:1,
        policyStatus:
          details?.policyDetailsObj?.planAndStatus?.policyStatus ||
          customerData?.policyStatus,
        proposerName:
          details?.policyDetailsObj?.identifiers?.po_Name ||
          customerData?.po_Name,
        plan:
          details?.policyDetailsObj?.planAndStatus?.planName ||
          customerData?.planName,
  
        CreatedOn: new Date(),
        CreatedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
        CreatedUsrId: loginInfo?.userProfileInfo?.profileObj?.userName,
        ModifiedOn: new Date(),
        ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
        AssignedToRole: "", // POS
        AssignedByUser: 0,
        ReasonForChange: "",
        RequestDateTime: "",
        ReasonDelayed: "",
        CustSignDateTime: "",
        TransactionData: transactionData || [],
        Uploads: [],
        CommunicationRequest: [
          {
            SrvReqRefNo: "",
            TemplateID: "",
            CommType: 2,
            ReceipientTo: process.env.REACT_APP_RECEIPIENT_TO
              ? process.env.REACT_APP_RECEIPIENT_TO
              : clientEnquiryData?.rinternet,
            ReceipientCC: process.env.REACT_APP_RECEIPIENT_CC
              ? process.env.REACT_APP_RECEIPIENT_CC
              : clientEnquiryData?.rinternet,
            MobileNos: "",
            ScheduledTime: new Date(),
            CommBody: "",
            Attachments: null,
          },
          {
            SrvReqRefNo: "",
            TemplateID: "",
            CommType: 1,
            ReceipientTo: "",
            ReceipientCC: "",
            MobileNos: process.env.REACT_APP_RECEIPIENT_MOBILENO
              ? process.env.REACT_APP_RECEIPIENT_MOBILENO
              : clientEnquiryData?.rmblphone,
            ScheduledTime: new Date(),
            CommBody: "",
            Attachments: null,
          },
        ],
      };
  
      const response = await apiCalls.genericAPI(obj);
      if (response?.data) {
        setAlertTitle(response?.data?.header);
        setAlertData(response?.data?.message);
        setShowAlert(true);
      } else {
        message.error({
          content:
            response?.data?.responseBody?.errormessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    } catch (error) {
      console.error("Error saving request:", error);
      message.error("Failed to save the request. Please try again.");
    }
  };

  const getFundValue = async () => {
    try {
      const obj = {
        requestHeader: {
          source: "POS",
          carrierCode: "2",
          branch: "PRA",
          userId: loggedUser?.allRoles[0]?.employeeID,
          userRole: "10",
          partnerId: "MSPOS",
          processId: "POS",
          monthendExtension: "N",
          monthendDate: "18/10/2023",
        },
        requestBody: {
          policyno: details?.policyDetailsObj?.identifiers?.policyNo,
        },
      };
  
      const response = await apiCalls.GetFundValue(obj);
      if (response?.data) {
        const totalValue = response?.data?.responseBody?.fundValue?.reduce(
          (acc, ele) => acc + Number(ele.curuntval || 0), // Default to 0 if curuntval is undefined
          0
        );
  
        const formattedValue = isNaN(totalValue) || totalValue === 0 
          ? "Not Applicable" 
          : Number(totalValue).toLocaleString("en-IN");
  
        return formattedValue;
      } else {
        message.error({
          content:
            response?.data?.responseBody?.errormessage ||
            "Something went wrong, please try again!",
          className: "custom-msg",
          duration: 2,
        });
        return "Not Applicable"; // Default fallback
      }
    } catch (err) {
      console.error("Error fetching fund value:", err);
      return "Not Applicable"; // Default fallback in case of an error
    }
  };
  
  

  const renderDetailsForm = (formType) => {
    return (
      <DetailsForm
        data={policyInquiry[selectedSubType]?.[formType]}
        subType={selectedSubType}
        form={form}
        handleInputChange={handleInputChange}

      ></DetailsForm>
    );
  };

  return (
    <>
     <Form
    form={form}
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="center"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    onFinish={handleSubmit}
    autoComplete="off">
      
       <>
       {
        selectedSubType==="policystatus" &&  renderDetailsForm("BOE_Details")
       }
       {
        selectedSubType==="premiumduedate" &&  renderDetailsForm("BOE_Details")
       }
        {
        selectedSubType==="premiumdueamount" &&  renderDetailsForm("BOE_Details")
       }
        {/* {
        selectedSubType==="policybonddispatchdetails" &&  renderDetailsForm("BOE_Details")
       }
        {
        selectedSubType==="policystatusanddetails" &&  renderDetailsForm("BOE_Details")
       } */}
        {
        selectedSubType==="others" &&  renderDetailsForm("BOE_Details")
       }
       </>
       <div className="contact-details-btn">
       <Button type="primary"
        htmlType="submit" 
        className="primary-btn"
        >
         Submit
       </Button>{" "}
      </div>
    </Form>
      {showAlert && (
        <PopupAlert
          alertData={alertData}
          getAdvance={props.getAdvance}
          title={alertTitle}
          navigate={navigateTo}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}
    </>
    
  );
};

export default InquiryPolicy;

