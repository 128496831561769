import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { PartialComplaintData } from "../../mainconfig";
import DetailsForm from "../../utils/DetailsForm";
import {
  Button,
  Form,
  Spin,
  message,
} from "antd";
import moment from "moment";
import apiCalls from "../../api/apiCalls";
import PopupAlert from "../popupAlert";
import UploadIcon from "../../assets/images/upload.png";
import dayjs from "dayjs";
import RaiseRequirementPopup from '../RaiseRequirementPopup';


const PotentialComplaint = (props) => {
  const loginInfo = useSelector(state => state);
  const [form] = Form.useForm();
  const { selectedSubType, customerData,details,setSelectedSubType,typesForm, requestModeLU,clientEnquiryData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertData, setAlertData] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const suffix = <img src={UploadIcon} alt="" />;
  const [uploadFiles,setUploadFiles] = useState([]);
  const [raiseRequirementOpen, setRaiseRequirementOpen] = useState(false);
  const [requirementModalLoader, setRequirementLoader] = useState(false);
  const [raiseRequerimentList, setRaiseRequerimentList] = useState([]);
  const [isLoader,setIsLoader] = useState(false);
  const [vaildateSignature,setVaildateSignature]=useState(false);
  const [radioValuesToEnable, setRadioValuesToEnable] = useState({});
  const [showRaiseRequirementBtn,setShowRaiseRequirementBtn] = useState(false);
  const [ReRenderComponent, setReRenderComponent]=useState(false);
  const [showReasonDelayField,setShowReasonDelayField] = useState(false);
  const [UpdateState, setUpdateState] = useState(false);
  const shouldLog = useRef(true);
useEffect(()=>{
  if(shouldLog.current){
    shouldLog.current = false;
  //getClientEnquiry();
  }
  if(props?.EmailResponse?.IsEmailmanagent){
    if(selectedSubType==='unfairbusinesspractices'||selectedSubType==='proposalprocessing'||selectedSubType==='policyservicing'||selectedSubType==='survivalclaim'
      ||selectedSubType==='deathclaim'||selectedSubType==='uliprelated'||selectedSubType==='others'||selectedSubType==='complaintreopen'){
        PartialComplaintData[selectedSubType]?.BOE_Details?.forEach(element => {
          if(element?.label==="Request Mode"){
            form.setFieldsValue({
              // requestchannel: "Email"
              requestchannel: 4
            });
            element.disabled=true;
          }
      })
      PartialComplaintData[selectedSubType]?.BOE_Details?.forEach(element => {
        if(element?.name==="CustomerSigningDate"||element?.name==="BranchReceivedDate"||element?.name==="ValidateSignature"){
          element.hide=true;
        }
      })
      }
  }
  // if(props?.EmailResponse?.IsEmailmanagent && (selectedSubType==='unfairbusinesspractices'||selectedSubType==='proposalprocessing'||selectedSubType==='policyservicing'||selectedSubType==='survivalclaim'
  //   ||selectedSubType==='deathclaim'||selectedSubType==='uliprelated'||selectedSubType==='others'||selectedSubType==='complaintreopen')){
  //   PartialComplaintData[selectedSubType]?.BOE_Details?.forEach(element => {
  //     if(element?.label==="Request Mode"){
  //       form.setFieldsValue({
  //         // requestchannel: "Email"
  //         requestchannel: 4
  //       });
  //       element.disabled=true;
  //     }
  // })
  // PartialComplaintData[selectedSubType]?.BOE_Details?.forEach(element => {
  //   if(element?.name==="CustomerSigningDate"||element?.name==="BranchReceivedDate"||element?.name==="ValidateSignature"){
  //     element.hide=true;
  //   }
  // })

  // }
},[selectedSubType])

const getUploadFiles = (listOfUploadFiles) => {
  const updatedUploadList = listOfUploadFiles?.map((obj) => {
    // Rename IndexName to "CSD_Complaint"
    const { IndexName, ...newObject } = obj;
    return {
      ...newObject,
      IndexName: "CSD_Complaint"
    };
  });

  // Update the state with the new list
  setUploadFiles(updatedUploadList);
}
const disabledDate = (current) => {
  return current && current > dayjs().endOf("day"); // Can not select days before today and today
};

  const getClientEnquiry = ()=>{
    setIsLoading(true);

        let obj = {
          clientNumber:  customerData?.poClientID
        
    };
    let response = apiCalls.getClientEnquiry(obj,loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID);
    response
      .then((val) => {
        if (val?.data) {
          //setClientEnquiryData(val?.data?.responseBody);
          const res = val?.data?.responseBody;
        form.setFieldsValue({
          'mobileNo': res?.rmblphone,
          'whatsAppNo':  res?.rmblphone,
          'emailId': res?.rinternet
        });

          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const handleDropdownChange=(e,item)=>{
   
  }
  const handleRadioChange = (e,item) => {
    if(selectedSubType === 'unfairbusinesspractices'){
      setRadioValuesToEnable(prevState => ({
        ...prevState,
        [item.name]: e.target.value
      }));
    }

    setShowRaiseRequirementBtn(false);
  //  if((item?.label?.includes("Validate Signature")||item?.label?.includes("Signature Validated"))&&e.target.value==="no"){
  //   setShowRaiseRequirementBtn(true);
  //  }
  if (e.target.value === "no" && item.name === "ValidateSignature") {
    // setShowRaiseRequirementBtn(true);
    setVaildateSignature(true)
  }else if(item.name === "ValidateSignature"){
    setVaildateSignature(false)
  }

  if (e.target.value === "no" && item.name === "FundTransfer") {
    PartialComplaintData[selectedSubType]?.BOE_Details?.forEach((item, index) => {

      if (item.d_FundTransfer) {
        item.hide = true;
       
      }
    });
    setReRenderComponent(!ReRenderComponent);
  }else if(item.name === "FundTransfer" && e.target.value === "yes"){
    PartialComplaintData[selectedSubType]?.BOE_Details?.forEach((item, index) => {
      console.log("item",item)
      if (item.d_FundTransfer) {
        item.hide = false;
       
      }
    });
    form.setFieldsValue({
      ReasonForFundTransfer:"Renewal Payment"
    })
    setReRenderComponent(!ReRenderComponent);
  }
  };
  const convertDate = (inputDate) => {
    const formattedDate = moment(inputDate, "YYYYMMDD").format("DD/MM/YYYY");
    return formattedDate;
  };
  const date_diff_indays = function (date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  };
  const handleDateChange = (date, item) => {
    setShowReasonDelayField(false);
    if (item === "BranchReceivedDate") {
      let newDate = new Date();
      let todayDate = moment(newDate).format("MM/DD/YYYY");
      let selectDate = moment(date + 1).format("MM/DD/YYYY");
      const formFeilds = form.getFieldsValue()
      let customerSignDate = moment(formFeilds?.CustomerSigningDate + 1).format("MM/DD/YYYY");
      let dateDiffence = date_diff_indays(selectDate,customerSignDate)
      if(!formFeilds?.CustomerSigningDate||dateDiffence > 0){
        message.destroy();
        message.error({
          content: "Request Received Date can't be before the customer signing date.",
          className: "custom-msg",
          duration: 3,
        });
        form.setFieldsValue({BranchReceivedDate: ""})
      return;
      } else {
        PartialComplaintData [selectedSubType]?.BOE_Details?.forEach(element => {
          if(element?.label?.includes("Reason For Delayed Submission")&&selectDate < todayDate){
            element.hide= false;
            setShowReasonDelayField(true);
          }
          else if(element?.label?.includes("Reason For Delayed Submission")&&selectDate >= todayDate){
            element.hide= true;
            setShowReasonDelayField(false);
          }
        });
      }
      setUpdateState(!UpdateState)
    }
  };

  const getTransactionData = (values) => {
      return [
        { Status: "Create", TagName: "ComplaintForm", TagValue: values?.ComplaintForm || ""},
        { Status: "Create", TagName: "ComplaintDescription", TagValue: values?.ComplaintDescription || "" },
        { Status: "Create", TagName: "Comments", TagValue: values.Comments },
        { Status: "Create", TagName: "requestchannel", TagValue: values?.requestchannel },
        { Status: "Create", TagName: "CustomerSigningDate", TagValue: values?.CustomerSigningDate },
        { Status: "Create", TagName: "BranchReceivedDate", TagValue: values?.BranchReceivedDate },
        { Status: "Create", TagName: "ReasonForDelay", TagValue: values?.ReasonForDelay },
        { Status: "Create", TagName: "ValidateSignature", TagValue: values?.ValidateSignature }, 
        { Status: "Create", TagName: "IsComplaint", TagValue: '' },
        { Status: "Create", TagName: "EmailTo", TagValue: '' },
        { Status: "Create", TagName: "EmailCC", TagValue: '' },
        { Status: "Create", TagName: "EmailFrom", TagValue: '' },
        // { Status: "Create", TagName: "EmailSubject", TagValue: values?.subject },
        { Status: "Create", TagName: "EmailBod", TagValue: '' },
        
      ];
  };
  const handleSubmit = (values) => {
    if(values.CustomerSigningDate > values.BranchReceivedDate){
        message.destroy();
        message.error({
          content: " customer signing date  can't be greater than  Request Received Date.",
          className: "custom-msg",
          duration: 3,
        });
        form.setFieldsValue({
          CustomerSigningDate: "",
          CustomerSigningDate:""
        })
        setIsLoader(false);
        return
      }
    setIsLoading(true);
    const obj = {
      CallType: props?.selectedCallType, // Required
      SubType: props?.selectedSubTypeId, // Required
      RequestSource: loginInfo?.userProfileInfo?.profileObj?.sourceId || 0, // Required
      RequestChannel: values.requestchannel, // Required
      Category: 2,
      ApplicationNo:
      details?.policyDetailsObj?.identifiers?.applicationNo ||customerData?.applicationNo,
      DOB: convertDate(customerData?.dob),
      PolicyNo: details?.policyDetailsObj?.identifiers?.policyNo || customerData?.policyNo, // Required
      CustomerId: 456,
      "CustRole":values.custRole,
      policyStatus:
      details?.policyDetailsObj?.planAndStatus?.policyStatus || customerData?.policyStatus,
      proposerName: details?.policyDetailsObj?.identifiers?.po_Name || customerData?.po_Name,
      plan: details?.policyDetailsObj?.planAndStatus?.planName || customerData?.planName,
      CreatedOn: new Date(),
      CreatedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      CreatedUsrId: loginInfo?.userProfileInfo?.profileObj?.userName,
      ModifiedOn: new Date(),
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      AssignedToRole: "", //POS
      AssignedByUser: 0,
      ReasonForChange: "",
      RequestDateTime: new Date(),
      ReasonDelayed: values.resonfordelay,
      CustSignDateTime: values?.customersigningdate
      ? new Date(values?.customersigningdate)
      : new Date(),
      "TransactionData": getTransactionData(values) || [],
      "Uploads": uploadFiles || [],
      CurrentStatus:raiseRequirementOpen? "Reject":'',
     CommunicationRequest: [
        {
          SrvReqRefNo: "",
          TemplateID: "",
          CommType: 2,
          ReceipientTo:  process.env.REACT_APP_RECEIPIENT_TO ? process.env.REACT_APP_RECEIPIENT_TO : clientEnquiryData?.rinternet,
          ReceipientCC: process.env.REACT_APP_RECEIPIENT_CC ? process.env.REACT_APP_RECEIPIENT_CC : clientEnquiryData?.rinternet,
          MobileNos: "",
          ScheduledTime: new Date(),
          CommBody: "",
          Attachments: null,
        },
        {
          SrvReqRefNo: "",
          TemplateID: "",
          CommType: 1,
          ReceipientTo: "",
          ReceipientCC: "",
          MobileNos: process.env.REACT_APP_RECEIPIENT_MOBILENO ? process.env.REACT_APP_RECEIPIENT_MOBILENO : clientEnquiryData?.rmblphone,
          ScheduledTime: new Date(),
          CommBody: "",
          Attachments: null,
        },
      ],
  }

  if(raiseRequirementOpen){
    let ids = raiseRequerimentList?.filter((e) => e.status === true)?.map((e) => e.raiseReqId)
    obj.TransactionData.push({
      "Status": "Create",
      "TagName": "ReasonList_Key",
      "TagValue":  JSON.stringify(ids)
    })
      }
      if(props?.EmailResponse?.IsEmailmanagent){
        obj.TransactionData.push(
          {
          "Status": "Create",
          "TagName": "EmailResponseId",
          "TagValue": props?.EmailResponse?.EmailResponseId
          },
          {
            "Status": "Create",
            "TagName": "EmailSubject",
            "TagValue": props?.EmailResponse?.subject
            }
      )
       }
    let response = apiCalls.genericAPI(obj);
    response
      .then((val) => {
        if (val?.data) {
          // if(!val?.data?.srvReqRefNo){
            setAlertTitle(val?.data?.header);
            setAlertData(val?.data?.message);
            setShowAlert(true);
            setIsLoader(false);
          //   return
          // }
          //   setAlertTitle("Request Created Successfully");
          //   setAlertData(`${"Ticket No " + val?.data?.srvReqRefNo}`);
          //   setNavigateTo("/advancesearch");
          //   setShowAlert(true);
        } else {
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      }); 
  };

  const getRaiseRequirements = () => {
    setRaiseRequirementOpen(true);
    setRequirementLoader(true);
    let obj = {
      calltype: props?.selectedCallType,
      subtype: props?.selectedSubTypeId,
      Role:loginInfo?.userProfileInfo?.profileObj?.role=== 1 ? 1:0
    };
    let response = apiCalls.getRaiseRequirements(obj);
    response
      .then((val) => {
        if (val?.data) {
          setRaiseRequerimentList(val?.data);
          setRequirementLoader(false);
        } else {
          setRequirementLoader(false);
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setRequirementLoader(false);
      });
  };

  const handleRequirementSubmit = () => {
    const formData = form.getFieldValue();
//     setRequirementLoader(true);
 if(raiseRequirementOpen){
  handleSubmit(formData);
    }

  };
  const popupClose=()=>{
    setRaiseRequirementOpen(false)
  }

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      {!props?.EmailResponse?.IsEmailmanagent &&
        <Form
          form={form}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <DetailsForm
            data={PartialComplaintData[selectedSubType]?.BOE_Details}
            subType={selectedSubType}
            handleDropdownChange ={handleDropdownChange }
            handleDateChange ={handleDateChange }
            suffix={ suffix}
           form={form}
           getUploadFiles={getUploadFiles}
           disabledDate={disabledDate}
           handleRadioChange ={handleRadioChange}
           requestModeLU={requestModeLU}
          ></DetailsForm>
          <div className="contact-details-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="primary-btn"
                  disabled={vaildateSignature}
                >
                  Submit
                </Button>{" "}
                {/* {( loginInfo?.userProfileInfo?.profileObj?.role=== 1 ) && (
              <Button type="primary" className="primary-btn" onClick={()=>getRaiseRequirements()}>
                Raise Requirement
              </Button>
            )} */}
              </div>
        </Form>
        
}
{props?.EmailResponse?.IsEmailmanagent &&
        <Form
          form={form}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <DetailsForm
            data={PartialComplaintData[selectedSubType]?.BOE_Details}
            subType={selectedSubType}
            handleDropdownChange ={handleDropdownChange }
            handleDateChange ={handleDateChange }
            suffix={ suffix}
           form={form}
           getUploadFiles={getUploadFiles}
           disabledDate={disabledDate}
           handleRadioChange ={handleRadioChange}
           requestModeLU={requestModeLU}
          ></DetailsForm>
          <div className="contact-details-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="primary-btn"
                  disabled={vaildateSignature}
                >
                  Submit
                </Button>{" "}
                {/* {( loginInfo?.userProfileInfo?.profileObj?.role=== 1 ) && (
              <Button type="primary" className="primary-btn" onClick={()=>getRaiseRequirements()}>
                Raise Requirement
              </Button>
            )} */}
              </div>
        </Form>
        }
      {showAlert && (
        <PopupAlert
          alertData={alertData}
          title={alertTitle}
          getAdvance={props.getAdvance}
          navigate={navigateTo}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}
      <RaiseRequirementPopup raiseRequerimentList={raiseRequerimentList} raiseRequirementOpen={raiseRequirementOpen} requirementModalLoader={requirementModalLoader} handleRequirementSubmit={handleRequirementSubmit} popupClose={popupClose}/>

    </>
  );
};

export default PotentialComplaint;