import React, { useState, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import apiCalls from "../../api/apiCalls";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

const CustomerInteractionTab = () => {
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [isLoading, setIsLoading] = useState(false);
  const [previousInteractionDetails, setPreviousInteractionDetails] = useState([]);
  const loginInfo = useSelector((state) => state);
  const { serviceId } = useParams();
  const [srvReqID, setSrvReqID] = useState(0);

  const DispositionLU = [
    { label: "Contacted", value: "contacted" },
    { label: "Not Contacted", value: "notContacted" },
    { label: "Wrong No", value: "wrongNo" },
    { label: "Switched Off", value: "switchedOff" },
    { label: "Not Reachable", value: "notReachable" },
  ];

  const featureDateDisabled = (current) => current && current < dayjs().startOf("day");

  const validatePhoneNumber = (_, value) => {
    if (value && !/^[6-9]\d{9}$/.test(value)) {
      return Promise.reject("Mobile number must start with 6-9 and be 10 digits");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    getPOSIndividualData();
  }, []);

  const getPOSIndividualData = async () => {
    try {
      setIsLoading(true);
      const response = await apiCalls.getPOSIndividualData(serviceId);
      const srvReqIDFromData = response?.data?.srvReqID || 0;
      setSrvReqID(srvReqIDFromData);
      setFormFieldsData(response.data?.servRequestIntract || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setFormFieldsData = (interactions) => {
    const filteredInteractions = interactions.filter(
      (item) => item.interactionWith === 1
    );

    const formattedInteractions = filteredInteractions.map((item) => ({
      callTime: item.intrAct_Dt
      ? dayjs(item.nxt_IntrAct_Dt).format(dateFormat)
      : "N/A", 
      contactNo: item.cnctPersonNum || "N/A",
      disposition: item.dispoType || "N/A",
      interactionDetails: item.intrAct_Commt || "No interaction details provided", 
      nextInteractionDate: item.nxt_IntrAct_Dt
        ? dayjs(item.nxt_IntrAct_Dt).format(dateFormat)
        : "N/A",
    }));

    // Remove duplicates by checking contactNo
    const uniqueInteractions = [...new Map(formattedInteractions.map(item => [item.contactNo, item])).values()];

    setPreviousInteractionDetails(uniqueInteractions); // Update without duplicates
  };

  const handleMiscSave = () => {
    form.validateFields().then((values) => {
      const formattedNextInteractionDate = values.nextInteractionDate
        ? dayjs(values.nextInteractionDate).format(dateFormat)
        : "";

      const obj = {
        SrvReqRefNo: serviceId,
        SrvReqID: srvReqID,
        InteractionWith: 1,
        CnctPersonNum: values?.contactNumber,
        DispoType: values?.disposition,
        IntrAct_Commt: values?.description,  // Ensure description is sent here
        Nxt_IntrAct_Dt: values?.nextInteractionDate,
        IntrAct_Dt : values?.callTime
      };

      apiCalls.SaveComplaintsInteractionDetails(obj).then((response) => {
        if (response?.data?.length > 0) {
          console.log("API response data:", response.data); // Check the API response data
          setFormFieldsData(response.data); // Update the interaction data after save
          form.resetFields(); // Reset the form
        }
      }).catch(error => {
        console.error("Error saving interaction:", error);
      });
    });
  };

  return (
    <Form form={form} className="customer-interaction-form" layout="vertical">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={9}>
          <Form.Item label="Call Date" name="callTime" rules={[{ required: true }]}>
          <DatePicker format={dateFormat} disabledDate={featureDateDisabled} style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col xs={24} md={9}>
          <Form.Item
            label="Contact Number"
            name="contactNumber"
            rules={[{ required: true }, { validator: validatePhoneNumber }]}>
            <Input maxLength={10} />
          </Form.Item>
        </Col>

        <Col xs={24} md={9}>
          <Form.Item label="Disposition" name="disposition">
            <Select options={DispositionLU} placeholder="Select Disposition" />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Interaction Details" name="description" rules={[{ required: true }]}>
            <TextArea rows={4} maxLength={1000} />
          </Form.Item>
        </Col>

        <Col xs={24} md={9}>
          <Form.Item label="Next Interaction Date" name="nextInteractionDate">
            <DatePicker format={dateFormat} disabledDate={featureDateDisabled} style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Button type="primary" onClick={handleMiscSave}>
            Save
          </Button>
        </Col>

        <Col xs={24}>
          <table className="responsive-table">
            <thead>
              <tr>
                <th>Call Date</th>
                <th>Disposition</th>
                <th>Contact No</th>
                <th>Interaction Details</th>
                <th>Next Interaction Date</th>
              </tr>
            </thead>
            <tbody>
              {previousInteractionDetails.length > 0 ? (
                previousInteractionDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.callTime || "N/A"}</td>
                    <td>{item.disposition || "N/A"}</td>
                    <td>{item.contactNo || "N/A"}</td>
                    <td>{item.interactionDetails || "N/A"}</td>
                    <td>{item.nextInteractionDate || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </Form>
  );
};

export default CustomerInteractionTab;
