import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { SearchOutlined } from "@ant-design/icons";

import { Bar } from "react-chartjs-2";
import {
  Card,
  Checkbox,
  Switch,
  Flex,
  Input,
  Progress,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Statistic,
  Table,
  message,
  Spin,
  Select,
  Button,
} from "antd";
import apiCalls from "../../api/apiCalls";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/images/close-icon.png";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EmailManagementAdmin = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
  const [showTotalPages, setShowTotalpages] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionList,setSelectionList] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [UsersData, setUsersData] =useState([]);
  const [ReassigneData, setReassigneData]  =useState([]);
  const [EmailManagementDashBoardData, setEmailManagementDashBoardData] =useState([]);
  const [attendence, setAttendence] = useState(false);
  
  const [ClosedCasesWithinTAT, setClosedCasesWithinTAT] = useState('');
  const [ClosedCasesOnTAT, setClosedCasesOnTAT] = useState('');
  const [ClosedCasesOutsideTAT, setClosedCasesOutsideTAT] = useState('');
  const [OpenCasesWithinTAT, setOpenCasesWithinTAT] = useState('');
  const [OpenCasesOnTAT, setOpenCasesOnTAT] = useState('');
  const [OpenCasesOutsideTAT, setOpenCasesOutsideTAT] = useState('');
  const [tableKey, setTableKey] = useState(0); // Key to force remount
  const [isLOBChecked, setIsLOBChecked] = useState(false);
  const [UnAttendedMailsWithinTAT, setUnAttendedMailsWithinTAT] = useState('');
  const [UnAttendedMailsOutsideTAT, setUnAttendedMailsOutsideTAT] = useState('');
  const [isNoData,setIsNoData]=useState('');
  const [isUsersData,setIsUsersData] = useState([]);

  
  useEffect(() => {
    EmailManagementDashBoardFilters('')
}, []);
  

  const emailSource = [];
  const dateFormat = "DD/MM/YYYY";
  const formItemLayout2 = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };



  const columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      sorter: (a, b) => a.userName?.length - b.userName?.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Open Emails",
      dataIndex: "openEmailsCount",
      // key: "serviceNo",
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <Space size="middle">

         <a className="hyperLink"  onClick={() => handleAction(record)}>{record?.emailWithinTat+record?.emailOutsideTAT} </a>


        </Space>
      ),
    },
    {
      title: "Within TAT",
      dataIndex: "emailWithinTat",
    },
    // {
    //   title: "On TAT",
    //   dataIndex: "onTat",
    // },
    {
      title: "Outside TAT",
      dataIndex: "emailOutsideTAT",
    },
    // {
    //   title: "Tickets Pending Closure",
    //   dataIndex: "emailAgeing",
      

    // },

    // {
    //   title: "Attendance Status",
    //   dataIndex: "attendance",
    
    //   render: (_, record, index) => (
    //     <Space size="middle">
    //       <Switch
    //         checked={record.attendance}
    //         onChange={(checked) => handleAttendanceChange(checked,index, record)}
    //         checkedChildren="Present"
    //         unCheckedChildren="Absent"
    //       />
    //     </Space>
    //   ),

    //   sortDirections: ["descend", "ascend"],
    // },
  ];

  const handleAction=(item)=>{
    let obj={
      isUser:true,
      item:item
    }
    navigate(`/emailuser/${item?.usrID}`,{ state: obj});
  }

  const handleAttendanceChange = ( )=>{
    let Feilds = form3.getFieldsValue();
    if(!Feilds?.selectedUser){
      message.destroy();
      message.warning("Select User ");
      return;
    }
    setIsLoading(true);
    // let data = [...EmailManagementDashBoardData];
    // data[index].attendance = val2
    // setEmailManagementDashBoardData(data);

    let obj={
      UserName:Feilds?.selectedUser,
      Status:isLOBChecked,
  
    }
    let response = apiCalls.EmailMgAttendence(obj);
    response
    .then((val) => {

      if (val?.data) {
        
        message.success({
          content:
            val?.data?.responseBody?.errormessage ||
            "Record Updated Successfully",
          className: "custom-msg",
          duration: 2,
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error({
          content:
            val?.data?.responseBody?.errormessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    })
    .catch((err) => {
      // setIsLoader(false);
    });
  }
  const reassignTicketsFrom = (val)=>{

    let ReassigneData =  UsersData.filter((ele)=>{
      return ele.value !== val
    });
     setReassigneData(ReassigneData);
     form2.setFieldsValue({
      ReassignTo:''
     })
  }

//   const filterUsersData = (val) => {
//     const filteredData = isUsersData?.filter((item) => item.usrID === val);
//     form.setFieldsValue({ emailSource: val });
//     setIsUsersData(filteredData);
//     return;
// }

  const EmailManagementDashBoardFilters = (val) => {

    // let ReassigneData =  UsersData.filter((ele)=>{
    //   return ele.value !== val
    // });
    //  setReassigneData(ReassigneData);
    setIsLoading(true);
    let obj = {
      'RoleId':'20',
      'UserName':val
    }
    let response = apiCalls.EmailManagementDashBoardFilters(obj);
    response
    .then((val) => {
      //
      if (val?.data) {   
        let transformedData =val?.data?.users?.map((item) => ({
          ...item,
          label:item.userName,
          value:item.usrID,
          
        }));

        setUsersData(transformedData);
        const newData = val?.data?.emailMags?.map((item, i) => ({
          ...item, 
          key: i,  
        }));
        setEmailManagementDashBoardData(newData);
        setTableKey((prevKey) => {
          return prevKey + 1
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error({
          content:
            val?.data?.responseBody?.errormessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    })
    .catch((err) => {
      // setIsLoader(false);
    });
  }


const ReAssign = ()=>{
  // if(selectionList?.length === 0){
  //   message.destroy();
  //   message.warning("Please select atleast one record");
  //   return;
  // }

  // if(selectionList?.length > 1){
  //   message.destroy();
  //   message.warning("Max Allowed One");
  //   return;
  // }
  // ReassignTo
  // selectedUser


  let data = form2.getFieldsValue();
  if(!data?.ReassignTo){
    message.destroy();
    message.warning("Select User to Reassign");
    return;
  }
  if(!data?.selectedUser){
    message.destroy();
    message.warning("Select User");
    return;
  }
  let obj={
    UserName:data?.selectedUser,
    ReassignUserName:data?.ReassignTo
  }
  let response = apiCalls.EmailAssignToUser(obj);
  response
  .then((val) => {
    //
    if (val?.data) {
      message.success({
        content:
          val?.data?.responseBody?.errormessage ||
          "Assignment Successfull",
        className: "custom-msg",
        duration: 2,
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      message.error({
        content:
          val?.data?.responseBody?.errormessage ||
          "Something went wrong please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  })
  .catch((err) => {
    // setIsLoader(false);
  });
}
const TATCasesForEmailMng = (userNameID) => {
  setIsLoading(true);
 // let response = apiCalls.TATCasesForEmailMng();
 let obj={
  "ReceivedFromDt": '',
  "ReceivedToDt": '',
  "AssignedTo": userNameID || null,
  "DisplayScreen": userNameID ? "EmailAdmin" : null,
  "PolicyNo": "",
  "SenderMailID": "",
  "Status": "NEW"
  // "emailSource":values?.emailSource ? values?.emailSource?.split('').map(Number):'',
  // "IsNLPRespGen":values?.IsNLPRespGen ? values?.IsNLPRespGen  :'',
  // "AddressedToMultipleIDs": values?.AddressedToMultipleIDs ? values?.AddressedToMultipleIDs  :false,
  // "CallType":values?.CallType ? values.CallType:'',
  // "SubType":values?.SubType ? values.SubType:'',
  // "Category":cat
  // "Category":values?.Category ?   values?.Category?.split('').map(Number):'',
}
let response = apiCalls.getEmailManagementFilter(obj);
  response
  .then((val) => {
    if (val?.data) {
      let transformedData =val?.data?.userSummary?.map((item) => ({
        ...item,
        label:item.userName,
        value:item.usrID,
        
      }));

      setUsersData(transformedData);
      setIsUsersData(val?.data?.userSummary);
      if(Array.isArray(val?.data?.emailSummary)){
        val?.data?.emailSummary.forEach((ele)=>{
          if(ele.emailTat=== "OpenCasesWithinTAT"){
            setOpenCasesWithinTAT(ele.tatCount)
          }else if(ele.emailTat=== "OpenCasesOutsideTAT"){
            setOpenCasesOutsideTAT(ele.tatCount)
          }else if(ele.emailTat=== "ClosedCasesWithinTAT"){
            setClosedCasesWithinTAT(ele.tatCount)
          }else if(ele.emailTat=== "ClosedCasesOutsideTAT"){
            setClosedCasesOutsideTAT(ele.tatCount)
          }
          else if(ele.emailTat=== "UnAttendedMailsWithinTAT"){
            setUnAttendedMailsWithinTAT(ele.tatCount)
          }else if(ele.emailTat=== "UnAttendedMailsOutsideTAT"){
            setUnAttendedMailsOutsideTAT(ele.tatCount)
          }

        })
      }
      setIsLoading(false);
    } else {
        setIsNoData("nodata");
        setIsLoading(false);
        message.error({
          content:
            val?.data?.responseBody?.errormessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
    }
  })
  // .then((val) => {
  //   if (val?.data) {

  //     if(Array.isArray(val?.data?.closedCases)){
  //       val?.data?.closedCases.forEach((ele)=>{
  //         if(ele.category === "WithinTAT"){
  //           setClosedCasesWithinTAT(ele)
  //         }else if(ele.category=== "OnTAT"){
  //           setClosedCasesOnTAT(ele)
  //         }else if(ele.category=== "OutsideTAT"){
  //           setClosedCasesOutsideTAT(ele)
  //         }

  //       })
  //     }
  //     if(Array.isArray(val?.data?.openCases)){
  //       val?.data?.openCases.forEach((ele)=>{
  //         if(ele.category === "WithinTAT"){
  //           setOpenCasesWithinTAT(ele)
  //         }else if(ele.category=== "OnTAT"){
  //           setOpenCasesOnTAT(ele)
  //         }else if(ele.category=== "OutsideTAT"){
  //           setOpenCasesOutsideTAT(ele)
  //         } 
  //       })
  //     }

  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //     message.error({
  //       content:
  //         val?.data?.responseBody?.errormessage ||
  //         "Something went wrong please try again!",
  //       className: "custom-msg",
  //       duration: 2,
  //     });
  //   }
  // })
  .catch((err) => {
    setIsLoading(false);
  });
}


  const EmailManagementUsersData = () => {
    setIsLoading(true);
    let obj = {
      'RoleId':'21',
      'UserName': loggedUser?.userName === "emailuser" ?  "EmailUser1" : loggedUser?.userName
    }
    let response = apiCalls.EmailManagementDashBoardFilters(obj);
    response
    .then((val) => {
      //
      if (val?.data) {
     
        let transformedData =val?.data?.users?.map((item) => ({
          ...item,
          label:item.userName,
          value:item.usrID,
          
        }));

        setUsersData(transformedData);
        setReassigneData(transformedData)
        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error({
          content:
            val?.data?.responseBody?.errormessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
    })
    .catch((err) => {
      // setIsLoader(false);
    });
  }

  const handleLOBSwitchChange = (checked) => {
    setIsLOBChecked(checked);
  };


  const selectionType = {


    
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectionList(selectedRows);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  useEffect(() => {
    TATCasesForEmailMng()
    EmailManagementUsersData()
  }, []);

  return (
    <>
      <div className="email-container">
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
            <Spin spinning={isLoading}>
              <div className="m-20" >
              
          {/* <Card  className="mb-16">
  <Form
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="left"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    form={form}
   
    autoComplete="off"
  >
    <Row gutter={[12, 12]} className="mb-16">
      <Col xs={24} sm={12} md={6} lg={6} xxl={6}>
        <div>
          <Form.Item
            label={
              <span>
                From Date
                <sup>*</sup>
              </span>
            }
            name="FormDate"
            className="inputs-label mb-0"
            rules={[
              {
                required: false,
                message: "Select a From Date",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
            />
          </Form.Item>
        </div>
      </Col>
      <Col xs={24} sm={12} md={6} lg={6} xxl={6}>
        <div>
          <Form.Item
            label={
              <span>
                To Date
                <sup>*</sup>
              </span>
            }
            name="ToDate"
            className="inputs-label mb-0"
            rules={[
              {
                required: true,
                message: "Select a To Date",
              },
            ]}
          >
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              className="cust-input"
              format={dateFormat}
            />
          </Form.Item>
        </div>
      </Col>

      <Col xs={24} sm={12} md={6} lg={6} xxl={6}>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            className="primary-btn"
            htmlType="submit"
            // Call the searchData function when the button is clicked
          >
            Search
          </Button>
        </Form.Item>
      </Col>
    </Row>
  </Form>
</Card> */}

                {/* <Row gutter={[16, 16]}>
                <Col xs={12} sm={12} md={12} lg={12} xxl={12}>
                <div>
                <table className="table table-bodered">
                  <thead>
                    <tr >
                    <th>Open Cases  </th>
                    <th colSpan={1}>  {OpenCasesWithinTAT?.count + OpenCasesOnTAT?.count + OpenCasesOutsideTAT?.count}</th>
                    <th>%</th>
                    </tr>
                   
             
                  </thead>
                  <tbody>
                  

                    <tr>
                      <td>Within TAT</td>
                      <td>{OpenCasesWithinTAT?.count}</td>
                      <td>{OpenCasesWithinTAT?.percentage}</td>
                    </tr>

                    <tr>
                      <td>On TAT</td>
                      <td>{OpenCasesOnTAT?.count}</td>
                      <td>{OpenCasesOnTAT?.percentage}</td>
                    </tr>

                    <tr>
                      <td>Outside TAT</td>
                      <td>{OpenCasesOutsideTAT?.count}</td>
                      <td>{OpenCasesOutsideTAT?.percentage}</td>
                    </tr>
                    

                  </tbody>
                </table>
              </div>

                 
                   </Col>
                  



                  <Col xs={12} sm={12} md={12} lg={12} xxl={12}>
                  <div>
                <table className="table table-bodered">
                  <thead>
                    <tr>
                    <th >Closed Cases  </th>
                    <th colSpan={1}>  {ClosedCasesWithinTAT?.count + ClosedCasesOnTAT?.count + ClosedCasesOutsideTAT?.count }  </th>
                    <th>%</th>
                    </tr>
                   
             
                  </thead>
                  <tbody>
                   

                    <tr>
                      <td>Within TAT</td>
                      <td>{ClosedCasesWithinTAT?.count}</td>
                      <td>{ClosedCasesWithinTAT?.percentage}</td>
                    </tr>
                    <tr>
                      <td>On TAT</td>
                      <td>{ClosedCasesOnTAT?.count}</td>
                      <td>{ClosedCasesOnTAT?.percentage}</td>
                    </tr>
                    <tr>
                      <td>Outside TAT</td>
                      <td>{ClosedCasesOutsideTAT?.count}</td>
                      <td>{ClosedCasesOutsideTAT?.percentage}</td>
                    </tr>

                    

                  </tbody>
                </table>
              </div>
              </Col>
              </Row> */}  

<Row gutter={[16, 16]}>
                <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
                <div>
                {
                    isNoData!=="nodata" ?  
                    <table className="table table-bodered">
                 
                    <thead>
                      <tr >
                      <th>New Emails  </th>
                      <th colSpan={1}>  {UnAttendedMailsWithinTAT + UnAttendedMailsOutsideTAT }  </th>
                      {/* <th>%</th> */}
                      </tr>
                     
               
                    </thead>
                    <tbody>
                      {/* <tr>
                        <td> Category  </td>
                        <td>Count</td>
                        <td>%</td>
                      </tr> */}
  
  
                      <tr>
                        <td>Within TAT</td>
                        <td>{UnAttendedMailsWithinTAT}</td>
                        {/* <td>{UserOpenCasesWithinTAT?.percentage}</td> */}
                      </tr>
  
                      {/* <tr>
                        <td>On TAT</td>
                        <td>{UserOpenCasesOnTAT?.count}</td>
                        <td>{UserOpenCasesOnTAT?.percentage}</td>
                      </tr> */}
  
                      <tr>
                        <td>Outside TAT</td>
                        <td>{UnAttendedMailsOutsideTAT}</td>
                        {/* <td>{UserOpenCasesOutsideTAT?.percentage}</td> */}
                      </tr>
                    </tbody>
                  </table> :<div className="text-center">No Data Found</div>
                  }
               
              </div>

                   </Col>

                  <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
                  <div>
                  {
                    isNoData!=="nodata" ?   <table className="table table-bodered">
               
                    <thead>
                    <tr>
                    <th colspan="1">Attended Mails</th>
                    {/* <th></th> */}
                    <th> {(OpenCasesWithinTAT + OpenCasesOutsideTAT)}</th>
                    {/* <th> {UserClosedCasesWithinTAT?.count + UserClosedCasesOutsideTAT?.count }</th> */}
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Within TAT</td>
                        <td>{OpenCasesWithinTAT}</td>
                        {/* <td>{UserClosedCasesWithinTAT?.percentage}</td> */}
                      </tr>
                      {/* <tr>
                        <td>On TAT</td>
                        <td>{UserClosedCasesOnTAT?.count}</td>
                        <td>{UserClosedCasesOnTAT?.percentage}</td>
                      </tr> */}
                      <tr>
                        <td>Outside TAT</td>
                        <td>{OpenCasesOutsideTAT}</td>
                        {/* <td>{UserClosedCasesOutsideTAT?.percentage}</td> */}
                      </tr>
                    </tbody>
                  </table> :<div className="text-center">No Data Found</div>}
              
              </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={8} xxl={8}>
  <div>
    {isNoData !== "nodata" ? (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan="2" style={{ height: "37px" }}>Closed Emails</th>
            <th>{ClosedCasesWithinTAT + ClosedCasesOutsideTAT}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Within TAT</td>
            <td>{ClosedCasesWithinTAT}</td>
          </tr>
          <tr>
            <td>Outside TAT</td>
            <td>{ClosedCasesOutsideTAT}</td>
          </tr>
        </tbody>
      </table>
    ) : (
      <div className="text-center">No Data Found</div>
    )}
  </div>
</Col>


              </Row>
              </div>
              

           
              <div
                className="table-container table-responsive email-managedashboard"
                style={{ background: "#f2f2f2", padding: "30px" }}
              >
                <div id="feild-email">
           
                  <Form
                      form={form1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
  <div style={{ width: "50%" }}>
  <Form.Item name="selectedUser" label="User Name" {...formItemLayout2}>
    <Select
      showSearch
      allowClear
      className="cust-input calltype-select"
      maxLength={100}
      placeholder="Select User"
      options={UsersData}
      name="emailSource"
      onChange={(value) => {
        if (value) {
          // Handle the selected case
          form.setFieldsValue({ emailSource: value });
          TATCasesForEmailMng(value);
        } else {
          // Handle the deselect case
          form.setFieldsValue({ emailSource: null });
          TATCasesForEmailMng(null);
        }
      }}
    ></Select>
  </Form.Item>
</div>




                    {/* <div style={{ width: "50%" }}>
                      <Form.Item name='ReassignTo' label="Reassign Tickets to" {...formItemLayout2}>
                        <Select
                          showSearch
                          className="cust-input calltype-select"
                          maxLength={100}
                          placeholder="Select User"
                          options={ReassigneData}
                          name="emailSource"
                          onChange={(value) => {
                            form.setFieldsValue({ emailSource: value });
                          }}
                        ></Select>
                      </Form.Item>
                    </div> */}
                  </Form>
                </div>

                <Table
                  key={tableKey}
                  columns={columns}
                  dataSource={isUsersData}
                  // rowSelection={{
                  //   type: 'checkbox',
                  //   ...selectionType,
                  // }}
                  // rowKey={(record) => record.key}
                  // rowClassName={() => 'editable-row'
                    
                  // }

                  x={true}
                  pagination={{
                    pageSize: 10,
                    defaultPageSize:  10,
                    total: { showTotalPages },
                  }}
                />


                
              </div>
 
              <div id="feild-email"  className="text-center" style={{  padding: "0px 30px" }}>
        
                    {/* <div style={{ width: "50%" }}>
                      <Form.Item label="Reassign Tickets to " {...formItemLayout2}>
                        <Select
                          showSearch
                          className="cust-input calltype-select"
                          maxLength={100}
                          placeholder="Select User"
                          options={emailSource}
                          name="emailSource"
                          onChange={(value) => {
                            form.setFieldsValue({ emailSource: value });
                          }}
                        ></Select>
                      </Form.Item>
                    </div> */}

                    
{/*             
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="primary-btn advance-btn"
                      onClick={() =>  ReAssign()}
                    >
                      Re-Assign 
                    </Button> */}
           
               
                </div>



                <div className="m-20" >
              
              <Card  className="mb-16">
      <Form
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        form={form2}
        onFinish={ReAssign}
       
        autoComplete="off"
      >
        <Row gutter={[12, 12]} className="mb-16">
          <Col xs={24} sm={12} md={8} lg={8} xxl={8}>
            <div>
            <Form.Item name='selectedUser' label="Reassign Tickets From" {...formItemLayout2}>
                        <Select
                          showSearch
                          className="cust-input calltype-select"
                          maxLength={100}
                          placeholder="Select User"
                          options={UsersData}
                          name="emailSource"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select User',
                            },
                          ]}

                          onChange={(value) => {
                            form.setFieldsValue({ emailSource: value });
                            reassignTicketsFrom(value)
                            // EmailManagementDashBoardFilters(value)
                          }}
                        ></Select>
                      </Form.Item>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xxl={8}>
            <div>
            <Form.Item name='ReassignTo' label="Reassign Tickets to" {...formItemLayout2}>
                        <Select
                          showSearch
                          className="cust-input calltype-select"
                          maxLength={100}
                          placeholder="Select User"
                          options={ReassigneData}
                          name="emailSource"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select User',
                            },
                          ]}
                          onChange={(value) => {
                            form.setFieldsValue({ emailSource: value });
                          }}
                        ></Select>
                      </Form.Item>
            </div>
          </Col>
    
          <Col xs={24} sm={12} md={6} lg={6} xxl={6}>
            <Form.Item className="mb-0">
              <Button
                type="primary"
                className="primary-btn"
                htmlType="submit"
                // Call the searchData function when the button is clicked
              >
                Re-Assign
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
    </div>




    <div className="m-20" >

              <Card  className="mb-16">
      <Form
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        form={form3}
        onFinish={handleAttendanceChange}
       
        autoComplete="off"
      >
        <Row gutter={[16, 16]} className="mb-16">
          <Col xs={24} sm={12} md={8} lg={8} xxl={8}>
            <div>
            <Form.Item name='selectedUser' label="Mark Absent/ Present" {...formItemLayout2}>
                        <Select
                          showSearch
                          className="cust-input calltype-select"
                          maxLength={100}
                          placeholder="Select User"
                          options={UsersData}
                          name="emailSource"
                          rules={[
                            {
                              required: true,
                              message: 'Please Select User',
                            },
                          ]}

                          onChange={(value) => {
                            form.setFieldsValue({ emailSource: value });
                           
                          }}
                        ></Select>
                      </Form.Item>
                      
            </div>
          </Col>
          <Col xs={24} sm={12} md={3} lg={3} xxl={3}>
          <Switch
                      checked={isLOBChecked}
                      onChange={handleLOBSwitchChange}
                      checkedChildren="Absent"
                      unCheckedChildren="Present"
                      style={{height:"100%"}}
                    />
          </Col>
    
          <Col xs={24} sm={12} md={6} lg={6} xxl={6}>
            <Form.Item className="mb-0">
              <Button
                type="primary"
                className="primary-btn"
                htmlType="submit"
                // Call the searchData function when the button is clicked
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
    </div>





                
            </Spin>
          </Col>
        </Row>
      
      </div>


    </>
  );
};

export default EmailManagementAdmin;