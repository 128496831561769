import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Switch, message, Select, Modal, DatePicker, Collapse,Spin, Radio,Tooltip } from 'antd';
import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import apiCalls from "../../api/apiCalls";
import moment from 'moment';
import ReactQuill from 'react-quill';
import DetailsForm from "../../utils/DetailsForm";
import PopupAlert from "../popupAlert";
import { useSelector } from "react-redux";
import CloseIcon from "../../assets/images/close-icon.png";
//import { data } from 'jquery';


const ErrorPopup = ({ errors, open, onClose }) => {
  return (
    <Modal
      title="Errors"
      open={open}
      destroyOnClose={true}
      width={1200}
      closeIcon={false}
      footer={null}
    >
      <Spin spinning={false}>
        <div>
          <div className="requirement">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Error Code</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {errors.length > 0 ? (
                  errors.map((error, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{error.errorCode}</td>
                      <td>{error.value}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div className="text-center">
                        <span>No data available</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="contact-details-btn">
            <Button
              type="primary"
              className="primary-btn"
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

const ViewCustomerComplaintTab = (props) => {
  const [form] = Form.useForm();
  const [EmailFromCustomer, setEmailFromCustomer] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [CALL_TyPES, setCALL_TyPES] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [requestModeLU, setRequestModeLU] = useState([]);
  const [subTypeLU, setSubTypeLU] = useState(null);
  const [SelectedSubTypeVal, setSelectedSubTypeVal] = useState(null);
  const [selectedCallType, setSelectedCallType] = useState("");
  const [selectedSubTypeId, setSelectedSubTypeId] = useState("");
  const [selectedSubType, setSelectedSubType] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [complaintTypes, setComplaintTypes] = useState([]);
  const [complaintDesc, setComplaintDesc] = useState([]);
  const [complaintSource, setComplaintSource] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cityOptionsList, setCityOptionsList] = useState([]);
  const [alertTitle, setAlertTitle] = useState("");
  const loginInfo = useSelector(state => state);
  const [alertData, setAlertData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  let { serviceId } = useParams();
  const [IRDATokenNumber, setIRDATokenNumber] = useState("");
  const [RegisterComplaintID, setComplaintID] = useState(0);
  const [ResponseForCustomer, setResponseForCustomer] = useState('');
  const [EmailSubject, setEmailSubject] = useState('');
  const [srvReqID, setSrvReqID] = useState(0);
  const [isEmailButton, setIsEmailButton] = useState(false);
  const [pinCode, setPinCode] = useState(0);
  const [ReceipientTo, SetReceipientTo] = useState('');
  const [ReceipientCC, SetReceipientCC] = useState('');
  const [PolicyNo, SetPolicyNo] = useState('');
  
  const [showPincodeField, setShowPincodeField] = React.useState(true);


  const TypeOfComplaintDropdown = [
    { label: 'Service-related complaint', value: 'Service-related complaint' },
    { label: 'Sales-related complaint', value: 'Sales-related complaint' },
  ];

  const [errors, setErrors] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [emailBody, setEmailBody] = useState([]);
  const [fromEmail, setFromEmail] = useState('');
  const [emailCreatedDate, setEmailCreatedDate] = useState('');
  const handleViewUserAgeing = async() => {
      setShowViewModal(true);

      let obj = {
        emailClassCTST: {
          "SrvReqID": srvReqID // Sends the `srvReqID` in the request body.
        }
      };
    
      // Makes an API call to fetch email details.
      const response = await apiCalls.getEmailResponseDetailsAPI(obj); // Fetch email data
    const rawEmailContent = response.data.emailClassify[0]?.body || "No content available.";
    const cleanEmailContent = rawEmailContent.replace(/<[^>]*>/g, "").replace(/\s+/g, " ").trim(); // Remove HTML tags
    const fromEMail = response.data.emailClassify[0]?.from;
    const createdDate = response.data.emailClassify[0]?.receivedDateTime;
    setEmailBody(cleanEmailContent);
    setFromEmail(fromEMail);
    setEmailCreatedDate(createdDate);
      
    };
  const customerComplaintsObj = {}

  useEffect(() => {
    getCTST();
    setClientResponse();
    getPOSIndividualData();
  }, []);

  const getPOSIndividualData = async () => {
    try {
        const val = await apiCalls.getPOSIndividualData(serviceId);
        const srvReqIDFromData = val?.data?.srvReqID; // Extract srvReqID from val.data

        // Update state and form values with srvReqID
        setSrvReqID(srvReqIDFromData);
        setFormFieldsData(val);
        props?.setTransactionDocData(val?.data);
    }
    catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors appropriately
    }
};

const setFormFieldsData = (val) => {
  const serviceRequestTransectionData = val?.data?.serviceRequestTransectionData || [];
// Find the object with tagName "EmailSubject"
const emailSubjectObj = serviceRequestTransectionData.find(
  (item) => item.tagName === "EmailSubject"
);
const emailSubjectObjLine = serviceRequestTransectionData.find(
  (item) => item.tagName === "subjectLine"
);
// const subjectLine1 = emailSubjectObj?.tagValue || "";
const subjectLine = emailSubjectObjLine !== undefined ? emailSubjectObjLine.tagValue :emailSubjectObj?.tagValue
if (val?.data) {
    // Define a mapping of tag names to form field names
    const fieldMapping = {
        'Type_of_Complaint': 'typeofcomplaint',
        'Complaint_Description': 'description',
        'Sent_Template': 'sentTemplate',
        'complaintClosed':"complaintClosed"  ,
        'complaintDecision':'complaintDecision',
        'subjectLine':subjectLine,
        // Add other mappings as needed
    };
    form.setFieldsValue({
      'subjectLine':subjectLine,
    })

      // Initialize an object to hold form field values
      const formValues = {};

      // Iterate over the transaction data and populate the formValues object
      val?.data?.serviceRequestTransectionData?.forEach((ele) => {
          const fieldName = fieldMapping[ele.tagName];
          if (fieldName) {
              formValues[fieldName] = ele.tagValue;
          }
      });

      // Set the form field values
      form.setFieldsValue(formValues);
  }
};

  const getCTST = () => {
    let obj = {
      "MasterRequest": [
        "CALL_TYP", "SUB_TYP", "REQST_MODE",
      ]
    }
    let CTST = apiCalls.ctst(obj);
    CTST.then((val) => {
      setMasterData(val.data);
      const transformedData = transformData(val.data, "CALL_TYP");
      const transformedSubType = transformData(val.data, "SUB_TYP");
      const rquestModeData = transformData(val.data, "REQST_MODE");
      setCALL_TyPES(transformedData);
      setRequestModeLU(rquestModeData);

      if (props?.serviceRequestData) {
        setSelectedCallType(props?.serviceRequestData?.callType);
        subTypeDropdown(props?.serviceRequestData?.callType, props?.serviceRequestData?.subType, val.data);
        props?.serviceRequestData?.serviceRequestTransectionData?.forEach(element => {
          customerComplaintsObj[element.tagName] = element.tagValue;
        });
        form.setFieldsValue({
          description: customerComplaintsObj?.ComplaintDescription,
        });
      }
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      message.destroy();
      message.error({
        content: err?.data?.responseBody?.errormessage,
        className: "custom-msg",
        duration: 2,
      });
    });
  }


  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];
  
  const module = {
    toolbar: toolbarOptions,
  }

  const transformData = (data, keyy) => {
    const filteredData = data?.filter((ele) => ele.key === keyy);
    return filteredData[0]?.value?.map((item, index) => ({
      ...item,
      label: item.mstDesc,
      value: item.mstID
    }));
  };

  const convertDate = (inputDate) => {
    return moment(inputDate, 'YYYYMMDD').format('DD/MM/YYYY');
  };

  const convertDateForIGMS = (inputDate) => {
    const date = new Date(inputDate);
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
 
  const handleEmailSend = async () => {
    const formData = form.getFieldValue();
    let obj = {
      "Subject": formData?.subjectLine,
      "ReceiptTo": formData?.senderEmail,
      "SrvReqID": props?.serviceRequestData?.srvReqID,
      "MailBody": ResponseForCustomer,
      "SenderID":'communications@futuregenerali.in',
      "SenderName": "Future Generali",
        
    };
    
    try {
      // Send the API request and wait for the response
      const response = await apiCalls.SendComplaintCommunication(obj);
      
      // Check the status code of the response
      if (response.data === true) {
        // Show success message
        setAlertTitle('Email Sent Successfully');
        setAlertData( 'Email Sent Successfully');
        setShowAlert(true);
        setIsLoading(false);
      } else {
        setAlertTitle('Something went wrong');
        setAlertData( 'Something went wrong');
        setShowAlert(true);
        setIsLoading(false);
      }
    } catch (error) {
      setAlertTitle('Something went wrong');
      setAlertData( 'Something went wrong');
      setShowAlert(true);
      setIsLoading(false);
    }
  };


  const handleComplaintsTicketCloseButton = async () => {
    const formData = form.getFieldValue();
    
    try {
      // Send the API request and wait for the response
      const response = await apiCalls.UpdateComplaintTicketStatusAPI(serviceId);
      
      // Check the status code of the response
      
        // Show success message
        setAlertTitle('Ticket Status Updated Successfully');
        setAlertData( 'Ticket Status Updated Successfully');
        setShowAlert(true);
        setIsLoading(false);
      
    } catch (error) {
      setAlertTitle('Something went wrong');
      setAlertData( 'Something went wrong');
      setShowAlert(true);
      setIsLoading(false);
    }
  };
  
  const setClientResponse = async () => {
    let obj = {
      "policyNo": props?.serviceRequestData?.policyNo,
      "applicationNo": '',
      "dob": props?.serviceRequestData?.dob
    }
    let response = await apiCalls.getHeaderParameters(obj);
    //const rquestModeData = transformData(val.data, "REQST_MODE");
    if (response?.data?.responseHeader?.issuccess) {
      SetPolicyNo(response?.data?.responseBody?.identifiers?.policyNo);
      form.setFieldsValue({
        policyHolderName: response?.data?.responseBody?.identifiers?.po_Name,
        PolicyNumber: response?.data?.responseBody?.identifiers?.policyNo,
        ProposalNumber: response?.data?.responseBody?.identifiers?.policyNo,
        //OrganizationName: 'Future Generali.in'
       // Mode: rquestModeData
      });
      const clientNumber = {
        clientNumber: response?.data?.responseBody?.identifiers?.po_ClientID
      };
      const clientEnquiryResponse = await apiCalls.getClientEnquiry(clientNumber,loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID);
      if (clientEnquiryResponse.data.responseHeader.issuccess) {
        SetReceipientTo(clientEnquiryResponse?.data?.responseBody?.rinternet);
        SetReceipientCC(clientEnquiryResponse?.data?.responseBody?.rinternet)
        const dob = clientEnquiryResponse.data.responseBody.clTdob;
        const newDob = convertDate(dob);
        const dobDate = new Date(newDob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDifference = today.getMonth() - dobDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dobDate.getDate())) {
          age--;
        }
        let pinCode = clientEnquiryResponse?.data?.responseBody?.cltpcode;
        if (pinCode.length === 6) {
         // searchLocationn(pinCode);
         setPinCode(pinCode)
        }
        form.setFieldsValue({
          Email: clientEnquiryResponse?.data?.responseBody?.rinternet,
          FirstName: clientEnquiryResponse?.data?.responseBody?.lgivname,
          MobileNumber: clientEnquiryResponse?.data?.responseBody?.rmblphone,
          IsSeniorCitizen: age > 60 ? 1 : 0,
          senderEmail: process.env.REACT_APP_RECEIPIENT_TO ? process.env.REACT_APP_RECEIPIENT_TO : clientEnquiryResponse?.data?.responseBody?.rinternet,
          Pincode: clientEnquiryResponse?.data?.responseBody?.cltpcode
         // OrganizationName: "Future Generali.in"
        });
      }
    }
  }

  const onSearch = (e) => {}

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const subTypeDropdown = async (value, subType, allData) => {
    let SUB_TYP = masterData?.length > 0 ? masterData?.filter((ele) => ele.key === "SUB_TYP") : allData?.filter((ele) => ele.key === "SUB_TYP");
    let data = SUB_TYP[0]?.value?.filter((ele) => ele?.mstParentID === value);
    let transformedData = data?.map((item) => ({
      ...item,
      label: item.mstDesc,
      value: item.mstID
    }));
    setSubTypeLU(transformedData);
    if (props?.serviceRequestData) {
      form.setFieldsValue({ callType: value, subType: subType });
      handleSubTypeChange(subType, transformedData);
    }
    setClientResponse();
  }
  const sentTemplate = (value) => {
    if (value === 'unregistered') {
      setIsEmailButton(true);
      setResponseForCustomer(`<p>Dear Sender,<br /><br />Kindly send email from your registered mailbox for further processing of your request.<br />Please note we will not be able to service any request from unregistered mail box.</p>
    <br/> <p>Regards,<br />Team FG</p>`);
    setEmailSubject('Unregistered Email')

    } else if (value === 'acknowledge') {
      setIsEmailButton(true);
      setResponseForCustomer(`<p>Dear Sender,</p>
    <p><br />We acknowledge your mail for policy number &lt;asdasd&gt;. <br />Please note you will receive a separate mail(s) for request raised by you.</p>
    <p><br />Regards,<br />Team FG</p>`)
    setEmailSubject('Acknowledged Email')
    }
    else if (value === 'closure') {
      setIsEmailButton(true);
      setResponseForCustomer(`<p>Dear Sender,</p>
    <p><br />You are hereby informed that your request cannot be processed due to below reasons</p>
    <p><br />Regards,<br />Team FG</p>`)
    setEmailSubject('Closure Email')
    }
  }

  const DisableRcaTab = (value, option) => {
    if (option.value === 'Service-related complaint') {
      //props?.setRCATabHide(false);
      props?.setMiscellingCalculatorTabHide(true);
    } else {
      //props?.setRCATabHide(true);
      props?.setMiscellingCalculatorTabHide(false);
    }
  };

  const handleCallTypeChange = (value, obj) => {
    if (obj?.isCallType) {
      setSelectedCallType(obj.mstID);
      form.setFieldsValue({ subType: null })
      setSubTypeLU(null);
      setSelectedSubType(null);
      subTypeDropdown(obj.mstID);
    } else {
      let CALL_TYP = masterData?.length > 0 ? masterData?.filter((ele) => ele.key === "CALL_TYP") : '';
      let SUB_TYP = masterData?.length > 0 ? masterData?.filter((ele) => ele.key === "SUB_TYP") : '';
      let transformedData = SUB_TYP[0]?.value.filter((ele) => (ele.mstParentID === obj?.mstID)).map((ele) => ({
        ...ele,
        label: ele.mstDesc,
        value: ele.mstID
      }));
      setSubTypeLU(transformedData);
      let slectedCALL_TYP = CALL_TYP[0].value?.find((ele) => ele.mstID === obj?.mstID);
      setSelectedCallType(+slectedCALL_TYP?.mstID);
      setSelectedSubTypeId(obj?.mstID);
      transformedData?.map((key, index) => {
        if (key.mstID === obj?.mstID) {
          const modifiedDesc = key.mstDesc?.replace(/[^\w]/g, "").toLowerCase();
          setSelectedSubType(modifiedDesc);
          setSelectedSubTypeVal(key.mstDesc);
        }
      });
      form.setFieldsValue({ callType: slectedCALL_TYP?.mstDesc });
    }
  };

  const formItemLayout2 = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleOk = async () => {
    setIsLoading(true);
    setIsModalVisible(false);
    let response = form.getFieldValue();
    let stateName = response.state;
    let districtName = response.DistrictId;

    const filteredStateValue = stateOptions?.find(
      (state) => state.label?.toLowerCase() === stateName?.toLowerCase()
    )?.value;
    
        
    
    const filteredDistrictValue = districtOptions?.find(
      (dist) => dist.label?.toLowerCase() === districtName?.toLowerCase()
    )?.value;
    
    // const filteredCityValue = cityOptions?.find(
    //   (city) => city.label?.toLowerCase() === response['CityID'][0]?.toLowerCase()
    // )?.value;

    const filteredCityValue2 = cityOptions?.find(
      (city) => city?.geo_PCIN_SrNo == response?.CityID
    )?.geo_PCIN_SrNo;

    // const filteredCityValue1 = cityOptions?.find(
    //   (city) => response.CityID.contains(city.label?.toLowerCase())
    // )?.value;

    let  obj = {
     Ack_Officer_Designation: "",
      Ack_Officer_Name: "SM",
      Addressed_To_Insurer: "Y",
      BOC_Or_Collection_Number: "",
      Branch_Code: "MU01",
      Broker_License_Number: response?.BrokerLicenseNumber,
      Certificate_Number: "",
      Cheque_Number: response?.ClaimPaymentChequeNumber,
      Claim_Clsr_Additional_Info: response?.ClaimClsrAdditionalInfo,
      Claim_Intimation_Date: response?.ClaimIntimationDate ? convertDateForIGMS(response?.ClaimIntimationDate) : "",
      Claim_Number: response?.ClaimNumber,
      Claim_Payment_Cheque_Date: response?.ClaimPaymentChequeDate ? convertDateForIGMS(response?.ClaimPaymentChequeDate) : "",
      Claim_Payment_Cheque_Number: response?.ClaimPaymentChequeNumber,
      Claim_Payment_Date: response?.ClaimPaymentDate ? convertDateForIGMS(response?.ClaimPaymentDate) : "",
      Claim_Received_Amount: response?.ClaimReceivedAmount,
      Claim_Requested_Amount: response?.ClaimRequestedAmount,
      Complaint_Against_Type_Id: 1,
      Complaint_Date: convertDateForIGMS(new Date()),
      Complaint_Description_Id: response?.ComplaintDescriptionId,
      Complaint_Details: response?.ComplaintDetails,
      Complaint_Receipt_Date: response?.ComplaintReceiptDate ? convertDateForIGMS(response?.ComplaintReceiptDate): "",
      Complaint_Status_Id: response?.ComplaintStatusId,
      Complaint_Type_Id: response?.ComplaintTypeId,
      Date_Of_Honoring_Service: response?.DateOfHonoringService ? convertDateForIGMS(response?.DateOfHonoringService): "",
      District_Id: filteredDistrictValue,
     // City_ID: 0,
      E_mail: response?.Email,
      Entity_Complaint_Ref_Number: response?.EntityComplaintRefNumber,
      First_Name: response?.FirstName,
      IRDA_Token_Number: response?.OriginalIRDATokenNum,
      Insurance_Type_Id: 1,
     Insurer_Resolution_Letter_date: response?.InsurerResolutionLetterdate,
      Intermediary_License_Number: response?.IntermediaryLicenseNumber,
      Intermediary_Name: "",
      Is_Complainant_Informed: response?.IsComplainantInformed,
      Mobile_Number: response?.MobileNumber,
      Mode: RegisterComplaintID == 0 ? 1 : 2,
      Option: 1,
      Organization_Name: response?.OrganizationName,
      Original_IRDA_Token_Num: response?.OriginalIRDATokenNum,
      Others_Clsr_Additional_Info: "",
      PolicyHolder_Or_Claimant_Name: "",
      Policy_Number: response?.PolicyNumber,
      Policy_Type_Id: 1,
      Premium_Payment_Ref_Number: response?.PremiumPaymentRefNumber,
      Proposal_Or_Cover_Note_Number: "",
      Receipt_Number: response?.ReceiptNumber,
      Remarks: response?.Remarks,
      Request_IRDAI_For_Closure: response?.RequestIRDAIForClosure,
      Source_Of_Complaint: response?.SourceOfComplaint,
      State_Id: filteredStateValue,
      Status_Change_Date: RegisterComplaintID == 0 ?  "" : convertDateForIGMS(new Date()),
      Trans_Login_Id: "FGLICADMIN",
      Type_of_disposal: "",
      User_Type: "I",
      Policy_Proposal_Cert_ClaimNumber: response?.PolicyNumber,
      Identifier_Type: 1,
      Wrong_Insurer_Selected: response?.WrongInsurerSelected,
      Is_Senior_Citizen: response?.IsSeniorCitizen,
      SrvReqRefNo:serviceId,
      RegisterComplaintID : RegisterComplaintID,
      Pincode:response?.Pincode,
      City_ID:filteredCityValue2 === undefined ? 0 : filteredCityValue2,
      SrvReqID : props.serviceRequestData.srvReqID
    };
    let registerResponse ='';
    if(RegisterComplaintID == 0){
      setIsLoading(true);
      registerResponse = await apiCalls.saveRegisterComplaintAPI(obj)
    }
    else{
      setIsLoading(true);
      registerResponse = await apiCalls.updateRegisterComplaintAPI(obj)

    }
     
     //console.log(response12)
     if(registerResponse?.data?.errors?.length > 0){
        setErrors(registerResponse.data.errors);
        setShowPopup(true);
        setIsModalVisible(true);
        setIsLoading(false);
        return;
     }
     else{
      if(RegisterComplaintID == 0){
        let obj1 = {
          SrvReqRefNo: serviceId,
          ReceipientTo:  process.env.REACT_APP_RECEIPIENT_TO ? process.env.REACT_APP_RECEIPIENT_TO : ReceipientTo,
          ReceipientCC: process.env.REACT_APP_RECEIPIENT_CC ? process.env.REACT_APP_RECEIPIENT_CC : ReceipientCC,
          PolicyNumber: props?.serviceRequestData?.policyNo
        }
        setAlertTitle('IRDA Token Number generated successfully ');
        setAlertData( registerResponse.data.irdaTokenNumber);
        setShowAlert(true);
        setIsLoading(false);
        await apiCalls.SendIGMSComplaintCommunication(obj1); 
      }
      else{
        setAlertTitle('Token Updated');
        setAlertData(`${registerResponse?.data?.irdaTokenNumber} - Updated to Acknowledged`);
        setShowAlert(true);
        setIsLoading(false);
      }
     // setAlertData("IRDA Token Number generated successfully " + registerResponse.data.irdaTokenNumber);
      return
     }
    setIsModalVisible(false);
    setIsLoading(false);
  }


  const handleCancel = () => 
    {
    form.resetFields();
   setIsModalVisible(false); 
}

  const mapToSelectOptions = (data) => {
    return data.map(item => ({
      value: item.statusID,
      label: item.status_desc, // changed 'name' to 'label' for consistency with other mappings
    }));
  };
  
  const mapToSelectComplaintTypeOptions = (data) => {
    return data.map(item => ({
      value: item.compl_Type_ID,
      label: item.compl_Type,
    }));
  };

  const getTransactionData = (values) => {
    const tags = [
        { key: 'callType', tagName: 'Call_Type' },
        { key: 'subType', tagName: 'Sub_Type' },
        { key: 'typeofcomplaint', tagName: 'Type_of_Complaint' },
        { key: 'description', tagName: 'Complaint_Description' }, // Assuming description is the complaint detail
        { key: 'sentTemplate', tagName: 'Sent_Template' },
        { key: 'complaintClosed', tagName: 'complaintClosed' },
        { key: 'complaintDecision', tagName: 'complaintDecision' },
        { key: 'subjectLine', tagName: 'subjectLine',tagvalue:values?.subject, },
    ];

    // Handle the switch state for 'Tag Complaint'
    const tagComplaint = values.tagComplaint ? 'Yes' : 'No';

    // Create the transaction data with tags and handle 'Tag Complaint' separately
    return tags.map(tag => ({
        Status: 'Create',
        TagName: tag.tagName,
        TagValue: values[tag.key] || ''
    })).concat([{
        Status: 'Create',
        TagName: 'Tag_Complaint',
        TagValue: tagComplaint
    }]);
};


  const handleMiscSave = () => {
    const formData = form.getFieldValue();
    const filteredCallType = masterData?.find((item) => item?.key === "CALL_TYP");
    let selectedCall;
    if(typeof (formData?.callType) === "number"){
      selectedCall = filteredCallType.value.find((item) => item?.mstID === props?.serviceRequestData?.callType)

    }
    if(typeof (formData?.callType) === "string"){
      selectedCall = filteredCallType.value.find((item) => item?.mstDesc === formData?.callType)

    }
    form.validateFields().then(async (values) => {
        const obj = {
            SrvReqRefNo: serviceId,
            SrvReqID: srvReqID,
            TransactionData: getTransactionData(values) || [],
            ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
            RequestDateTime: "2024-01-10T00:00:00",
            CustSignDateTime: "2024-01-10T00:00:00",
            CallType: selectedCall?.mstID,
            SubType: formData.subType
        };

        try {
            const response = await apiCalls.genericAPI(obj); // Await the API call
            if (response?.status === 200) { // Ensure response.status is available in your API response
                setAlertTitle(response?.data?.header);
                setAlertData(response?.data?.message);
                setShowAlert(true);
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    }).catch((error) => {
        console.log("Validation failed:", error);
    });
};
  
  const mapToSelectComplaintDescriptionOptions = (data) => {
    return data.map(item => ({
      value: item.compl_Desc_ID,
      label: item.compl_Desc,
    }));
  };
  
  const mapToSelectSourceofComplaintnOptions = (data) => {
    return data.map(item => ({
      value: item.compltSrc_ID,
      label: item.compltSrc,
    }));
  };
  
  const mapToSelectStateOptions = (data) => {
    return data.map(item => ({
      value: item.state_ID,
      label: item.stateNm,
    }));
  };

  const mapToSelectCityOptions = (data) => {
    return data.map(item => ({
      value: item.city_ID,
      label: item.cityNm,
    }));
  };

  const mapToSelectCityOptionsList = (data) => {
    return data.map(item => ({
      value: item.city_ID,
      label: item.cityNm,
      dist_ID : item.district_ID,
    }));
  };

  const removeAlphabets = (str) => {
    let response =  str.replace(/[^\d]/g, ''); // Replace everything that's not a digit with an empty string
    return `C${response}`
  };
  
  const mapToSelectDistrictOptions = (data) => {
    return data.map(item => ({
      value: item.district_ID,
      label: item.districtNm,
    }));
  };

  const searchLocationn = (pinCode) => {
    setIsLoading(true);
    apiCalls.searchIGMSLocation(pinCode)
    .then((response) => {
      setIsLoading(false);
      if (response?.data?.length > 0) {
        //const { stateName, district, region } = response.data;
        const allCities = response?.data.map((item) => item.geoPCIN)
        setCityOptions (allCities);
        let stateName = response?.data[0]?.stateNm;
        let districtName = response?.data[0]?.districtNm;

        const filteredStateValue = stateOptions?.find(
          (state) => state.label?.toLowerCase() === stateName?.toLowerCase()
        )?.value;
        
        const filteredStateValueDescription = stateOptions?.find(
          (state) => state.label?.toLowerCase() === stateName?.toLowerCase()
        )?.label;
        
            
        
        const filteredDistrictValue = districtOptions?.find(
          (dist) => dist.label?.toLowerCase() === districtName?.toLowerCase()
        )?.value;
        
        const filteredDistrictValueDescription = districtOptions?.find(
          (dist) => dist.label?.toLowerCase() === districtName?.toLowerCase()
        )?.label;
        

      form.setFieldsValue({
        state: response?.data[0]?.stateNm,
        DistrictId: response?.data[0]?.districtNm,
        CityID: allCities,
        Pincode: pinCode
      });
      setShowPincodeField(false);
      }
   else {

    form.setFieldsValue({
      state: '',
      DistrictId: '',
      CityID: '',
      Pincode: pinCode
    });
          message.error({
            content: response?.data?.responseBody?.errormessage || "Invalid PinCode Entered , please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
        setShowPincodeField(false);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error({
          content: "An error occurred while fetching location data.",
          className: "custom-msg",
          duration: 2,
        });
        setShowPincodeField(false);
      });
  };
  

  
 
  const onBlurInput = (value, item) => {
    if (item.label === "Pincode") {
      // Reset state, district, and city if PINCode is empty
      if (!value) {
        form.setFieldsValue({
          CityID: '',
          state: '',
          DistrictId: ''
        });
      } else if (value.length === 6) {
        searchLocationn(value);
      }
    }
  };

  


  const showIGMSData = async (event) => {
    event.preventDefault();
    setIsModalVisible(true);
  

    try {
      const [response1, response2, 
        response3, 
        response4, response5, response6, response7, response8] = await Promise.all([
        apiCalls.getIGMSMastersData('STATUS_MASTER'),
        apiCalls.getIGMSMastersData('COMPLAINT_TYPE_MASTER'),
         apiCalls.getIGMSMastersData('COMPLAINT_DESCRIPTION_MASTER'),
        apiCalls.getIGMSMastersData('SOURCE_OF_COMPLAINT_MASTER'),
        apiCalls.getIGMSMastersData('DISTRICT'),
        apiCalls.getIGMSMastersData('STATE'),
        apiCalls.getIGMSComplaintsAPI(serviceId),
        apiCalls.getIGMSMastersData('CITY'),
      ]);
  
      setStatusOptions(mapToSelectOptions(response1.data));
      setComplaintTypes(mapToSelectComplaintTypeOptions(response2.data));
       setComplaintDesc(mapToSelectComplaintDescriptionOptions(response3.data));
      setComplaintSource(mapToSelectSourceofComplaintnOptions(response4.data));
      setDistrictOptions(mapToSelectDistrictOptions(response5.data)); // updated to setDistrictOptions
      setStateOptions(mapToSelectStateOptions(response6.data)); // updated to setStateOptions
      setCityOptions(mapToSelectCityOptions(response8.data))
      setCityOptionsList(mapToSelectCityOptionsList(response8.data));

      //const cities = response8.data
      if(response6.data.length > 0){
        searchLocationn(pinCode)
      }
      const cityName = response8.data.filter((item) => item.city_ID === response7.data.city_ID)[0]?.cityNm;
      form.setFieldsValue({
        //Console.log(response7.entity_Complaint_Ref_Number)
        EntityComplaintRefNumber: response7?.data?.entity_Complaint_Ref_Number
            ? response7.data.entity_Complaint_Ref_Number
            : removeAlphabets(serviceId),
        InsurerResolutionLetterdate: response7?.data?.insurer_Resolution_Letter_date,
        IsComplainantInformed: response7?.data?.is_Complainant_Informed,
        OriginalIRDATokenNum: response7?.data?.irdA_Token_Number,
        RequestIRDAIForClosure: response7?.data?.request_IRDAI_For_Closure,
        PolicyProposalClaimNumber: response7?.data?.policy_Proposal_Cert_ClaimNumber,
        state:response7?.data?.state_Id,
        DistrictId :response7?.data?.district_Id,
         ComplaintDescriptionId : response7?.data?.complaint_Description_Id,
        ComplaintDetails:response7?.data?.complaint_Details,
        ComplaintStatusId : response7?.data?.complaint_Status_Id,
        ComplaintTypeId : response7?.data?.complaint_Type_Id,
        SourceOfComplaint : response7?.data?.source_Of_Complaint,
        Pincode : response7?.data?.pincode,
        CityID : cityName,
        //ComplaintReceiptDate: convertDateForIGMS(response7?.data?.complaint_Receipt_Date),
        //DateOfHonoringService: convertDateForIGMS(response7?.data?.Date_Of_Honoring_Service),
        //ComplaintReceiptDate: response7?.data?.complaint_Receipt_Date,

       // Mode: rquestModeData
      });
      const tokenNumber = response7?.data?.irdA_Token_Number || '';
      if(tokenNumber!== ''){
        setIRDATokenNumber(tokenNumber);
        setComplaintID(response7?.data?.registerComplaintID)
      }
      else{
        setIRDATokenNumber('');
        setComplaintID(0);
      }
    } catch (error) {
      console.error('Failed to fetch IGMS data:', error);
    }
  };
  

  const handleSubTypeChange = (value, getSubLU) => {
    setSelectedSubTypeId(value);
    let subTypeData = subTypeLU?.length > 0 ? subTypeLU : getSubLU;
    subTypeData?.map((key) => {
      if (key.mstID === value) {
        const modifiedDesc = key.mstDesc?.replace(/[^\w]/g, "").toLowerCase();
        setSelectedSubType(modifiedDesc);
        setSelectedSubTypeVal(key.mstDesc);
      }
    });
  }

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf('day');
  };

  const sections = [
    {
      title: "Policy Information",
      fields: [
        { label: "Broker License Number", name: "BrokerLicenseNumber", type: "Input", className: "bold-label" },
        { label: "Entity Complaint Ref Number", name: "EntityComplaintRefNumber", type: "Input", className: "bold-label", rules: [{ required: true, message: "Please Enter Entity Complaint Ref Number" }] },
        { label: "Email", name: "Email", type: "Input", className: "bold-label", disabled: true  },
        { label: "First Name", name: "FirstName", type: "Input", className: "bold-label",  disabled: true, rules: [{ required: true, message: "Please Enter First Name" }]},
        { label: "Insurer Resolution Letter date", name: "InsurerResolutionLetterdate", type: "DatePicker", className: "inputs-label mb-0 bold-label", disabledDate, disabled: true },
        { label: "Intermediary License Number", name: "IntermediaryLicenseNumber", type: "Input", className: "bold-label" },
        { label: "Is Complainant Informed", name: "IsComplainantInformed", type: "Switch", className: "bold-label" },
        { label: "Mobile Number", name: "MobileNumber", type: "Input", className: "bold-label", disabled: true },
        { label: "Organization Name", name: "OrganizationName", type: "Input", className: "bold-label" },
        { label: "Original IRDA Token Num", name: "OriginalIRDATokenNum", type: "Input", className: "bold-label" },
        { label: "Policy Holder Name", name: "policyHolderName", type: "Input", className: "bold-label", disabled: true },
        { label: "Policy Number", name: "PolicyNumber", type: "Input", className: "bold-label", disabled: true },
        { label: "Premium Payment Ref Number", name: "PremiumPaymentRefNumber", type: "Input", className: "bold-label" },
        { label: "Proposal Number", name: "ProposalNumber", type: "Input", className: "bold-label", disabled: true },
        { label: "Receipt Number", name: "ReceiptNumber", type: "Input", className: "bold-label" },
        {
          label: "Pincode",
          name: "Pincode",
          type: "Input",
          className: "bold-label",
          rules: [
            {
              required: false,
              message: "Please enter a valid Pincode",
            },
            {
              pattern: /^\d{6}$/, // Regular expression for 6 digits only
              message: "Pincode must be 6 digits and numbers only",
            },
          ],
          disabled: showPincodeField,
        },
        
        { label: "State", name: "state", type: "Select", options: stateOptions, className: "bold-label",rules: [{ required: false, message: "Please select PinCode" }], disabled: true  },
        { label: "District", name: "DistrictId", type: "Select", options: districtOptions, className: "bold-label", rules: [{ required: false, message: "Please select PinCode" }], disabled:true },
        { label: "City", name: "CityID", type: "Select", options: cityOptions, className: "bold-label", rules: [{ required: false, message: "Please select PinCode" }], disabled:false  },
        { label: "Remarks", name: "Remarks", type: "Input", className: "bold-label" },
        { label: "Request IRDAI For Closure", name: "RequestIRDAIForClosure", type: "Switch", className: "bold-label" },
        { label: "Policy Proposal ClaimNumber", name: "PolicyProposalClaimNumber", type: "Input", className: "bold-label" },
        { label: "Wrong Insurer Selected", name: "WrongInsurerSelected", type: "Switch", className: "bold-label" },
        { label: "Is Senior Citizen", name: "IsSeniorCitizen", type: "Switch", className: "bold-label" },
      ]
    },
    {
      title: "Complaint Details",
      fields: [
        { label: "Complaint Type", name: "ComplaintTypeId", type: "Select", options: complaintTypes, className: "bold-label", rules: [{ required: true, message: "Please select Complaint Type" }] },
        { label: "Complaint Description Id", name: "ComplaintDescriptionId", type: "Select", options: complaintDesc, className: "bold-label", rules: [{ required: true, message: "Please Select Complaint DescriptionId" }] },
        { label: "Complaint Details", name: "ComplaintDetails", type: "Input", className: "bold-label", rules: [{ required: true, message: "Please Enter Complaint Details" }] },
        { label: "Complaint Receipt Date", name: "ComplaintReceiptDate", type: "DatePicker", className: "inputs-label mb-0 bold-label", disabledDate, rules: [{ required: true, message: "Please select Complaint ReceiptDate" }], disabled: false,},
        { label: "Complaint Status Id", name: "ComplaintStatusId", type: "Select", options: statusOptions, className: "bold-label", rules: [{ required: true, message: "Please Select Complaint Status" }] },
        { label: "Source Of Complaint", name: "SourceOfComplaint", type: "Select", options: complaintSource, className: "bold-label", rules: [{ required: true, message: "Please select Complaint Source" }] },
        { label: "Date Of Honoring Service", name: "DateOfHonoringService", type: "DatePicker",required: true, className: "inputs-label mb-0 bold-label", disabledDate,rules: [{ required: false, message: "Please select DateOfHonoringService" }] },
      ]
    },
    {
      title: "Claim Information",
      fields: [
        { label: "Claim Clsr Additional Info", name: "ClaimClsrAdditionalInfo", type: "Input", className: "bold-label" },
        { label: "Claim Received Amount", name: "ClaimReceivedAmount", type: "Input", className: "bold-label" },
        { label: "Claim Requested Amount", name: "ClaimRequestedAmount", type: "Input", className: "bold-label" },
        { label: "Claim Intimation Date", name: "ClaimIntimationDate", type: "DatePicker", className: "inputs-label mb-0 bold-label", disabledDate },
        { label: "Claim Payment Cheque Number", name: "ClaimPaymentChequeNumber", type: "Input", className: "bold-label" },
        { label: "Claim Payment Date", name: "ClaimPaymentDate", type: "DatePicker", className: "inputs-label mb-0 bold-label", disabledDate },
        { label: "Claim Number", name: "ClaimNumber", type: "Input", className: "bold-label" },
        { label: "Claim Payment Cheque Date", name: "ClaimPaymentChequeDate", type: "DatePicker", className: "inputs-label mb-0 bold-label", disabledDate },
      ]
    }
  ];

  const getComplaintDescriptionID = async(data) => {
    try{
      setIsLoading(true);
      const obj = {
        "selectedOption": data
      };

      const response = await apiCalls?.GetIGMSComplaintDescription(obj);
      if(response.status === 200){
        const uniqueData = response?.data.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.compl_Desc_ID === item.compl_Desc_ID)
        );
        setIsLoading(false);
        setComplaintDesc(mapToSelectComplaintDescriptionOptions(uniqueData));
      }
    }
    catch(err) {
      console.log("Error is ", err);
      setIsLoading(false);
    }
  };

  const handleSelectChange =(field, value1, option, title) => {
    if(title === "Complaint Details" && field?.label === "Complaint Type"){
      getComplaintDescriptionID(option?.children)
    }
  };
  return (
    <Spin spinning={isLoading} >
    <div>
          {/* <CustomerDetails 
           isComplaintsUserTabs={true}
        //   setIsShowCallTypes={setIsShowCallTypes} SubTypeId={selectedSubTypeId} CallTypeId={selectedCallTypeId}
        //           isEmailManagement={true}
        //           searchPolicyData={searchPolicyData[0]} EmailResponse={EmailResponseId}
                  ></CustomerDetails> */}



<Form
    form={form}
    name="wrap"
    labelCol={{ flex: "35%" }}
    labelAlign="left"
    labelWrap
    wrapperCol={{ flex: 1 }}
    colon={false}
    autoComplete="off"
>
    <Row gutter={[16, 16]} className='mb-16'>
        <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
            <Form.Item
                label="Call Type"
                name="callType"
                className="inputs-label mb-0"
            >
                <Select
                    showSearch
                    onSearch={onSearch}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Call Type"
                    options={CALL_TyPES}
                    filterOption={filterOption}
                    onChange={(value, option) => handleCallTypeChange(value, option)}
                    disabled={props?.customerData?.isPOS}
                />
            </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
            <Form.Item
                label="Sub Type"
                name="subType"
                className="inputs-label mb-0 subtype right-colsize"
            >
                <Select
                    showSearch
                    onSearch={onSearch}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Sub Type"
                    options={subTypeLU}
                    filterOption={filterOption}
                    onChange={(e) => handleSubTypeChange(e)}
                    disabled={props?.customerData?.isPOS}
                />
            </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={9} lg={9} xxl={9} className='mb-16'>
            <Form.Item
                label="Type of Complaint"
                name="typeofcomplaint"
                className="inputs-label mb-0 subtype right-colsize"
            >
                <Select
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Type of Complaint"
                    options={TypeOfComplaintDropdown}
                    filterOption={filterOption}
                    onChange={(value, option) => DisableRcaTab(value, option)}
                />
            </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='mb-16'>
        <div>
          <a onClick={handleViewUserAgeing} style={{ cursor: "pointer", color: "blue" }}>
            View Email <span><i className="bi bi-envelope-open"></i></span>
          </a>

        </div>
        </Col>

        <Col xs={24} sm={24} md={23} lg={23} xxl={23} className='mb-16'>
            <Form.Item
                label=""
                name="description"
                className="inputs-label mb-0"
                rules={[{ required: true, message: "Enter Description" }]}
            >
                <TextArea
                    rows={4}
                    maxLength={1000}
                    placeholder="Enter Complaints Description"
                />
            </Form.Item>
        </Col>

        {/* <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='mb-16'>
   <div>
     <a href="">View Letter <span><i class="bi bi-file-earmark-text"></i></span></a>
   </div> 
 </Col> */}


        <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='mb-16'>
            <Form.Item
                label="Tag Complaint"
                name="tagComplaint"
                className="inputs-label mb-0"
            >
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                />
            </Form.Item>
        </Col>

        
        <Col xs={12} sm={12} md={12} lg={12} xxl={12} className='mb-16'>
        <div>
        <a href="#" onClick={showIGMSData}>Click For IGMS</a>
  <Modal
    title={IRDATokenNumber !== '' ? `IGMS Data (${IRDATokenNumber})` : 'IGMS Data'}
    open={isModalVisible}
   // onOk={handleOk}
     onCancel={handleCancel}
    // okText="Submit"
    // cancelText="Cancel"
    footer={null}

    width={1200}
    style={{ top: 20 }}
    closeIcon={true}
  >

{showPopup && (
            <ErrorPopup
                errors={errors}
                open={showPopup}
                onClose={() => setShowPopup(false)}
            />
        )}
    <div style={{ height: '600px', overflowY: 'auto'}}>
      <Form form={form} layout="vertical" onFinish={handleOk}>

        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <span className={`${isActive ? 'active' : ''}`} />
          )}
        >
          {sections.map((section, index) => (
            <Collapse.Panel header={section.title} key={index}>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', marginLeft: '10px', marginTop:'10px'  }}>
                {section.fields.map((field, fieldIndex) => (
                  <div key={fieldIndex}>
                    {field.type === 'Input' && (
                      <Form.Item
                      label={<span className={field.className || ''}>{field.label} {field?.rules?.[0]?.required && <sup>*</sup>}
</span>}
                      name={field.name}
                      rules={field?.rules}
                      >
                      <Input placeholder={`Please Enter ${field.label}...`} disabled={field.disabled} onBlur={  (e) => onBlurInput(e.target.value, field)} />
                      </Form.Item>
                    )}
                   {field.type === 'DatePicker' && (
  <Form.Item
    label={
      <span className={field.className || ''}>
        {field.label} {field?.rules?.[0]?.required && <sup>*</sup>}
      </span>
    }
    name={field.name}
    rules={field?.rules}
  >
    <DatePicker
      style={{ width: '100%' }}
      disabledDate={field.disabledDate}
      disabled = {field.disabled}
      format="DD/MM/YYYY" // Specify the format here
      placeholder={`Please Select ${field.label}...`}
    />
  </Form.Item>
)}

                    {field.type === 'Select' && (
                      <Form.Item
                      label={<span className={field.className || ''}>{field.label} {field?.rules?.[0]?.required && <sup>*</sup>}
</span>}
                      name={field.name}
                      rules={field?.rules}
                      disabled = {field.disabled}
                      >
                      <Select placeholder={`Please select a ${field.label}...`}
                       disabled = {field.disabled}
                            onChange={(value, option) => handleSelectChange(field,value, option, section.title)}
                      >
                        {field.name === 'CityID' ? (
                          cityOptions.map((city, index) => (
                            <Select.Option key={city.geo_PCIN_SrNo} value={city.geo_PCIN_SrNo}>
                              {city.city_Town_Vill_AreaNm}
                            </Select.Option>
                          ))
                        ) : (
                          field.options.map(option => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))
                        )}
                      </Select>
                    </Form.Item>
                    )}
{field.type === 'RadioGroup' && (
                      <Form.Item
                      label={<span className={field.className || ''}>{field.label} {field?.rules?.[0]?.required && <sup>*</sup>}
</span>}
                      name={field.name}
                      rules={field?.rules}
                      >
                      <Radio.Group>
                                        <Radio value={1}>Yes</Radio>
                                        <Radio value={0}>No</Radio>
                                    </Radio.Group>
                    </Form.Item>
                    )}


{field.type === 'Switch' && (
  <Form.Item
    label={
      <span className={field.className || ''}>
        {field.label} {field?.required && <sup>*</sup>}
      </span>
    }
    name={field.name}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Switch checkedChildren="Yes" unCheckedChildren="No" />
    </div>
  </Form.Item>
)}

                  </div>
                ))}
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
        <div className="contact-details-btn">
                <Button
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                >
                 Save
                </Button>{" "}
                <Button
                  type="primary"
                  className="primary-btn"
                  onClick={handleCancel}
                >
                 Cancel
                </Button>{" "}
                </div>
      </Form>
    </div>
    
  </Modal>
  <Modal
      title={
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
       
        </div>
      }
      open={showViewModal}
      destroyOnClose={true}
      closeIcon={
        <Tooltip title="Close">
          <span onClick={() => setShowViewModal(false)} style={{ cursor: "pointer" }}>
            <img src={CloseIcon} alt="Close" />
          </span>
        </Tooltip>
      }
      footer={null}
      width={900} // Adjust for table width
      className="mt-62"
    >
      <div style={{ border: "1px solid black", textAlign: "center" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
  <thead>
    <tr>
      {<th
        colSpan="1"
        style={{
          borderBottom: "1px solid black",
          padding: "10px",
          fontWeight: "bold",
        }}
      >
      {/* <span>From:{fromEmail}</span>  */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <label htmlFor="fromEmail" style={{ marginRight: '10px' }}>From:</label>
      <span id="fromEmail">{fromEmail}</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <label htmlFor="createdDate" style={{ marginRight: '10px' }}>Time:</label>
      <span id="createdDate">{emailCreatedDate}</span>
      </div>
      </th> }
    </tr>
  </thead>
  <tbody
    style={{
      display: "block", // Required to enable scroll on tbody
      maxHeight: "1000px", // Set max height for scrollable area
      overflowY: "auto", // Enable vertical scrolling
    }}
    className="scrollable-container"
  >
    <tr style={{  }}> {/* Maintain table-like structure */}
      <td className='para-compact'>{emailBody}</td>
    </tr>
    {/* Add more rows to test scrolling */}
    
   
  </tbody>
</table>

      </div>
    </Modal>
</div>

        </Col>
        <Col xs={24} sm={12} md={24} lg={24} xxl={24}>
            <div className="contact-details-btn" style={{
            display: "flex",
            justifyContent: "flex-start", // Align content to the left
        }}>
                <Button
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    onClick={handleMiscSave}
                >
                    Save 
                </Button>
            </div>
        </Col>
        <Col xs={24} sm={24} md={17} lg={17} xxl={17} className="mb-16">
       
    <h6 className="fw-700" style={{color:"#b21f1f"}}>Response For Complainant</h6>
    <Form.Item
    label="Is Complaint Closed in Favour Of the Customer"
    name="complaintClosed"
    className="inputs-label"
    style={{ margin: '16px 0' }} // Adds spacing
>
    <Radio.Group>
        <Radio value="yes">Yes</Radio>
        <Radio value="no">No</Radio>
    </Radio.Group>
    </Form.Item>
    <Form.Item
    label="Complaint Decision"
    name="complaintDecision"
    className="inputs-label"
    style={{ margin: '16px 0' }} // Adds spacing
    // rules={[{ required: true, message: 'Please select a complaint decision!' }]} // Validation rule
>
    <Select
        className="cust-input"
        placeholder="Select complaint decision"
    >
        <Select.Option value="infavor">In favor</Select.Option>
        <Select.Option value="rejected">Rejected</Select.Option>
        <Select.Option value="partiallyInfavor">Partially in favor</Select.Option>
        <Select.Option value="duplicate">Duplicate</Select.Option>
    </Select>
</Form.Item>
    {/* <Form.Item
        label="Sent Template"
        name="sentTemplate"
        className="inputs-label"
        style={{ margin: '16px 0' }} // Adds 16px margin above and below
    >
        <Select
            onChange={(value) => sentTemplate(value)}
            className="cust-input"
            maxLength={100}
            placeholder="Select a Sent Template"
            options={[
                { value: "acknowledge", label: "Acknowledge" },
                { value: "closure", label: "Closure" },
                { value: "unregistered", label: "UnRegistered Mail" }
            ]}
        />
    </Form.Item> */}
    {/* New SenderEmail field */}
    <Form.Item
        label="Email To"
        name="senderEmail"
        className="inputs-label"
        style={{ margin: '16px 0' }} // Adds 16px margin above and below
    >
        <Input
            className="cust-input"
            placeholder="Enter sender's email"
        />
    </Form.Item>
    <Form.Item
        label="Subject Line"
        name="subjectLine"
        className="inputs-label"
        style={{ margin: '16px 0' }} // Adds 16px margin above and below
    >
        <Input
            className="cust-input"
            placeholder="Enter Subject Line"
        />
    </Form.Item>

    {/* {isEmailButton && 
        <Button
            type="primary"
            className="primary-btn"
            htmlType="submit"
            onClick={handleEmailSend}
            style={{ marginTop: '16px', marginBottom: '16px' }} // Adjust these values as needed
        >
            Send
        </Button>
    } */}

{/* <Col xs={24} sm={12} md={24} lg={24} xxl={24}>
            <div className="contact-details-btn" style={{
            display: "flex",
            justifyContent: "flex-start", // Align content to the left
        }}>
                <Button
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    onClick={handleMiscSave}
                >
                    Save 
                </Button>
            </div>
        </Col> */}


    <ReactQuill className="quill-container" modules={module} theme="snow" value={ResponseForCustomer}  onChange={(value) => setResponseForCustomer(value)} />
    <Col xs={24} sm={12} md={24} lg={24} xxl={24}>
  <div
    className="contact-details-btn"
    style={{
      display: "flex",
      justifyContent: "flex-start", // Aligns content to the left
      gap: "10px", // Adds spacing between buttons
    }}
  >
    <Button
      type="primary"
      className="primary-btn"
      htmlType="submit"
      onClick={handleEmailSend}
    >
      Send
    </Button>

    <Button
      type="primary"
      className="primary-btn"
      htmlType="submit"
      onClick={handleComplaintsTicketCloseButton}
    >
      Close
    </Button>
  </div>
</Col>

</Col>



        {/* <Col xs={24} sm={12} md={24} lg={24} xxl={24}>
            <div className="contact-details-btn">
                <Button
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    onClick={handleMiscSave}
                >
                    Save 
                </Button>
            </div>
        </Col> */}
    </Row>
</Form>


      {showAlert && (
        <PopupAlert
          alertData={alertData}
          title={alertTitle}
          setShowAlert={setShowAlert}
        ></PopupAlert>
      )}
       
    </div>
    </Spin>



  );
}

export default ViewCustomerComplaintTab;