import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form, Spin, Button, message, Input, Tabs, Radio,DatePicker,Select, Modal, Checkbox,Tooltip } from "antd";
import { ClaimsData } from "../../mainconfig";
import DetailsForm from "../../utils/DetailsForm";
import moment from "moment";
import UploadIcon from "../../assets/images/upload.png";
import apiCalls from "../../api/apiCalls";
import PopupAlert from "../popupAlert";
import dayjs from "dayjs";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import CloseIcon from "../../assets/images/close-icon.png";
import { profileObj } from "../../reducers/ProfileReducer";

const { TabPane } = Tabs;
const { Option } = Select;
const ClaimsPrimaryAssesment = (props) => {
  const loginInfo = useSelector(state => state);
  const dateFormat = "DD/MM/YYYY";
  const [emailExist] = useState(false);
  const suffix = <img src={UploadIcon} alt="" />;
  const { selectedSubType, clientRoleLU, details, customerData, clientEnquiryData,causeOfEventLU, natureOfDeathLU,policyTypeLU,claimCategoryLU,claimIntimationLU,sourceInformationLU,assuredIncomePlanLU,POSContactData,laNomineeAddressLU,subStageLU,assessorsDecisionLU,policyStatusDOBLU, loggedUser,dataBseCHeckLU,hotSpotCheckLU,referCaseToLU,reinstatementDecisionLU,withDGHLU,investigatorLU, decisionDescriptionLU,masterData, isPolicyAssigned } = props?.propsData;
  const [form] = Form.useForm();
  const [nomineeform] = Form.useForm();
  const [nomineebankform] = Form.useForm();
  const [uploadform] = Form.useForm();
  const [reInsureForm] = Form.useForm();
  const [reInstatementForm] = Form.useForm();
  const [policyCheckForm] =  Form.useForm();
  const [claimDetailsForm] = Form.useForm();
  const [assignmentForm] = Form.useForm();
  const [referrelSheetForm] = Form.useForm();
  const [assessorsForm] =  Form.useForm();
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const [ClaimTypee, setClaimTypee] = useState('');
  const [isShowPOSScreen, setIsShowPOSScreen] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertData, setAlertData] = useState("");
  const [navigateTo, setNavigateTo] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [claimIntimatedBy, setClaimIntimatedBy] = useState("");
  const [existingNomineeData, setExistingNomineeData] = useState([]);
  const [relationShipLU,setRelationShipLU] = useState([]);
  const [isPennyDropStatus, setIsPennyDropStatus] = useState(false);
  const [isBeneficiaryChangeRequired,setIsBeneficiaryChangeRequired] = useState("");
  const [CNFBankAccNo, setCNFBankAccNo] = useState("");
  const [BankAccNo, setBankAccNo] = useState("");
  const [updateFields,setUpdateFields] = useState(false);
  const [isAccidentSelection,setIsAccidentSelection] = useState(false);
  const [policyDetailsData, setPolicyDetailsData] = useState({});
  const [claimDetailsData,setClaimDetailsData] = useState({});
  const [intimationData,setIntimationData] = useState({});
  const [dateOfDeath, setDateOfDeath] = useState('');
  const [riderDetailsData, setRiderDetailsData] = useState([]);
  const [lifeAsiaTransactionModal,setLifeAsiaTransactionModal] = useState(false);
  const [lifeTransactionData, setLifeTransactionData] = useState([])
  const [beneficiaryDetailsData, setBeneficiaryDetailsData] = useState([]);
  const [beneficiaryBankData, setBeneficiaryBankData] = useState([]);
  const [isEditNominee, setIsEditNominee] = useState(false);
  const [Edit, setIsEdit] = useState(true);
  const [isBeneficiaryEdit, setIsBeneficiaryEdit] =  useState(true);
  const [isEditBeneficiary, setIsEditBeneficiary] = useState(false);
  const [posUpdateNomineeData, setPosUpdateNomineeData] = useState([]);
  const [isBeneficiaryBankEdit, setIsBeneficiaryBankEdit] = useState(true);
  const [isBankEditable, setIsBankEditable] = useState(false);
  const [isShowRequirements, setIsShowRequirements] = useState(false);
  const [isRerenderForm, setIsReRenderForm] = useState(false);
  const [policyCheckData,setPolicyCheckData] = useState({});
  const [reInstatementData,setReInstatementData] = useState({});
  const [assignmentData, setAssignmentData] = useState({});
  const [claimPaymentData, setClaimPaymentData] = useState({});
  const [referrelSheetData, setReferrelSheetData] = useState({});
  const [asessorsRecomandationData,setAsessorsRecomandationData] = useState({});
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [negativeList, setNegativeList] = useState([]);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [isShowOtherDocument, setIsShowOtherDocument] = useState(false);
  const [raiseRequirementOpen,setRaiseRequirementOpen] = useState(false);
  const [requirementModalLoader, setRequirementLoader] = useState(false);
  const [raiseRequerimentList, setRaiseRequerimentList] = useState([]);
  const [isLoader,setIsLoader] = useState(false);
  const [serviceRequestId, setServiceRequestId] = useState(null);
  const [showRaiseRequirementBtn,setShowRaiseRequirementBtn] = useState(false);
  const [isRiderData, setIsRiderData] = useState([])
  const [NameDeDupeData,setNameDeDupeData] = useState([]);
  const [NameDeDupeModal,setNameDeDupeModal] = useState(false);
  const [deDupeModalOpen, setDeDupeModalOpen] = useState(false);
  const [BankduDupeData,setBankDeDupeData] = useState([]);
  const [followUpsData, setFollowUpsData] = useState([]);
  const [viewTransactionModal, setViewTransactionModal] = useState(false);
  const [viewTransactionData, setViewTransactionData] = useState([]);
  const [viewTransLoader, setViewTransLoader] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [hideInvestDetails, setHideInvestDetails] = useState(false)
  const [subDescriptionLU, setSubDescriptionLU] = useState([]);
  const [isReferallData, setIsReferallData] = useState('')
  const [deathSumAssured, setDeathSumAssured] = useState(null);
  const [showAgentSourcing, setShowAgentSourcing] = useState(false);
  const [showAgentSourcingDetails, setShowAgentSourcingDetails] = useState([]);
  const [claimPayout, setClaimPayout] = useState({
    riderSumAssured: "",
    premiumsToBeWaved: "",
    premiumSuspense: "",
    interestCharges: "",
    penalInterestCharges: "",
    premiumRecovery: "",
    survivalBenefit: ""
  });
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [addComments, setAddComments] = useState(null);
  const [allComments, setAllComments] = useState([]);
  const [reInsureDetails, setReInsureDetails] = useState([
    { id: 1, withinRetention: '', retentionAmount: null, reInsurerName: '', participation: null, isDisable: false },
  ]);
  const [investDetails, setInvestDetails] = useState([
    { id: 1, investigatorName: '', investigationStartDate: null, investigationEndDate: null, isDisable: false },
  ]);
  const dispatch = useDispatch();
  

  const handleAdd = () => {
    const newData = {
      id: reInsureDetails.length + 1,
      withinRetention: 'Yes',
      retentionAmount: '',
      reInsurerName: '',
      participation: '',
    };
    setReInsureDetails([...reInsureDetails, newData]);
  };
  
  const handleInvestAdd = () => {
    const newData = {
      id: investDetails?.length + 1,
      investigatorName: '',
      investigationStartDate: '',
      investigationEndDate: '',
    };
    setInvestDetails([...investDetails, newData]);
  };

  const handleDelete = (id, index) => {
    if (reInsureDetails.length > 1) {
      form.setFieldsValue({
        reInsureDetails: {
          [id]: {
            withinRetention: '',
      retentionAmount: '',
      reInsurerName: '',
      participation: '',
      isDisable: false
          },
        },
      });
      const updatedupdateNomineeData = reInsureDetails.filter((row) => row.id !== id);
      setReInsureDetails(updatedupdateNomineeData);
      // Reset the form instance to reflect the changes
      reInsureForm.resetFields([`reInsureDetails[${index}].withinRetention`, `reInsureDetails[${index}].retentionAmount`, `reInsureDetails[${index}].reInsurerName`, `reInsureDetails[${index}].participation`, `reInsureDetails[${index}].isDisable` ],);
    }
  };
  const handleInvestDelete = (id, index) => {
    if (investDetails?.length > 1) {
      form.setFieldsValue({
        investDetails: {
          [id]: {
            investigatorName: '',
      investigationStartDate: '',
      investigationEndDate: '',
          },
        },
      });
      const updatedupdateNomineeData = investDetails?.filter((row) => row.id !== id);
      setInvestDetails(updatedupdateNomineeData);
      // Reset the form instance to reflect the changes
      reInsureForm.resetFields([`investDetails[${index}].investigatorName`, `investDetails[${index}].investigationStartDate`, `investDetails[${index}].investigationEndDate` ],);
    }
  };

const handleSelectChange = (value, id) => {
 // console.log(`Selected value for Requirement ${id}:`, value);
};

const handleCheckboxChange = (e, id, type) => {
 // console.log(`Checkbox ${type} for Requirement ${id}:`, e.target.checked);
};

const handleTabChange = (key) => {
  setActiveTabKey(key);
  if(key === "12"){
    handleViewComments();
  }
};
const posChangeinPlanObj= {
}

const requirementsLU = [
    "Claim intimation form / letter / email",
    "Original policy bond or confirmation letter or indemnity for lost policy bond",
    "Original death certificate issued by local authority",
    "Current address proof & photo identity proof of claimant/representative",
    "PAN card / Form 60 of the claimant/representative",
    "Bank details and proof of bank account (cancelled cheque with printed name or passbook copy verified by the branch)",
    "Employer Questionnaire for salaried LA (Optional)",
    "Certificate of cremation or burial (Optional)",
    "Relationship proof of the nominee with the LA",
    "Medical / medicolegal cause of death certificate",
    "Medical records (admission notes, discharge / death summary, tests reports, etc.)",
    "Medical Questionnaires",
    "Any other related documents",
    "Copy of FIR, panchanama, inquest report, post-mortem report, viscera chemical analysis (VCA), final police report (if applicable)",
    "Copy of Driving License"
];

const options = requirementsLU?.map((requirement, index) => ({
    key: index + 1,
    value: requirement
}));

const [selectedRequirements, setSelectedRequirements] = useState([]);

const handleChange = (value) => {
    setSelectedRequirements(value);
};
const convertDate1 =(dateString)  =>{
  if (!dateString) return dateString; // Return if the date is null or undefined
  
  const date = new Date(dateString); // Convert the string to a Date object
  
  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date:", dateString);
    return dateString; // Return the original string if the date is invalid
  }

  // Format the date as dd/mm/yyyy using locale settings
  return date.toLocaleDateString('en-GB'); // 'en-GB' locale formats dates as dd/mm/yyyy
}

// Function to calculate the difference
const calculateDifference = (rcd, dod) => {
  const format = "DD/MM/YYYY"; // Define the date format
  const startDate = moment(rcd, format); // Parse the start date with format
  const endDate = moment(dod, format); // Parse the end date with format

  const years = endDate.diff(startDate, 'years');
  startDate.add(years, 'years'); // Add the years difference to the start date

  const months = endDate.diff(startDate, 'months');
  startDate.add(months, 'months'); // Add the months difference to the start date

  const days = endDate.diff(startDate, 'days'); // Now calculate the days difference

  return `${years}Y ${months}M ${days}D`;
};

useEffect(()=>{
if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="1"){
        POSContactData?.serviceRequestTransectionData?.forEach(element => {
          posChangeinPlanObj[element.tagName] = element.tagValue
        });
       // const newDate = new Date(convertDate(details?.policyDetailsObj?.saDetails?.rcd));
        form.setFieldsValue({
          custRole: posChangeinPlanObj?.custRole ? parseInt(posChangeinPlanObj.custRole, 10) : null,
          srvReqID: posChangeinPlanObj?.srvReqRefNo,
          policyType:  policyDetailsData?.policyType || details?.policyDetailsObj?.planAndStatus?.policyType,
          LAClientID:  policyDetailsData?.LAClientID || details?.policyDetailsObj?.identifiers?.la_ClientID || null,
          POClientID: policyDetailsData?.POClientID || details?.policyDetailsObj?.identifiers?.po_ClientID  || null,
          ApplicationDate: policyDetailsData?.ApplicationDate || (posChangeinPlanObj?.ApplicationDate? parseInt(posChangeinPlanObj?.ApplicationDate) : null),
          Totalpremiumpaidtilldate:  policyDetailsData?.Totalpremiumpaidtilldate || posChangeinPlanObj?.Totalpremiumpaidtilldate,
          LifeAsiaTransactionsAfterDOD:  policyDetailsData?.LifeAsiaTransactionsAfterDOD || posChangeinPlanObj?.LifeAsiaTransactionsAfterDOD,
          policyStatusOnDateOfDeath:  posChangeinPlanObj?.policyStatusOnDateOfDeath ?parseInt(posChangeinPlanObj?.policyStatusOnDateOfDeath) : policyDetailsData?.policyStatusOnDateOfDeath,
          LAAgeonDOD:  policyDetailsData?.LAAgeonDOD,
          LAOccupation:  policyDetailsData?.LAOccupation || clientEnquiryData?.occpcode,
          LifeAssuredAnnualIncome:  policyDetailsData?.LifeAssuredAnnualIncome || POSContactData?.LifeAssuredAnnualIncome,
          DurationfromRCDTillDOD:  policyDetailsData?.DurationfromRCDTillDOD || posChangeinPlanObj?.DateofDeath ? calculateDifference(convertDate(details?.policyDetailsObj?.saDetails?.rcd),posChangeinPlanObj?.DateofDeath) : null,
          DurationfromRCDTillDODIntimation:  policyDetailsData?.DurationfromRCDTillDODIntimation || posChangeinPlanObj?.DateofIntimation ? calculateDifference(convertDate(details?.policyDetailsObj?.saDetails?.rcd),posChangeinPlanObj?.DateofIntimation) : null,
          UWDecision:  policyDetailsData?.UWDecision || POSContactData?.UWDecision,
          UWComments:  policyDetailsData?.UWComments || POSContactData?.UWComments,
          LifeAssuredGender:  policyDetailsData?.LifeAssuredGender || clientEnquiryData?.cltsex,
        });
       // GetClaimsPrimaryAssessmentEnquiry();
        getGCPPolicydetails();
        const tagPatternPersonal = /_(\d+)$/;
        const personalFields = [ "longdesc","sumins","LastReinstatementDate","StatusonDateofDeath","id"];
        const riderNewdata = processData(
          POSContactData?.serviceRequestTransectionData,
          'Create',
          tagPatternPersonal,
          personalFields
        );
        setIsRiderData(riderNewdata);
  }
  else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="2"){
    POSContactData?.serviceRequestTransectionData?.forEach(element => {
      posChangeinPlanObj[element.tagName] = element.tagValue
    });
    claimDetailsForm?.setFieldsValue({
     // SourceofIntimation: intimationData?.SourceofIntimation || (posChangeinPlanObj?.SourceofIntimation ? parseInt(posChangeinPlanObj?.SourceofIntimation) : null),
      srvReqID: posChangeinPlanObj?.srvReqRefNo,
      ClaimsID:  claimDetailsData?.ClaimsID || POSContactData?.srvReqRefNo || null,
      ClaimServiceGuarateeApplicable: claimDetailsData?.ClaimServiceGuarateeApplicable || posChangeinPlanObj?.claimsApplicable,
      AgeingFromIntimation:  claimDetailsData?.AgeingFromIntimation || posChangeinPlanObj?.ClaimIntimatedOn ? calculateDifference(posChangeinPlanObj?.ClaimIntimatedOn,convertDate(new Date())) : null,
      AgeingFromRegisterationDate:  claimDetailsData?.AgeingFromRegisterationDate || posChangeinPlanObj?.ClaimReceivedOn ? calculateDifference(posChangeinPlanObj?.ClaimReceivedOn,convertDate(new Date())) : null,
      AgeingFromLastDocumentReceived: claimDetailsData?.AgeingFromLastDocumentReceived || posChangeinPlanObj?.AgeingFromLastDocumentReceived,
      DeceasedPerson: claimDetailsData?.DeceasedPerson || posChangeinPlanObj?.DeceasedPerson,
      ReasonForLateIntimation: claimDetailsData?.ReasonForLateIntimation || posChangeinPlanObj?.ReasonForLateIntimation,
      policyType:  claimDetailsData?.policyType || details?.policyDetailsObj?.planAndStatus?.customerType,
      claimType:  claimDetailsData?.claimType || posChangeinPlanObj?.claimType || null,
      claimCategory: posChangeinPlanObj?.claimCategory ? parseInt(posChangeinPlanObj?.claimCategory) : claimDetailsData?.claimCategory,
      NatureofDeath: claimDetailsData?.NatureofDeath || (posChangeinPlanObj?.NatureofDeath? parseInt(posChangeinPlanObj?.NatureofDeath) : null),
      exactCauseOfDeath:  claimDetailsData?.exactCauseOfDeath || posChangeinPlanObj?.exactCauseOfDeath || null,
      DateofDeath:  claimDetailsData?.DateofDeath || posChangeinPlanObj?.DateofDeath || null,
      RegistrationEffectiveDate: claimDetailsData?.RegistrationEffectiveDate || posChangeinPlanObj?.ClaimReceivedOn || null,
      DateofIntimation: claimDetailsData?.DateofIntimation || posChangeinPlanObj?.ClaimIntimatedOn || null,
     // LastRequirementReceivedDate:  claimDetailsData?.LastRequirementReceivedDate || posChangeinPlanObj?.LastRequirementReceivedDate || null,
    });
    // ClaimsData[selectedSubType]?.Intimation_Details?.forEach(element => {
    //   if(element?.name === "NameofiIntimatingPerson"&&posChangeinPlanObj?.ClaimIntimatedBy === "nominee"){
    //     element.hide= true;
    //     setIsReRenderForm(!isRerenderForm)
    //   }
    //   else {
    //     if(element?.name === "NameofiIntimatingPerson"&&posChangeinPlanObj?.ClaimIntimatedBy !== "nominee"){
    //       element.hide= false;
    //       setIsReRenderForm(!isRerenderForm)
    //     }
    //   }
    // });
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="4"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  getMandatetagEnquiry();
  policyCheckForm?.setFieldsValue({
   // SourceofIntimation: policyCheckData?.SourceofIntimation || (posChangeinPlanObj?.SourceofIntimation ? parseInt(posChangeinPlanObj?.SourceofIntimation) : null),
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    OFAC:  policyCheckData?.OFAC || posChangeinPlanObj?.OFAC || null,
    ClaimHotspotCheck:  policyCheckData?.ClaimHotspotCheck ? parseInt(policyCheckData?.ClaimHotspotCheck) : posChangeinPlanObj?.ClaimHotspotCheck ? parseInt(posChangeinPlanObj?.ClaimHotspotCheck) : null,
    IIBDataBaseCheck:  policyCheckData?.IIBDataBaseCheck ? parseInt(policyCheckData?.IIBDataBaseCheck) : posChangeinPlanObj?.IIBDataBaseCheck ? parseInt(posChangeinPlanObj?.IIBDataBaseCheck) : null,
    IndustryCheck:  policyCheckData?.IndustryCheck || posChangeinPlanObj?.IndustryCheck || null,
    AutoPayStatus:  policyCheckData?.AutoPayStatus || posChangeinPlanObj?.AutoPayStatus || null,
  });
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="5"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  reInstatementForm?.resetFields();
  reInstatementForm?.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    DidPolicyLapse: reInstatementData?.DidPolicyLapse|| "",
    Lapsedon:  reInstatementData?.Lapsedon  || null,
    WasPolicyReinstated: reInstatementData?.WasPolicyReinstated  ||"",
    ReinstatementDate:  reInstatementData?.ReinstatementDate || null,
    ReinstatementDecision:  reInstatementData?.ReinstatementDecision || null,
    WithDGH:  reInstatementData?.WithDGH || null,
    MedicalDisclosures:  reInstatementData?.MedicalDisclosures || "",
  });
  getUWFollowups();
 
    // ClaimsData[selectedSubType]?.Reinstatement_Details_Claim_Details?.forEach(element => {
    // if(element.name === 'DidPolicyLapse' && element?.DidPolicyLapse == '1'){
    //   if(['Lapsedon'].includes(element?.name)){
    //     element.hide = false;
    //     setUpdateFields(!updateFields);
    //   }
    // }
    //   else if(element.name === 'DidPolicyLapse' && element?.DidPolicyLapse == '2'){
    //     if(['Lapsedon'].includes(element?.name)){
    //       element.hide = true;
    //       setUpdateFields(!updateFields);
    //     }
    // }
  
    // else if(element.name === 'WasPolicyReinstated' && element?.DidPolicyLapse == '1'){
    //   if(['ReinstatementDate','ReinstatementDecision','WithDGH'].includes(element?.name)){
    //     element.hide = false;
    //     setUpdateFields(!updateFields);
    //   }
    // }
    //   else if(element.name === 'WasPolicyReinstated' && element?.DidPolicyLapse == '2'){
    //     if(['ReinstatementDate','ReinstatementDecision','WithDGH'].includes(element?.name)){
    //       element.hide = true;
    //       setUpdateFields(!updateFields);
    //     }
    // }
    // })
 
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="6"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  GetAssigneeEnquiry();
  LoanEnquiry();
  assignmentForm?.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    DateofAssignment:  assignmentData?.DateofAssignment || posChangeinPlanObj?.DateofAssignment || null,
   // AssignmentType:  assignmentData?.AssignmentType || posChangeinPlanObj?.AssignmentType || null,
    // AssigneeName:  assignmentData?.AssigneeName || posChangeinPlanObj?.AssigneeName || null,
    AssignorName:  assignmentData?.AssignorName || posChangeinPlanObj?.AssignorName || null,
   // LoanActive:  assignmentData?.LoanActive || posChangeinPlanObj?.LoanActive || null,
    //OutstandingLoanAmount:  assignmentData?.OutstandingLoanAmount || posChangeinPlanObj?.OutstandingLoanAmount || null,
  });
}

if (customerData?.isClaimsPrimaryAssesment && activeTabKey === "7") {
    getNomineeEnquiry();
    POSContactData?.serviceRequestTransectionData?.forEach(element => {
        posChangeinPlanObj[element.tagName] = element.tagValue
      });
      setIsBeneficiaryChangeRequired(posChangeinPlanObj?.isBeneficiaryChangeRequired ==="true");
      nomineeform.setFieldsValue({ isExistingNomineeAlive: posChangeinPlanObj?.isBeneficiaryChangeRequired ==="true" });
      const tagPatternNew = /_(New_\d+)$/;
    const newFields = ["New"];  
    const consolidatedNewData = processData(
      POSContactData?.serviceRequestTransectionData,
      'Create',
      tagPatternNew,
      newFields
    );
    setPosUpdateNomineeData(consolidatedNewData);
    const tagPatternPersonal = /_(\d+)$/;
    const personalFields = ["NomineePANNumber", "NameonPAN", "PANValidationResult", "NomineeMobile", "address", "NomineeEmail"];
    const personlBeneficiaryNewData = processData(
      POSContactData?.serviceRequestTransectionData,
      'Create',
      tagPatternPersonal,
      personalFields
    );
  
    setBeneficiaryDetailsData(personlBeneficiaryNewData);
  }
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="8"){
  if(POSContactData?.deDupPayload?.length > 0){
    for (let index in POSContactData?.deDupPayload){
     if(POSContactData?.deDupPayload[index]?.type ==='BANK') {
       setBankDeDupeData(POSContactData?.deDupPayload[index]?.deDupPayload);
     }
    }
   }
    const tagPatternPersonal = /_(\d+)$/;
    const personalFields = ["IFSC","BankName","BranchName","AccountNumber","ReAccountNumber","AccountHolderName","InitiatePennyDrop","NameasperPennyDrop","NameMatch"];
    const beneficiaryNewData = processData(
      POSContactData?.serviceRequestTransectionData,
      'Create',
      tagPatternPersonal,
      personalFields
    );
    setBeneficiaryBankData(beneficiaryNewData);
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="9"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  // setReInsureDetails([
  //   { id: 1, withinRetention: '', retentionAmount: null, reInsurerName: '', participation: null },
  // ]);
  // reInsureForm.resetFields();
  reInsureForm.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    // DateofAssignment:  claimPaymentData?.DateofAssignment || posChangeinPlanObj?.DateofAssignment || null,
    // AssignmentType:  claimPaymentData?.AssignmentType || posChangeinPlanObj?.AssignmentType || null,
    // AssigneeName:  claimPaymentData?.AssigneeName || posChangeinPlanObj?.AssigneeName || null,
    // AssignorName:  claimPaymentData?.AssignorName || posChangeinPlanObj?.AssignorName || null,
    // LoanActive:  claimPaymentData?.LoanActive || posChangeinPlanObj?.LoanActive || null,
    // OutstandingLoanAmount:  claimPaymentData?.OutstandingLoanAmount || posChangeinPlanObj?.OutstandingLoanAmount || null,
  });
  GetClaimsPrimaryAssessmentEnquiry();
}

else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="3"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  form.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    ClaimIntimatedBy:  intimationData?.ClaimIntimatedBy || posChangeinPlanObj?.ClaimIntimatedBy || null,
    ClaimIntimatedOn:  intimationData?.ClaimIntimatedOn || posChangeinPlanObj?.ClaimIntimatedOn || null,
    ClaimRegisteredBy:  intimationData?.ClaimRegisteredBy || posChangeinPlanObj?.ClaimRegisteredBy || null,
    ClaimRegisteredOn:  intimationData?.ClaimRegisteredOn || posChangeinPlanObj?.ClaimReceivedOn || null,
    AssesmentDoneBy:  intimationData?.AssesmentDoneBy || loginInfo?.userProfileInfo?.profileObj?.name || null,
    //AssesmentDoneOn:  intimationData?.AssesmentDoneOn || posChangeinPlanObj?.AssesmentDoneOn || null,
    SelectSubStage:  intimationData?.SelectSubStage || posChangeinPlanObj?.SelectSubStage ? parseInt(posChangeinPlanObj?.SelectSubStage) : null,
  });
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="10"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  setShowRaiseRequirementBtn(false);
  form.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    // ClaimIntimatedBy:  intimationData?.ClaimIntimatedBy || posChangeinPlanObj?.ClaimIntimatedBy || null,
    // ClaimIntimatedOn:  intimationData?.ClaimIntimatedOn || posChangeinPlanObj?.ClaimIntimatedOn || null,
    // ClaimRegisteredBy:  intimationData?.ClaimRegisteredBy || posChangeinPlanObj?.ClaimRegisteredBy || null,
    // ClaimRegisteredOn:  intimationData?.ClaimRegisteredOn || posChangeinPlanObj?.ClaimRegisteredOn || null,
    // AssesmentDoneBy:  intimationData?.AssesmentDoneBy || posChangeinPlanObj?.AssesmentDoneBy || null,
    // AssesmentDoneOn:  intimationData?.AssesmentDoneOn || posChangeinPlanObj?.AssesmentDoneOn || null,
    // SelectSubStage:  intimationData?.SelectSubStage || posChangeinPlanObj?.SelectSubStage || null,
  });
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="12"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  referrelSheetForm.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    InitiateInvestigation:  referrelSheetData?.InitiateInvestigation || posChangeinPlanObj?.InitiateInvestigation || null,
    SelectInvestigator:  referrelSheetData?.SelectInvestigator || posChangeinPlanObj?.SelectInvestigator || null,
    InitiateReInvestigation:  referrelSheetData?.InitiateReInvestigation || posChangeinPlanObj?.InitiateReInvestigation || null,
    SelectReInvestigator:  referrelSheetData?.SelectReInvestigator || posChangeinPlanObj?.SelectReInvestigator || null,
    ReferCaseTo:  referrelSheetData?.ReferCaseTo || posChangeinPlanObj?.ReferCaseTo ? parseInt(posChangeinPlanObj?.ReferCaseTo) : null,
    ReferrelComments:  referrelSheetData?.ReferrelComments || posChangeinPlanObj?.ReferrelComments || null,
  });
}
else if(customerData?.isClaimsPrimaryAssesment && activeTabKey ==="13"){
  POSContactData?.serviceRequestTransectionData?.forEach(element => {
    posChangeinPlanObj[element.tagName] = element.tagValue
  });
  assessorsForm.setFieldsValue({
    srvReqID: posChangeinPlanObj?.srvReqRefNo,
    ReferrelComments:  asessorsRecomandationData?.ReferrelComments || posChangeinPlanObj?.ReferrelComments || null,
    AssesorsDecision:  asessorsRecomandationData?.AssesorsDecision || null,
    ReasonForDecision:  asessorsRecomandationData?.ReasonForDecision || posChangeinPlanObj?.ReasonForDecision || null,
  });
}

},[activeTabKey])

const GetAssigneeEnquiry  =()=>{
  setIsLoading(true);
  let obj = {
    "requestHeader": {
      "source": "POS",
      "carrierCode": "2",
      "branch": "PRA",
      "userId": loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID,
      "userRole": "10",
      "partnerId": "MSPOS",
      "processId": "POS",
      "monthendExtension": "N",
      "monthendDate": "09/11/2023"
      },
      "requestBody": {
      "policyNumber": customerData?.policyNo,
      }
}
  let response = apiCalls.GetAssigneeEnquiry(obj);
  response
    .then((val) => {
      if (val?.data?.responseBody?.errorCode === "0") {
          let res = val?.data?.responseBody;
        form.setFieldsValue({
          AssignmentType:  assignmentData?.AssignmentType || res?.reasonCode || null,
          AssigneeName:  assignmentData?.AssigneeName || res?.assigneeName || null,
         // PolicyOwnerClientID_Old: res?.asigneeCode || details?.policyDetailsObj?.assigneeDetails?.assigneeID,
        })
      } else {
        message.error({
          content:
            val?.data?.responseBody?.errorMessage ||
            "Something went wrong please try again!",
          className: "custom-msg",
          duration: 2,
        });
      }
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
    });
}
const LoanEnquiry = async () => {
  try {
    setShowAlert(false);
   // setIsLoader(true);

    let obj = {
      RequestHeader: {
        source: "POS",
        carrierCode: "2",
        branch: "pra",
        userId: "F1135010",
        userRole: "10",
        monthEndExtension: "N",
        MonthendDate: "30/09/2023",
      },
      RequestBody: {
        policyNo: details?.policyDetailsObj?.identifiers?.policyNo,
      },
    };

    let response = await apiCalls.LoanEnquiry(obj);
    
    if (response?.data?.responseBody?.errorcode === "0") {
      const res = response?.data?.responseBody?.loanEnquiryDetails;
      form?.setFieldsValue({
        LoanActive:  res?.hpleamt,
        OutstandingLoanAmount: res?.hcurbal
      });
    } else {
      message.error({
        content:
          response?.data?.responseBody?.errormessage ||
          "Something went wrong, please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  } catch (err) {
    message.error({
      content: "An error occurred while processing your request.",
      className: "custom-msg",
      duration: 2,
    });
  } finally {
    //setIsLoader(false);
  }
};

const processData = (data, statusFilter, tagPattern, fieldNames) => {
    const filteredData = data?.filter(item => 
      item.status === statusFilter && fieldNames.some(field => item.tagName?.includes(field))
    );
  
    const processedData = filteredData?.reduce((acc, item) => {
      const match = item.tagName?.match(tagPattern);
      if (match) {
        const index = match[1];
        const fieldName = item.tagName.replace(`_${index}`, '');
        const currentIndex = acc.findIndex((el) => el.index === index);
  
        if (currentIndex === -1) {
          acc.push({ index, [fieldName]: item.tagValue });
        } else {
          acc[currentIndex][fieldName] = item.tagValue;
        }
      }
      return acc;
    }, []);
  
    return processedData;
  };

const getNomineeEnquiry = async () => {
  setIsLoading(true);
  setShowAlert(false);
  try {
    const response = await apiCalls.getNomineeEnquiry(customerData?.policyNo, loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID);
    if (response?.data?.responseBody?.errorcode==0 && response?.data?.responseBody?.nomineeEnquiry) {
      const res = response?.data?.responseBody;
      const nomineeArray = [];
      if (res?.nomineeEnquiry?.length > 0) {
        for (const val of res?.nomineeEnquiry) {
          if (val) {
              const dob = await getClientEnquiry(val.bnysel);
              const fullName = val.clientName;
              const [firstName, lastName] = fullName.split(',').map(name => name.trim());
              const relationShip = await getRelationsData(val);
              const nomineeObj = {
                NomineeFirstName_Old: firstName ,
                NomineeLastName_Old:  lastName,
                NomineeDOB_Old: dob,
                RealtionshipWithPolicyowner_Old: relationShip,
                Share_Old: val?.bnypc,
                Role_Old: val?.bnyrln === "AP" ? "Appointee" : "Nominee"
              };
              nomineeArray.push(nomineeObj);
          }
        }
        setExistingNomineeData(nomineeArray);
      }
      setIsLoading(false);
    } else {
        setIsLoading(false);
      message.error({
        content:
          response?.data?.responseBody?.errormessage ||
          "Something went wrong, please try again!",
        className: "custom-msg",
        duration: 2,
      });
    setIsLoading(false);
    }
  } catch (error) {
    setIsLoading(false);
  }
};

const getRelationsData = async (val) =>{
  setIsLoading(true);
  try {
    const response = await apiCalls.getRelationsData(val?.bnysel);
    if (response?.data) {
      const res = response?.data;
        let transformedData = res?.map((item) => ({
         ...item,
         label: item.longdesc,
         value: item.descitem
       }));
       setRelationShipLU(transformedData);
       setIsLoading(false);
        let matchingItem = res?.find((item) => item?.descitem === val?.bnyrln);
        let relationValue = matchingItem ? matchingItem.longdesc : null;
        return relationValue;
    } else {
      setIsLoading(false);
      message.error({
        content:
          response?.data?.responseBody?.errormessage ||
          "Something went wrong, please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  } catch (error) {
    setIsLoading(false);
  }
};
const getClientEnquiry = async (clientNo) => {
  let obj ={
    clientNumber: clientNo
  }
  try {
    const response = await apiCalls.getClientEnquiry(obj,loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID);
    if (response?.data) {
      const res = response?.data?.responseBody;
      return res?.clTdob ? convertDate(res.clTdob) : res?.clTdob;
    } else {
      message.error({
        content:
          response?.data?.responseBody?.errormessage ||
          "Something went wrong, please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
  } catch (error) {
  }
};
 
  const convertDate = (inputDate) => {
    if (inputDate) {
      const formattedDate = moment(inputDate, "YYYYMMDD").format("DD/MM/YYYY");
      return formattedDate;
    } else {
      return ''
    }

  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day"); // Can not select days before today and today
  };
  const featuredatedisabled = (current) => {
    return current && current < dayjs().startOf("day");
};

const handleDateChange = (date, item) => {
  if(item?.toLowerCase() === "dateofdeath"){
    setDateOfDeath(date);
  }
  else if(item?.toLowerCase() === "dateofintimation"){
    if (date && dateOfDeath) {
      const intimationDate = new Date(date);
      const deathDate = new Date(dateOfDeath);

      const differenceInMonths = (intimationDate.getFullYear() - deathDate.getFullYear()) * 12 + intimationDate.getMonth() - deathDate.getMonth();

         ClaimsData[selectedSubType]?.ClaimsRequest_Details?.forEach(element => {
      if (element?.label?.includes("Reason for Late Intimation(If any)")) {
        element.hide = differenceInMonths > 6 ? false : true;
        setUpdateFields(!updateFields);
      }
    });
  }
  }
    else if (item?.toLowerCase() === "lastrequirementreceiveddate") {
      const lastReceivedDate = new Date(date); // Convert the input date to a Date object
      const currentDate = new Date(); // Current date
  
      // Calculate the difference in milliseconds
      const diffInMilliseconds = currentDate - lastReceivedDate;
  
      // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
      const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  
      // Bind the calculated difference to the form field "AgeingFromLastDocumentReceived"
      claimDetailsForm.setFieldsValue({
        AgeingFromLastDocumentReceived: `${diffInDays} days`
      });
    }
};

  const handleSubmit = (values) => {
    // if(selectedSubType==="claimsrequest" && ["3"].includes(activeTabKey)){
    //     return handleClaimDetailsTabSave();
    //   }
    setIsLoading(true);
    setShowRaiseRequirementBtn(false);
    const obj = {
      SrvReqID: POSContactData?.srvReqID,
      SrvReqRefNo: POSContactData?.srvReqRefNo,
      CallType: props?.propsData?.selectedCallType, // Required
      SubType: props?.propsData?.selectedSubTypeId, // Required
      RequestSource: loginInfo?.userProfileInfo?.profileObj?.sourceId || 0, // Required
      RequestChannel: values?.requestchannel || "1", // Required
      Category:  selectedSubType === "claimsrequest" ? 2 : 1,
      ApplicationNo:
        details?.policyDetailsObj?.identifiers?.applicationNo || customerData?.applicationNo,
      DOB: convertDate(customerData?.dob),
      PolicyNo: details?.policyDetailsObj?.identifiers?.policyNo || customerData?.policyNo, // Required
      CustomerId: 456,
      CustRole: values?.custRole || "1",
      policyStatus:
        details?.policyDetailsObj?.planAndStatus?.policyStatus || customerData?.policyStatus,
      proposerName: details?.policyDetailsObj?.identifiers?.po_Name || customerData?.po_Name,
      plan: details?.policyDetailsObj?.planAndStatus?.planName || customerData?.planName,
      CreatedOn: new Date(),
      CreatedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      CreatedUsrId: loginInfo?.userProfileInfo?.profileObj?.userName,
      ModifiedOn: new Date(),
      ModifiedByRef: loginInfo?.userProfileInfo?.profileObj?.userName,
      AssignedToRole: "", //POS
      AssignedByUser: 0,
      ReasonForChange: "",
      RequestDateTime: values?.BranchReceivedDate
        ? new Date(values?.BranchReceivedDate)
        : new Date(),
      ReasonDelayed: values?.ReasonForDelay || "",
      CustSignDateTime: values?.CustomerSigningDate
        ? new Date(values?.CustomerSigningDate)
        : new Date(),
      "TransactionData": getTransactionData(values) || [],
      "Uploads": uploadFiles || [],
      CurrentStatus: '',
      CommunicationRequest: [
        {
          SrvReqRefNo: "",
          TemplateID: "",
          CommType: 2,
          ReceipientTo:  process.env.REACT_APP_RECEIPIENT_TO ? process.env.REACT_APP_RECEIPIENT_TO : clientEnquiryData?.rinternet,
            ReceipientCC: process.env.REACT_APP_RECEIPIENT_CC ? process.env.REACT_APP_RECEIPIENT_CC : clientEnquiryData?.rinternet,
          MobileNos: "",
          ScheduledTime: new Date(),
          CommBody: "",
          Attachments: null,
        },
        {
          SrvReqRefNo: "",
          TemplateID: "",
          CommType: 1,
          ReceipientTo: "",
          ReceipientCC: "",
          MobileNos: process.env.REACT_APP_RECEIPIENT_MOBILENO ? process.env.REACT_APP_RECEIPIENT_MOBILENO : clientEnquiryData?.rmblphone,
          ScheduledTime: new Date(),
          CommBody: "",
          Attachments: null,
        },
      ],
    }
    if (props?.propsData?.EmailResponse?.IsEmailmanagent) {
      obj.TransactionData.push(
        {
          "Status": "Create",
          "TagName": "EmailResponseId",
          "TagValue": props?.propsData?.EmailResponse?.EmailResponseId
        }
      )
    }

    let response = apiCalls.genericAPI(obj);
    response
      .then((val) => {
        if (val?.data) {
            setIsLoading(false);
            if(selectedSubType==="claimsrequest" && ["1", "2","3","4","5","6","7","8","9","10","11","12","13"].includes(activeTabKey)){
                if(activeTabKey==="1") {
                  setPolicyDetailsData(values);
                }
                else if(activeTabKey==="2"){
                  setClaimDetailsData(values);
                }
                else if(activeTabKey==="3"){
                  setIntimationData(values);
                }
                else if(activeTabKey==="4"){
                  setPolicyCheckData(values);
                }
                else if(activeTabKey==="5"){
                  setReInstatementData(values);
                }
                else if(activeTabKey==="6"){
                  setAssignmentData(values);
                }
                else if(activeTabKey==="9"){
                  setClaimPaymentData(values);
                }
                else if(activeTabKey==="10"){
                  setShowRaiseRequirementBtn(true);
                  setIntimationData(values);
                }
                else if(activeTabKey==="12"){
                  setReferrelSheetData(values);
                  // handlePastComments(values);
                }
                else if(activeTabKey==="13"){
                //   setAsessorsRecomandationData(values);
                // setAlertTitle(val?.data?.header);
                // setAlertData("Claim Assessed and moved to Claims Checker!");
                // setShowAlert(true);
                // return;
                const user = loginInfo?.userProfileInfo?.profileObj;
                setAlertTitle("");
                setAlertData("Claim Assessed and moved to Claims Checker!");
                setShowAlert(true);
                setActiveTabKey("1");
                // user.role = 33;
                // user.roleName = "Claims Assessment Checker";
                // user.boe = true;
                // user.sourceId = 33;
                // dispatch(profileObj(user))
                setNavigateTo("/claimsprimaryuser")
                }
                // setAlertTitle(val?.data?.header);
                // setAlertData("Details Saved");
                // setShowAlert(true);
                message.destroy();
                message.success({
                 content: "Details Saved.",
                 className: "custom-msg",
                 duration: 2,
               });
               if(activeTabKey!=="10") {
                return handleClaimDetailsTabSave();
               }
              }
              else {
                setAlertTitle(val?.data?.header);
                setAlertData(val?.data?.message);
                setShowAlert(true);
              }
        } else {
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const handleRadioChange =(e,item)=>{
    if(item.name === 'DidPolicyLapse' && e.target.value === 'yes'){
      ClaimsData[selectedSubType]?.Reinstatement_Details_Claim_Details?.forEach(element => {
        if(['Lapsedon'].includes(element?.name)){
          element.hide = false
        }
      })
      setUpdateFields(!updateFields);
    }else if(item.name === 'AddComments' && e.target.value === 'yes'){
      setShowCommentsModal(true);
      setAddComments(null);
    }
    else if(item.name === 'DidPolicyLapse' && e.target.value === 'no'){
      ClaimsData[selectedSubType]?.Reinstatement_Details_Claim_Details?.forEach(element => {
        if(['Lapsedon'].includes(element?.name)){
          element.hide = true
        }
      });
      setUpdateFields(!updateFields);
    }
    else if(item.name === 'WasPolicyReinstated' && e.target.value === 'yes'){
      ClaimsData[selectedSubType]?.Reinstatement_Details_Claim_Details?.forEach(element => {
        if(['ReinstatementDate','ReinstatementDecision','WithDGH'].includes(element?.name)){
          element.hide = false
        }
      })
      setUpdateFields(!updateFields);
    }else if(item.name === 'WasPolicyReinstated' && e.target.value === 'no'){
      ClaimsData[selectedSubType]?.Reinstatement_Details_Claim_Details?.forEach(element => {
        if(['ReinstatementDate','ReinstatementDecision','WithDGH'].includes(element?.name)){
          element.hide = true
        }
      });
      setUpdateFields(!updateFields);
    }
    else if(item.name === 'InitiateInvestigation' && e.target.value === 'yes'){
     setHideInvestDetails(true);
      setUpdateFields(!updateFields);
    }else if(item.name === 'InitiateInvestigation' && e.target.value === 'no'){
      setHideInvestDetails(false);
      setUpdateFields(!updateFields);
    }
    else if(item.name === 'InitiateReInvestigation' && e.target.value === 'yes'){
      ClaimsData[selectedSubType]?.PolicyChecks_Referrel_Details?.forEach(element => {
        if(['SelectReInvestigator'].includes(element?.name)){
          element.disabled = false
        }
      })
      setUpdateFields(!updateFields);
    }else if(item.name === 'InitiateReInvestigation' && e.target.value === 'no'){
      ClaimsData[selectedSubType]?.PolicyChecks_Referrel_Details?.forEach(element => {
        if(['SelectReInvestigator'].includes(element?.name)){
          element.disabled = true
        }
      });
      setUpdateFields(!updateFields);
    }
  }

  const getUploadFiles = (listOfUploadFiles) => {
    // const PreviouslyFiles = [...uploadFiles, ...listOfUploadFiles]; // Commenting This line bez, adding Duplicate Files Upload like, 1,12,123..
    // if(selectedSubType === 'claimsrequest'){
    //   setUploadFiles(PreviouslyFiles);
    //   return;
    // }
    // else{
      setUploadFiles(listOfUploadFiles);
   // }
  }

  const handleTextLink = (item) => {
    if(item.name ==="LifeAsiaTransactionsAfterDOD"){
        setLifeAsiaTransactionModal(true);
    }
    else if (item?.linkValue?.toLowerCase() === "view") {
      const url =  process.env.REACT_APP_DMS_API_URL + `/omnidocs/WebApiRequestRedirection?Application=BPMPOLENQ&cabinetName=FG&sessionIndexSet=false&DataClassName=Future_Generali&DC.Application_no=${details?.policyDetailsObj?.identifiers?.applicationNo}`;
      window.open(url, '_blank');
    }
  }
  const handleDropdownChange = (e, item) => {
    if (item.name === "claimType") {
      setClaimTypee((prevClaimTypee) => e);
    }
    else if(item.name === "ClaimIntimatedBy")
    {
      setClaimIntimatedBy((prevClaimTypee) => e);
    }
    else if(item.name?.toLowerCase() === "natureofdeath"){
        setIsAccidentSelection(e);
      }
    
    

  };

  const handleSubDropdownChange = (value,obj,item)=>{
      //let CALL_TYP = masterData?.length>0 ? masterData?.filter((ele) => ele.key === "ASSESOR_DECI") :'';
      let SUB_TYP = masterData?.length>0 ? masterData?.filter((ele) => ele.key === "ASSESOR_SUB_DECI") :'';
      let transformedData = SUB_TYP[0]?.value.filter((ele)=>(ele.mstParentID === obj?.mstID)).map((ele) =>({
        ...ele,
        label: ele.mstDesc,
        value: ele.mstID
      }))
      setSubDescriptionLU(transformedData)
     if(item.name === 'AssesorsDecision' && [2,3,4,6,7,8,9].includes(value)){
      ClaimsData[selectedSubType]?.AssessorsRecommendation_Referrel_Details?.forEach(element => {
        if(['ReasonForDecision'].includes(element?.name)){
          element.hide = false
        }
      })
      setUpdateFields(!updateFields);
    }else if(item.name === 'AssesorsDecision' && ![2,3,4,6,7,8,9].includes(value)){
      ClaimsData[selectedSubType]?.AssessorsRecommendation_Referrel_Details?.forEach(element => {
        if(['ReasonForDecision'].includes(element?.name)){
          element.hide = true
        }
      });
      setUpdateFields(!updateFields);
    }
  }

  const visibilityRules = {
    NameofiIntimatingPerson: (context) => context.claimIntimatedBy !== "nominee",
    NameChangeAffidavit: (context) => context.isPennyDropStatus,
    NomineeDeathCertificate: (context) => context.isBeneficiaryChangeRequired,
    CopyofFirstInformationReport: (context) => context.isAccidentSelection === 3,
    CopyofPostMortemReport: (context) => context.isAccidentSelection === 3,
    NatureofDeath: (context) => context.ClaimTypee !== "CI"&& context.ClaimTypee  !== "TPD",
    DateofDeath: (context) => context.ClaimTypee !== "CI"&& context.ClaimTypee  !== "TPD",
    CauseofEvent: (context) => context.ClaimTypee === "CI" || context.ClaimTypee  === "TPD",
    DateofEvent: (context) => context.ClaimTypee === "CI" || context.ClaimTypee  === "TPD",
    claimsApplicable: (context) => details?.policyDetailsObj?.planAndStatus?.productType === "TD" || details?.policyDetailsObj?.planAndStatus?.planCode === "T07"
};

  const renderDetailsForm = (formType) => {
    const formFields = ClaimsData[selectedSubType]?.[formType];
    const formMapping = {
      "2": nomineeform,
      "3": nomineebankform,
      "4": uploadform
    };
    const context = {
        claimIntimatedBy,
        isPennyDropStatus,
        isBeneficiaryChangeRequired,
        isAccidentSelection,
        ClaimTypee,
    };
    return (
        <DetailsForm
            data={formFields?.map(field => {
                const rule = visibilityRules[field.name];
                if (rule) {
                    return { ...field, hide: !rule(context) }; 
                }
                return field; 
            })}
            subType={selectedSubType}
            suffix={!isShowPOSScreen && suffix}
            form ={selectedSubType === "claimsrequest" ? formMapping[activeTabKey] || form : form}
            getUploadFiles={getUploadFiles}
            handleTextLink={handleTextLink}
            clientRoleLU={clientRoleLU}
            onBlurInput={onBlurInput}
            handleDropdownChange={handleDropdownChange}
            handleSubDropdownChange={handleSubDropdownChange}
            handleDateChange={handleDateChange}
            featuredatedisabled={featuredatedisabled}
            disabledDate={disabledDate}
            causeOfEventLU={causeOfEventLU}
            natureOfDeathLU={natureOfDeathLU}
            ClaimTypee={ClaimTypee}
            policyTypeLU={policyTypeLU}
            claimCategoryLU={claimCategoryLU}
            claimIntimationLU={claimIntimationLU}
            sourceInformationLU={sourceInformationLU}
            assuredIncomePlanLU={assuredIncomePlanLU}
            handleRadioChange={handleRadioChange}
            laNomineeAddressLU={laNomineeAddressLU}
            subStageLU={subStageLU}
            assessorsDecisionLU={assessorsDecisionLU}
            handleRadioLink={handleRadioLink}
            policyStatusDOBLU={policyStatusDOBLU}
            dataBseCHeckLU = {dataBseCHeckLU}
            hotSpotCheckLU = {hotSpotCheckLU}
            referCaseToLU = {referCaseToLU}
            reinstatementDecisionLU={reinstatementDecisionLU}
            withDGHLU={withDGHLU}
            investigatorLU={investigatorLU}
            decisionDescriptionLU={decisionDescriptionLU}
            subDescriptionLU={subDescriptionLU}
        ></DetailsForm>
    );
};


const handleRadioLink = (item) => {
  if (["Name De-Dupe Match", "OFAC"].includes(item?.label)) {
    if (POSContactData?.deDupPayload?.length > 0) {
      for (let index in POSContactData?.deDupPayload) {
        if (selectedSubType) {
          if (POSContactData?.deDupPayload[index]?.type === 'NEGATIVELIST') {
            setNameDeDupeData(POSContactData?.deDupPayload[index]?.deDupPayload);
          }
          else if (POSContactData?.deDupPayload[index]?.type === 'Name') {
            setNameDeDupeData(POSContactData?.deDupPayload[index]?.deDupPayload);
          }
        }

      }
    }
    setNameDeDupeModal(true)
  }
  else if(["View Life Asia Transactions"].includes(item?.label)){
    setViewTransactionModal(true);
    setViewTransLoader(true);
    let response = apiCalls.GetAuditTrailDetails(details?.policyDetailsObj?.identifiers?.policyNo);
      response
        .then((val) => {
          if (val?.data?.responseHeader?.errorcode === "0") {
            setViewTransactionData(val?.data?.responseBody?.auditTrailDetailsList)
          } else {
            message.error({
              content:
                val?.data?.responseBody?.errormessage ||
                "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          }
          setViewTransLoader(false);
        })
        .catch((err) => {
          setViewTransLoader(false);
        });
  }
  else if(["View Agent Sourcing Details"].includes(item?.label)){
    setShowAgentSourcing(true);
    setViewTransLoader(true);
    let response = apiCalls.GetClaimPolicyDetails(details?.policyDetailsObj?.salesDetails?.agentCode);
      response
        .then((val) => {
          if (val?.data?.responseHeader?.errorcode === "0") {
            setShowAgentSourcingDetails(val?.data?.responseBody?.claims_PolicyDetails);
          } else {
            message.error({
              content:
                val?.data?.responseBody?.errormessage ||
                "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          }
          setViewTransLoader(false);
        })
        .catch((err) => {
          setViewTransLoader(false);
        });
  }
}
// const handleofacData = (data) => {
//   let name=data?.NomineeFirstName+" "+data?.NomineeLastName;
//   setOfcListModal(true)
//   let obj = {
//     "requestHeader": {
//       "source": "",
//       "policyNo": details?.policyDetailsObj?.identifiers?.policyNo,
//       "applicationNo": details?.policyDetailsObj?.identifiers?.applicationNo,
//       "dob": ""
//     },
//     "requestBody": {
//       "searchtype": "C",
//       "lastName": "",
//       "percentage": 0,
//       "percentageCIP": 0,
//       "type": "",
//       "country": "",
//       "dob": "",
//       "name": name,
//       "applicationNo": details?.policyDetailsObj?.identifiers?.applicationNo,
//       "createdby": "",
//       "source": "",
//       "panNo": "",
//       "passportNo": "",
//       "employercheck": ""
//     }
//   }
//   let response = apiCalls.getOFACDetailsApi(obj);
//   response
//     .then((val) => {
//       if (val?.data) {
//         setNegativeList(val?.data?.responseBody?.ofac)
//       } else {
//         message.error({
//           content:
//             val?.data?.responseBody?.errormessage ||
//             "Something went wrong please try again!",
//           className: "custom-msg",
//           duration: 2,
//         });
//       }
//       setIsLoading(false);
//     })
//     .catch((err) => {
//       setIsLoading(false);
//     });
// }

  const  onBlurInput =(value,item)=>{
  }
  const validatePANNumber = (_, value) => {
    if (emailExist) {
      return Promise.reject("PAN number already exists");
    } else if (value && !/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(value?.toUpperCase())) {
      return Promise.reject("Invalid PAN number and must be 10 digits");
    }  else {
      return Promise.resolve();
    }
  };
  const validatePhoneNumber = (_, value) => {
    if (emailExist) {
      return Promise.reject("Mobile number already exists");
    } else if (value && !/^[6-9]\d{9}$/.test(value)) {
      return Promise.reject("Mobile number should start with 6,7,8 or 9 and must be 10 digits");
    } else if (
      value &&
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(
        value
      )
    ) {
      return Promise.reject("Invalid mobile number");
    } 
    else {
      return Promise.resolve();
    }
  };
  const handleKeyDown = (pattern, e, type) => {
    const key = e.key;
    let specialCharacterRegex = '';
    if (pattern === 'numbersOnly') {
      const inputValue = e.target.value;
      if (inputValue.includes('.')) {
          specialCharacterRegex = /^[0-9]$/; 
      } else {
          specialCharacterRegex = /^[0-9.]$/;
      }
      
    } else if (pattern === 'charactersOnly') {
        specialCharacterRegex = /^[a-zA-Z0-9]$/;
    } else if (pattern === 'alphabatesOnly') {
        specialCharacterRegex = /^[a-zA-Z]$/;
    } else if (pattern === "decimalOnly") {
        const inputValue = e.target.value;
        if (inputValue.includes('.')) {
            specialCharacterRegex = /^[0-9]$/; 
        } else {
            specialCharacterRegex = /^[0-9.]$/;
        }
    }
    else if (pattern === 'NumbersAlphabetscommaonly') {
             specialCharacterRegex =  /^[a-zA-Z0-9, ]*$/;
  } 
  
    if (key === 'Backspace' || key.startsWith('Arrow')) {
        return;
    }
    if (!specialCharacterRegex.test(key)) {
        e.preventDefault(); 
    }
  };

  const CheckPANdetails = (panNumber,row,index)=>{
    let values = nomineeform.getFieldsValue();
    setIsLoading(true);
    let response = apiCalls.getCheckPANdetails(panNumber || values?.beneficiaryDetailsData[row?.id]?.NomineePANNumber);
    response
      .then((val) => {
        if (val?.data?.responseBody?.errorcode!=="1") {
          const res = val?.data?.responseBody;
            nomineeform.setFieldsValue({
              beneficiaryDetailsData: {
                  [row?.id]: {
                      NameonPAN: `${res?.firstName || ''} ${res?.middleName || ''} ${res?.lastName || ''}`,
                      PANValidationResult: res?.description,
                  },
              },
          });
          const updatedbeneficiaryData = [...beneficiaryDetailsData];
          updatedbeneficiaryData[index].NameonPAN = `${res?.firstName} ${res?.middleName || ''} ${res?.lastName}`;
          updatedbeneficiaryData[index].PANValidationResult = res?.description;
          setBeneficiaryDetailsData(updatedbeneficiaryData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          nomineeform.setFieldsValue({
            beneficiaryDetailsData: {
                [row?.id]: {
                    NameonPAN: val?.data?.responseBody?.errormessage,
                    PANValidationResult: val?.data?.responseBody?.errormessage,
                },
            },
        });
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }
  const getTransactionData = (values) => {  
    if (selectedSubType === "claimsrequest" && activeTabKey === "1") {
      let newArray = [
        { Status: "Create", TagName: "policyType", TagValue: values?.policyType || ""},
        { Status: "Create", TagName: "ApplicationDate", TagValue: values?.ApplicationDate || ""},
        { Status: "Create", TagName: "Totalpremiumpaidtilldate", TagValue: values?.Totalpremiumpaidtilldate || ""},
        { Status: "Create", TagName: "LifeAsiaTransactionsAfterDOD", TagValue: values?.LifeAsiaTransactionsAfterDOD || ""},
        { Status: "Create", TagName: "policyStatusOnDateOfDeath", TagValue: values?.policyStatusOnDateOfDeath || ""},
        { Status: "Create", TagName: "LAAgeonDOD", TagValue: values?.LAAgeonDOD || ""},
        { Status: "Create", TagName: "LifeAssuredGender", TagValue: values?.LifeAssuredGender || ""},
        { Status: "Create", TagName: "LAOccupation", TagValue: values?.LAOccupation  || ""},
        { Status: "Create", TagName: "LifeAssuredAnnualIncome", TagValue: values?.LifeAssuredAnnualIncome || ""},
        { Status: "Create", TagName: "DurationfromRCDTillDOD", TagValue: values?.DurationfromRCDTillDOD || ""},
        { Status: "Create", TagName: "DurationfromRCDTillDODIntimation", TagValue: values?.DurationfromRCDTillDODIntimation  || ""},
        { Status: "Create", TagName: "UWDecision", TagValue: values?.UWDecision || ""},
        { Status: "Create", TagName: "UWComments", TagValue: values?.UWComments  || ""},
      ]
      const properties = [
        "longdesc",
        "sumins",
        "LastReinstatementDate",
        "StatusonDateofDeath",
        "id"
      ];
      let updatedDataList = [];
      isRiderData?.forEach((record, recordIndex) => {
        properties.forEach((property, propertyIndex) => {
          if (record[property] || record[property] === 0) {
            let obj = {
              Status: "Create",
              TagName: `${property}_${recordIndex + 1}`,
              TagValue: property?.includes("LastReinstatementDate") ? moment(record[property] + 1).format("DD/MM/YYYY") : record[property]
            };
            updatedDataList.push(obj);
          }
        });
      });
      updatedDataList = [...updatedDataList, ...newArray];
      return updatedDataList;
    }
    if (selectedSubType === "claimsrequest" && activeTabKey === "2") {
        return [
          { Status: "Create", TagName: "ClaimsID", TagValue: values?.ClaimsID || ""},
          { Status: "Create", TagName: "ClaimServiceGuarateeApplicable", TagValue: values?.ClaimServiceGuarateeApplicable || ""},
          { Status: "Create", TagName: "AgeingFromIntimation", TagValue: values?.AgeingFromIntimation || ""},
          { Status: "Create", TagName: "AgeingFromRegisterationDate", TagValue: values?.AgeingFromRegisterationDate || ""},
          { Status: "Create", TagName: "claimType", TagValue: values?.claimType || ""},
          { Status: "Create", TagName: "NatureofDeath", TagValue: values?.NatureofDeath || ""},
          { Status: "Create", TagName: "exactCauseOfDeath", TagValue: values?.exactCauseOfDeath || ""},
          { Status: "Create", TagName: "DateofDeath", TagValue: values?.DateofDeath || ""},
          { Status: "Create", TagName: "claimCategory", TagValue: values?.claimCategory || ""},
          { Status: "Create", TagName: "ReasonForLateIntimation", TagValue: values?.ReasonForLateIntimation || ""},
          { Status: "Create", TagName: "LastRequirementReceivedDate", TagValue: values?.LastRequirementReceivedDate ?  moment(values?.LastRequirementReceivedDate + 1).format("DD/MM/YYYY"): ""},
          { Status: "Create", TagName: "AgeingFromLastDocumentReceived", TagValue: values?.AgeingFromLastDocumentReceived || ""},
          { Status: "Create", TagName: "DateofIntimation", TagValue: values?.DateofIntimation || ""},
          { Status: "Create", TagName: "RegistrationEffectiveDate", TagValue: values?.RegistrationEffectiveDate || ""},
        ]
      }
      if (selectedSubType === "claimsrequest" && activeTabKey === "4") {
        return [
          { Status: "Create", TagName: "ClaimHotspotCheck", TagValue: values?.ClaimHotspotCheck || ""},
          { Status: "Create", TagName: "IIBDataBaseCheck", TagValue: values?.IIBDataBaseCheck || ""},
          { Status: "Create", TagName: "IndustryCheck", TagValue: values?.IndustryCheck || ""},
          { Status: "Create", TagName: "AutoPayStatus", TagValue: values?.AutoPayStatus || ""},
        ]
      }
      else if (selectedSubType === "claimsrequest" && activeTabKey === "5") {
        return [
          { Status: "Create", TagName: "DidPolicyLapse", TagValue: values?.DidPolicyLapse || ""},
          { Status: "Create", TagName: "Lapsedon", TagValue: values?.Lapsedon ? moment(values?.Lapsedon + 1).format("DD/MM/YYYY")  : ""},
          { Status: "Create", TagName: "WasPolicyReinstated", TagValue: values?.WasPolicyReinstated || ""},
          { Status: "Create", TagName: "ReinstatementDate", TagValue: values?.ReinstatementDate ? moment(values?.ReinstatementDate + 1).format("DD/MM/YYYY")  : ""},
          { Status: "Create", TagName: "ReinstatementDecision", TagValue: values?.ReinstatementDecision || ""},
          { Status: "Create", TagName: "WithDGH", TagValue: values?.WithDGH || ""},
          { Status: "Create", TagName: "MedicalDisclosures", TagValue: values?.MedicalDisclosures || ""},
        ]
      }
      else if (selectedSubType === "claimsrequest" && activeTabKey === "6") {
        return [
          { Status: "Create", TagName: "DateofAssignment", TagValue: values?.DateofAssignment || ""},
          { Status: "Create", TagName: "AssignmentType", TagValue: values?.AssignmentType || ""},
          { Status: "Create", TagName: "AssigneeName", TagValue: values?.AssigneeName || ""},
          { Status: "Create", TagName: "AssignorName", TagValue: values?.AssignorName || ""},
          { Status: "Create", TagName: "LoanActive", TagValue: values?.LoanActive || ""},
          { Status: "Create", TagName: "OutstandingLoanAmount", TagValue: values?.OutstandingLoanAmount || ""},
        ]
      }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "7") {
      let newArray =
      [
        { Status: "Create", TagName: "Comments", TagValue: values?.Comments || ""},
        { Status: "Create", TagName: "NomineeChanged", TagValue: values?.NomineeChanged || ""},
        { Status: "Create", TagName: "IsLANomineeAddressSame", TagValue: values?.IsLANomineeAddressSame || ""},
        {Status: "Create",TagName: "Client_Id","TagValue":  values?.GSTINToBeUpdateFor === "1" ? customerData?.laClientID: customerData?.poClientID},
      ];
      let ExistingDataList = [];
      if(existingNomineeData?.length>0){
        const oldProperties = [
          "NomineeFirstName_Old",
          "NomineeLastName_Old",
          "NomineeDOB_Old",
          "Share_Old",
          "RealtionshipWithPolicyowner_Old",
          "Role_Old"
        ];
        existingNomineeData?.forEach((record, recordIndex) => {
          oldProperties?.forEach((property, propertyIndex) => {
            if (record[property]) {
              let obj = {
                Status: "Create",
                TagName: `${property}_${recordIndex + 1}`,
                TagValue: record[property]
              };
        
              ExistingDataList.push(obj);
            }
          });
        });
      }
      const properties = [
        "NomineePANNumber",
        "PANValidationResult",
        "NomineeMobile",
        "NameonPAN",
        "address",
        "NomineeEmail",
      ];
      
      let updatedDataList = [];
      beneficiaryDetailsData?.forEach((record, recordIndex) => {
        properties.forEach((property, propertyIndex) => {
          if (record[property] || record[property] == 0) {
            let obj = {
              Status: "Create",
              TagName: `${property}_${recordIndex + 1}`,
              TagValue: record[property]
            };
      
            updatedDataList.push(obj);
          }
        });
      });
      const nomineeproperties = [
        "NomineeFirstName_New",
          "NomineeLastName_New",
          "NomineeDOB_New",
          "Share_New",
          "RealtionshipWithPolicyowner_New",
          "Role_New",
      ];
      if(isBeneficiaryChangeRequired){
      let nomineeUpdateList = [];
      posUpdateNomineeData?.forEach((record, recordIndex) => {
        nomineeproperties.forEach((property, propertyIndex) => {
          if (record[property] || record[property] === 0) {
            let obj = {
              Status: "Create",
              TagName: `${property}_${recordIndex + 1}`,
              TagValue: property?.includes("NomineeDOB_New") ? moment(record[property] + 1).format("DD/MM/YYYY") : record[property]
            };
            nomineeUpdateList.push(obj);
          }
        });
      });
      updatedDataList = [...nomineeUpdateList, ...updatedDataList, ...ExistingDataList,...newArray];
    }else{
      updatedDataList = [...updatedDataList, ...ExistingDataList,...newArray];
    }
      return updatedDataList;
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "8") {
      let newArray =
      [
        { Status: "Create", TagName: "Comments", TagValue: values?.Comments || ""},
        {Status: "Create",TagName: "Client_Id","TagValue":  values?.GSTINToBeUpdateFor === "1" ? customerData?.laClientID: customerData?.poClientID},
      ];
      const properties = [
        "IFSC",
        "BankName",
        "BranchName",
        "AccountNumber",
        "ReAccountNumber",
        "AccountHolderName",
        "InitiatePennyDrop",
        "NameasperPennyDrop",
        "NameMatch"
      ];
      let updatedDataList = [];
      beneficiaryBankData?.forEach((record, recordIndex) => {
        properties.forEach((property, propertyIndex) => {
          if (record[property] || record[property] == 0) {
            let obj = {
              Status: "Create",
              TagName: `${property}_${recordIndex + 1}`,
              TagValue: record[property]
            };
            updatedDataList.push(obj);
          }
        });
      });
      updatedDataList = [...updatedDataList, ...newArray];
      return updatedDataList;
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "9") {
      let newArray = [
        // { Status: "Create", TagName: "WithinFGLIRetention", TagValue: values?.WithinFGLIRetention|| ""},
        // { Status: "Create", TagName: "FGLIRetention", TagValue: values?.FGLIRetention|| ""},
        // { Status: "Create", TagName: "ReInsurerName", TagValue: values?.ReInsurerName|| ""},
        // { Status: "Create", TagName: "ReInsurerParticipation", TagValue: values?.ReInsurerParticipation|| ""},
        // { Status: "Create", TagName: "LifeAsiaTransactions", TagValue: values?.LifeAsiaTransactions|| ""},
        { Status: "Create", TagName: "TotalClaimPayable", TagValue: values?.TotalClaimPayable|| ""},
      ]
      const properties = [
        "withinRetention",
        "retentionAmount",
        "reInsurerName",
        "participation",
      ];
      let updatedDataList = [];
      reInsureDetails?.forEach((record, recordIndex) => {
        properties.forEach((property, propertyIndex) => {
          if (record[property] || record[property] == 0) {
            let obj = {
              Status: "Create",
              TagName: `${property}_${recordIndex + 1}`,
              TagValue: record[property]
            };
            updatedDataList.push(obj);
          }
        });
      });
      updatedDataList = [...updatedDataList, ...newArray];
      return updatedDataList;
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "3") {
      return [
        { Status: "Create", TagName: "ClaimIntimatedBy", TagValue: values?.ClaimIntimatedBy|| ""},
        { Status: "Create", TagName: "ClaimIntimatedOn", TagValue: values?.ClaimIntimatedOn|| ""},
        { Status: "Create", TagName: "ClaimRegisteredBy", TagValue: values?.ClaimRegisteredBy|| ""},
        { Status: "Create", TagName: "ClaimRegisteredOn", TagValue: values?.ClaimRegisteredOn|| ""},
        { Status: "Create", TagName: "AssesmentDoneBy", TagValue: values?.AssesmentDoneBy|| ""},
        { Status: "Create", TagName: "AssesmentDoneOn", TagValue: values?.AssesmentDoneOn|| ""},
        { Status: "Create", TagName: "SelectSubStage", TagValue: values?.SelectSubStage|| ""},
      ]
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "10") {
      return [
        { Status: "Create", TagName: "RaiseRequirement", TagValue: isShowRequirements|| ""},
        { Status: "Create", TagName: "Requirements", TagValue: selectedRequirements|| ""},
        { Status: "Create", TagName: "isShowOtherDocument", TagValue: isShowOtherDocument|| ""},
        { Status: "Create", TagName: "otherInput", TagValue: values?.otherInput|| ""},
      ]
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "11") {
      return [
        // { Status: "Create", TagName: "RaiseRequirement", TagValue: isShowRequirements|| ""},
        // { Status: "Create", TagName: "Requirements", TagValue: selectedRequirements|| ""},
        // { Status: "Create", TagName: "isShowOtherDocument", TagValue: isShowOtherDocument|| ""},
        // { Status: "Create", TagName: "otherInput", TagValue: values?.otherInput|| ""},
      ]
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "12") {
      let newArray =
      [
        { Status: "Create", TagName: "InitiateInvestigation", TagValue: values?.InitiateInvestigation || ""},
        { Status: "Create", TagName: "ReferCaseTo", TagValue: values?.ReferCaseTo || ""},
        { Status: "Create", TagName: "ReferrelComments", TagValue: values?.ReferrelComments || ""},
      ];
      const properties = [
        "investigatorName",
        "investigationStartDate",
        "investigationEndDate",
      ];
      let updatedDataList = [];
      investDetails?.forEach((record, recordIndex) => {
        properties.forEach((property, propertyIndex) => {
          if (record[property] || record[property] == 0) {
            let obj = {
              Status: "Create",
              TagName: `${property}_${recordIndex + 1}`,
              TagValue: record[property]
            };
            updatedDataList.push(obj);
          }
        });
      });
      updatedDataList = [...updatedDataList, ...newArray];
      return updatedDataList;
    }
    else if (selectedSubType === "claimsrequest" && activeTabKey === "13") {
      return [
        { Status: "Create", TagName: "AssesorsDecision", TagValue: values?.AssesorsDecision || ""},
        { Status: "Create", TagName: "DecisionDescription", TagValue: values?.DecisionDescription || ""},
        { Status: "Create", TagName: "ReasonForDecision", TagValue: values?.ReasonForDecision || ""},
        { Status: "Create", TagName: "ReferralComments", TagValue: values?.ReferralComments || ""},
      ]
    }
  };

  const handleAccNumberChange = (index, field,value) => {
    const updatedData = [...beneficiaryDetailsData];
    updatedData[index][field] = value;
    setBeneficiaryDetailsData(updatedData);
  };
  const handleBeneficiaryBankDetailsChange = (index, field,value) => {
    const updatedData = [...beneficiaryBankData];
    updatedData[index][field] = value;
    setBeneficiaryBankData(updatedData);
  };
  const validateIFSCNumber = (_, value) => {
    if (value && !/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/.test(value)) {
      return Promise.reject("IFSC number must be 11 characters alphanumeric");
    } else {
      return Promise.resolve();
    }
  };

  const getIFSCBankDetails =async(ifscCode,row,index)=>{
    setIsLoading(true);
    let response = await apiCalls.getIFSCBanks(ifscCode);
    if (response.statusText) {
          if (response?.data.length >0) {
            nomineebankform.setFieldsValue({
              beneficiaryBankData: {
                  [row?.id]: {
                    BankName: response?.data[0]?.bank,
                    BranchName: response?.data[0]?.branch
                  },
              },
          });
          const updatedbeneficiaryobj = [...beneficiaryBankData];
          updatedbeneficiaryobj[index].BankName = response?.data[0]?.bank;
          updatedbeneficiaryobj[index].BranchName = response?.data[0]?.branch;
          setBeneficiaryBankData(updatedbeneficiaryobj);
            setIsLoading(false);
          } else {
            message.error({
              content:
              response?.data?.responseBody?.errormessage ||
                "Invalid IFSC",
              className: "custom-msg",
              duration: 2,
            });
            setIsLoading(false);
            nomineebankform.setFieldsValue({
              beneficiaryBankData: {
                  [row?.id]: {
                    IFSC: "",
                    BankName: "",
                    BranchName: ""
                  },
              },
          });
            
          }
        }
  }
  const handleBackClick = () => {
    const previousTabKey = (parseInt(activeTabKey, 10) - 1).toString();
    if (parseInt(previousTabKey, 10) >= 1) {
      setActiveTabKey(previousTabKey);
    }
  };

  const InitiatePennyDropp = (row) => {
    setIsPennyDropStatus(false);
    const values = nomineebankform.getFieldsValue();
    if(!values?.beneficiaryBankData[row?.id]?.AccountNumber || !values?.beneficiaryBankData[row?.id]?.AccountHolderName || !values?.beneficiaryBankData[row?.id]?.IFSC){
      message.destroy();
      message.error({
        content:"Enter All Mandatory Feilds",
        className: "custom-msg",
        duration: 2,
      });
     return;
    }
    
    let obj = {
      "accountNumber": values?.beneficiaryBankData[row?.id]?.AccountNumber,
      "accountHolderName":values?.beneficiaryBankData[row?.id]?.AccountHolderName || "",
      "ifsc": values?.beneficiaryBankData[row?.id]?.IFSC,
      "consent": "Y",
      "nameMatchType": "Individual",
      "useCombinedSolution":"N",
      "allowPartialMatch": "true",
      "preset": "G",
      "suppressReorderPenalty": "true",
      "clientData":{
        caseId: "null"
       }
  };
    var pennyPayload = {
      requestHeader : { source : "POS"},
      requestBody : obj
    }
    let response = apiCalls.bankaccverification(pennyPayload);
    response
      .then((result) => {
        if (result?.data) {
         if(result?.data?.responseBody?.statusCode === 101){
          nomineebankform.setFieldsValue({
            beneficiaryBankData: {
                [row?.id]: {
                  InitiatePennyDrop: result?.data?.responseBody?.result?.data?.source[0]?.data?.bankResponse,
                  NameasperPennyDrop: result?.data?.responseBody?.result?.data?.source[0]?.data?.accountName,
                },
            },
        });
        setIsPennyDropStatus(false);
         }else{
          nomineebankform.setFieldsValue({
            beneficiaryBankData: {
                [row?.id]: {
                  InitiatePennyDrop: result?.data?.responseHeader?.message
                },
            },
        });
        setIsPennyDropStatus(true);
         }
        } else {
          setIsLoading(false);
          setIsPennyDropStatus(true);
          nomineebankform.setFieldsValue({
            beneficiaryBankData: {
                [row?.id]: {
                  InitiatePennyDrop: 'Invalid Input'
                },
            },
        });
          message.error({
            content:
            result?.data?.responseBody?.errormessage ||result?.data?.responseHeader?.message ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        nomineebankform?.setFieldsValue({
          InitiatePennyDrop: 'Invalid Input',
       
        })
      });
  };

  const handleNomineeFirstNameChange = (index, value) => {
    const updatedData = [...posUpdateNomineeData];
    updatedData[index].NomineeFirstName_New = value;
    setPosUpdateNomineeData(updatedData);
  };
  
  const handleNomineeLastNameChange = (index, value) => {
    const updatedData = [...posUpdateNomineeData];
    updatedData[index].NomineeLastName_New = value;
    setPosUpdateNomineeData(updatedData);
  };

  const handleRelationshipChange = (index, value,row) => {
    const updatedData = [...posUpdateNomineeData];
    updatedData[index].RealtionshipWithPolicyowner_New = value;
    setPosUpdateNomineeData(updatedData);
  };

  const handleShareChange = (index, newShare) => {
    const updatedNomineeData = [...posUpdateNomineeData];
    updatedNomineeData[index].Share_New = newShare;
    setPosUpdateNomineeData(updatedNomineeData);
  };
  
  const handleBankAccNumber = (e, selectedFiledName,row) => {
    const selectedRowObj = nomineebankform.getFieldsValue();
    const obj = selectedRowObj?.beneficiaryBankData[row.id];
    if(selectedFiledName === 'ReAccountNumber'){
     setCNFBankAccNo(e)
    }else if(selectedFiledName === 'AccountNumber'){
      setBankAccNo(e)
    }
      if(obj.ReAccountNumber?.length >= 4 && selectedFiledName === 'ReAccountNumber'){
        if(BankAccNo !== e ){
          message.destroy();
    message.error({
      content:
        "Bank Number Not matched",
      className: "custom-msg",
      duration: 2,
    });
    nomineebankform.setFieldsValue({
      beneficiaryBankData: {
          [row?.id]: {
            ReAccountNumber: ''
          },
      },
  });
    }
      }else if(obj.AccountNumber?.length >= 4 && selectedFiledName === 'AccountNumber'){
      const lastFourDigits = obj.AccountNumber.slice(-4);
      const maskedString = '*'.repeat(obj.AccountNumber.length - 4) + lastFourDigits;
      nomineebankform.setFieldsValue({
        beneficiaryBankData: {
            [row?.id]: {
              AccountNumber: maskedString
            },
        },
    });
     }
  }

  const handleClaimDetailsTabSave = () => {
    const nextTabKey = (parseInt(activeTabKey, 10) + 1).toString();
    if (parseInt(nextTabKey, 10) <= 13) {
      setActiveTabKey(nextTabKey);
    }
  };

  const handleEdit = (val,isBeneficiary)=>{
    if (val === 'edit') {
        isBeneficiary ? setIsEditBeneficiary(true) : setIsEditNominee(true);
      } else if (val === 'close') {
        isBeneficiary ? setIsEditBeneficiary(false) : setIsEditNominee(false);
      }
  }
  const handleBankEdit = (val)=>{
    if (val === 'edit') {
       setIsBankEditable(true);
      } else if (val === 'close') {
        setIsBankEditable(false);
      }
  }
  const handleAssessmentSave =()=>{
    setShowSubmitBtn(true);
  }

  const getMandatetagEnquiry = ()=>{
    //setIsLoading(true);
    //setDisableOTP(true);
    //setECGRequestField(null);
    getMandateData();
    let empID = loggedUser?.allRoles[0]?.employeeID
    let response = apiCalls.getMandatetagEnquiry(customerData?.policyNo, empID);
    response
      .then((val) => {
        if (val?.data?.responseBody?.errorCode !== "1") {
            const sortedData = [...val?.data?.responseBody?.bankDetailsList];
    sortedData.sort((a, b) => {
      return new Date(convertDate(b.effdate)) - new Date(convertDate(a.effdate));
    });
          const res = sortedData[0];
          const isECGRequestValue = res?.mandstat === "10" ? "Active" : "Mandate Tag Not Found";
        //  setECGRequestField(isECGRequestValue);
       // form?.setFieldsValue({ECSRequest: isECGRequestValue})
        // setMandateDetailsData(sortedData);
        if(["mandatedetails", "mandatecancellation", "holdmandate" ].includes(selectedSubType)){
          const bankDetailsList = val?.data?.responseBody?.bankDetailsList;
          let primary = null;
          let secondary = null;
          let tertiary = null;
      
          const accountsWithTag = bankDetailsList.filter(account => account.zmandtag === 'P');
          const accountsWithoutTag = bankDetailsList.filter(account => account.zmandtag === '');
      
          accountsWithoutTag.sort((a, b) => new Date(a.effdate) - new Date(b.effdate));
      
          if (accountsWithTag.length > 0) {
            primary = accountsWithTag[0];
          }
      
          if (accountsWithoutTag.length > 0) {
            if (!primary) {
              primary = accountsWithoutTag[0];
              if (accountsWithoutTag.length > 1) {
                secondary = accountsWithoutTag[1];
                if (accountsWithoutTag.length > 2) {
                  tertiary = accountsWithoutTag[2];
                }
              }
            } else {
              secondary = accountsWithoutTag[0];
              if (accountsWithoutTag.length > 1) {
                tertiary = accountsWithoutTag[1];
              }
            }
          }
      
          const taggedAccounts = [primary, secondary, tertiary].filter(Boolean).map((account, index) => ({
            ...account,
            tag: index === 0 ? 'Primary' : index === 1 ? 'Secondary' : 'Tertiary'
          }));
      
         // setMandateDetailsData(taggedAccounts);
         }
        // else if(selectedSubType==="newmandateregistration"||selectedSubType==="holdmandate"||selectedSubType==="restartmandate"||selectedSubType==="mandatecancellation"){
        //   getMandateData(res?.mandref);
        //   setMandRefNo(res?.mandref);
        // }
        // } else {
        //   const isECGRequestValue = val?.data?.responseBody?.errorMessage;
        //   setECGRequestField(isECGRequestValue);
        // form?.setFieldsValue({ECSRequest: isECGRequestValue})
        //   setIsLoading(false);
        // }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const getMandateData = (mandref)=>{
    //setIsLoading(true);
   // setDisableOTP(true);
   // setECGRequestField(null);
    let response = apiCalls.getMandateData(customerData?.poClientID||details?.policyDetailsObj?.identifiers?.po_ClientID,mandref,customerData?.applicationNo);
    response
      .then((val) => {
        if (val?.data?.responseBody?.errorCode !== "1") {
          const res = val?.data?.responseBody
        // form?.setFieldsValue({
        //   PaymentMethod: "NACH",
        //   NACHStatus:  res?.statdets,
        //   RegisteredOn: res?.effdate ? convertDate(res?.effdate) : null,
        //   BankName: res?.bankkey,
        //   BankAccountNumber: res?.bankacckey,
        //   BankIFSC: "",
        //   PreferredDebitDate:res?.zddday || null,
        //   MaxDebitAmounat: res?.mandamt,
        //   NACHValidTill: handleAddYears()
        // })
        //   setIsLoading(false);
        } 
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const POSActionsOnContactDetails = (values, status, list) => {
//     let content  = status === 'REJECTED' ? "Please Select Documents to Reject": "Please Select Documents to move  Internally"
     let seletedRequerimentList =[]; 
//     if(status === 'INTERNAL'){
//       seletedRequerimentList = list
//      }
//     else if (status === 'REJECTED'){
// seletedRequerimentList = raiseRequerimentList
//      ?.filter((e) => e.status === true)
//      ?.map((e) => e.raiseReqId);
//      let dummy = '';
//      seletedRequerimentList.forEach(x => {
//        dummy = x.value;
//      })
//     }
   
    // if(status !== 'APPROVED'){
    //  if((seletedRequerimentList.length===0  && status === 'REJECTED') || (seletedRequerimentList.length===0 && status === 'INTENAL')){
    //    setIsLoader(false);
    //    setRequirementLoader(false);
    //    message.destroy();
    //    message.error({
    //      content: content,
    //      className: "custom-msg",
    //      duration: 3,
    //    });
    //  return;
    //  }
    // }
 

    let obj = {
      TransectionId: 1,
      SrvReqRefNo: POSContactData?.srvReqRefNo || serviceRequestId, 
      Status: status,
      RequirementList: seletedRequerimentList,
      UsrID: loginInfo?.userProfileInfo?.profileObj?.userName,
      RoleID: loginInfo?.userProfileInfo?.profileObj?.role,
      // "RequirementComments":requirementCmnt,
      Comments: values?.comment,
      TransactionPayload: [
        {
          "Status": "Update",
          "TagName": "POSComments1",
          "TagValue": values?.Comments
        }
      ],
    };

    if(status==="INTERNAL"){
      obj.TransactionPayload.push(
        {
          "Status": "create",
          "TagName": "InternalRequirementValue",
          "TagValue":JSON.stringify(seletedRequerimentList)
      },
    )}
    let response = apiCalls.POSActionsOnContactDetails(obj);
    response
      .then((val) => {
        if (val?.data) {
          setAlertTitle(status === 'REJECTED' ?  "Requirements Raised" : `${val?.data?.message}`);
          setNavigateTo("/claimsprimaryuser");
          setAlertData(`${"Ticket No " + val?.data?.srvReqRefNo}`);
          setShowAlert(true);
        } else {
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
        setIsLoader(false);
        setRequirementLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
        setRequirementLoader(false);
      });
  };
  const handleRequirementSubmit = () => {
    setRequirementLoader(true);
  //  if(isShowPOSScreen){
      POSActionsOnContactDetails(null, "REJECTED");
    // }else{
    //   saveRequest(formData);
    // }
    
  };
  const getRaiseRequirements = () => {
    setRaiseRequirementOpen(true);
    setRequirementLoader(true);
    let obj = {
      calltype: props?.propsData?.selectedCallType,
      subtype: props?.propsData?.selectedSubTypeId,
      Role:isShowPOSScreen?0:1
    };
    let response = apiCalls.getRaiseRequirements(obj);
    response
      .then((val) => {
        if (val?.data) {
          setRaiseRequerimentList(val?.data);
          setRequirementLoader(false);
        } else {
          setRequirementLoader(false);
          message.error({
            content:
              val?.data?.responseBody?.errormessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
      })
      .catch((err) => {
        setRequirementLoader(false);
      });
  };

  const GetClaimsPrimaryAssessmentEnquiry = async () => {
    setIsLoading(true);
    try {
      const response = await apiCalls.GetClaimsPrimaryAssessmentEnquiry(loginInfo?.userProfileInfo?.profileObj?.allRoles[0]?.employeeID, details?.policyDetailsObj?.identifiers?.policyNo || customerData?.policyNo);
      if (response?.data?.responseHeader?.errorcode === "0") {
        setIsRiderData(response?.data?.responseBody?.claimsPrimaryAssessmentEnquiry);
        if(activeTabKey === "9") {
          const deathAssuredNumber = Number(response?.data?.responseBody?.dsumins);
          setDeathSumAssured(deathAssuredNumber);
          reInsureForm.setFieldsValue({
            TotalClaimPayable: response?.data?.responseBody?.dsumins? Number(response?.data?.responseBody?.dsumins).toLocaleString('en-IN') : null
          })
        }
        setIsLoading(false);
      } else {
        message.error({
          content:
            response?.data?.responseBody?.errormessage ||
            "Something went wrong, please try again!",
          className: "custom-msg",
          duration: 2,
        });
        setIsLoading(false);
      }
    } catch (error) {
    }
  };

  const getGCPPolicydetails = () => {
    setIsLoading(true);
    let response = apiCalls.getFreeLookDetailsApi(process.env.REACT_APP_ENVIRONMENT == "UAT" ? process.env.REACT_APP_GCP_POLICY_NO : customerData?.policyNo);
    response
      .then((val) => {
        if (val?.data?.statusCode==="200") {
          // let maxDate = new Date(0);
          // let maxRecord = null;
          // // Iterate over the JSON data to find the record with the maximum date
          // val?.data?.response?.dispatch_details?.forEach(item => {
          //   // Extract day, month, and year from the date string
          //   if(item?.dispatchdate){
          //   const dateParts = item?.dispatchdate?.split('-'); // Change '/' to '-' assuming your date format is "YYYY-MM-DD"
          //   const year = parseInt(dateParts[0], 10);
          //   const month = parseInt(dateParts[1], 10) - 1; // Subtract 1 because months are zero-indexed
          //   const day = parseInt(dateParts[2], 10);
          //   const currentDate = new Date(year, month, day);
          
          //   if (currentDate > maxDate) {
          //     maxDate = currentDate;
          //     maxRecord = item;
          //   }
          // }
          // });
          // let dispatchDetails = maxRecord;
          // handleValidate(val?.data?.response?.delivery_details[0]?.dispatchDeliveryDate)

          form.setFieldsValue({
            ApplicationDate:  val?.data?.response?.applicationAttribute[0]?.proposalSignDate ? convertDate(val?.data?.response?.applicationAttribute[0]?.proposalSignDate) : null,
            Totalpremiumpaidtilldate: val?.data?.response?.tds_details[0]?.total_premium_paid_in_policy,
            LAOccupation:  val?.data?.response?.clientAttribute[0]?.laOccupation,
            LifeAssuredAnnualIncome: val?.data?.response?.clientAttribute[0]?.laIncome,
          })
          
          // const response= val?.data?.response?.policyAttribute[0];
          // if(selectedSubType === "statusenquiry"){
          //   setEnquiryListData(val?.data?.response?.delivery_details);
          //   setRtoDetails(val?.data?.response?.rto_details)

          //   setViewDispatchDetails(val?.data?.response?.dispatch_details)
          //   form.setFieldsValue({
          //     WelcomeCallComments: val?.data?.response?.applicationAttribute[0]?.welcomeCallDisposition,
          //     FreelookPeriodEndedOn:convertDates(val?.data?.response?.delivery_details[0]?.dispatchDeliveryDate)

          //   })
          // }else {
          //   setGCPDetailsData({
          //     // dispatchDetailsData: val?.data?.response?.dispatch_details || [],
          //     // deliveryDetailsData: val?.data?.response?.delivery_details || [],
          //     // rtoDetailsData: val?.data?.response?.rto_details || [],
          //     policyAttribute: val?.data?.response?.policyAttribute || [],
          //   });
          // }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error({
            content:
              val?.data?.statusMessage ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
       
      })
      .catch((err) => {
       
      });
  };

  const getUWFollowups = ()=>{
    let response = apiCalls.GetUWFollowups(customerData?.policyNo);
    response
      .then((val) => {
        if (val?.data?.responseBody?.errorCode !== "1") {
             setFollowUpsData(val?.data?.responseBody?.followupcodeList);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const handlePastComments =(values)=>{
    const newComment = addComments?.trim();
    if(!newComment || newComment === " "){
        return null;
    }

    let response = apiCalls.GetClaimsCommentsInfo(POSContactData?.srvReqID,addComments,loginInfo?.userProfileInfo?.profileObj?.name);
    response
      .then((val) => {
      message.success(val?.data);
      const newComment = {
        "createdDate": new Date(),
        "comments" : addComments
      };
      setAllComments((prevComments) => [...prevComments, newComment,]);
      setShowCommentsModal(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }
  const handleViewComments =()=>{
    apiCalls
    .GetClaimsViewCommentsInfo(POSContactData?.srvReqID)
    .then((val) => {
      if (val?.data?.length > 0) {
        // Concatenate all comments into a single string
        const commentsString = val.data
        .map((item) => {
          const formattedDate = moment(item.createdDate).format("MM/DD/YYYY, hh:mm:ss A");
          return  item.comments ? `${formattedDate}: ${item.comments}` : "";
        })
        .join("\n");

        setAllComments(val?.data);
         
         // referrelSheetForm.resetFields(['referralViewComments']); // Optional reset
          referrelSheetForm.setFieldsValue({
            referralViewComments: commentsString,
          });
          setForceUpdate((prev) => !prev);
      } else {
        console.warn("No comments found.");
      }
    })
    .catch((err) => {
      console.error("Error fetching comments:", err);
    })
    .finally(() => {
      setIsLoading(false);
    });
  
  }
  const handleReferralCmtCahnge =(e)=>{
   setIsReferallData(e.target.value);
  }
  const handleAddComments =()=>{
    referrelSheetForm.resetFields(['referralViewComments']);
    setForceUpdate((prev) => !prev);
  }

  const handleDobChange = (newDob, index) => {
    const updatedNomineeData = [...isRiderData];
    updatedNomineeData[index].LastReinstatementDate = newDob;
   setIsRiderData(updatedNomineeData);
  };
  const handleStatsDateofDeath = (index, value) => {
    const updatedData = [...isRiderData];
    updatedData[index].StatusonDateofDeath = value;
    setIsRiderData(updatedData);
  };
  
  const handleReInsureNameChange = (index, value) => {
    const updatedData = [...reInsureDetails];
    updatedData[index].reInsurerName = value;
    setReInsureDetails(updatedData);
  };
  const handleInvestNameChange = (index, value) => {
    const updatedData = [...investDetails];
    updatedData[index].investigatorName = value;
    setInvestDetails(updatedData);
  };
  const handleInvestStartDOB = (newDob, index) => {
    const updatedNomineeData = [...investDetails];
    updatedNomineeData[index].investigationStartDate = newDob;
    setInvestDetails(updatedNomineeData);
  };
  const handleInvestEndDOB = (newDob, index) => {
    const updatedNomineeData = [...investDetails];
    updatedNomineeData[index].investigationEndDate = newDob;
    setInvestDetails(updatedNomineeData);
  };

   const handleRetentionChange = (index, value, row) => {
    const updatedData = [...reInsureDetails];
    updatedData[index].withinRetention = value;
    updatedData[index].isDisable = value === "1" ? true : false;
    setReInsureDetails(updatedData);
  };
  const handleRoleChange = (index, value) => {
    const updatedData = [...reInsureDetails];
    updatedData[index].retentionAmount = value;
    setReInsureDetails(updatedData);
  };
  
  const handleParticipationChange = (index, value) => {
    const updatedData = [...reInsureDetails];
    updatedData[index].participation = value;
    setReInsureDetails(updatedData);
  };
  // const formatNumber = (value) => {
  //   // Remove non-numeric characters before formatting
  //   const numValue = value?.replace(/[^\d]/g, "");
  //   return Number(numValue)?.toLocaleString();
  // };


  const handleOFAVCHECK =()=>{
    setNameDeDupeModal(true)
      if(POSContactData?.deDupPayload?.length > 0){
        for (let index in POSContactData?.deDupPayload){
            if(POSContactData?.deDupPayload[index]?.type ==='NEGATIVELIST') {
              setNameDeDupeData(POSContactData?.deDupPayload[index]?.deDupPayload);
          }
          else if(POSContactData?.deDupPayload[index]?.type ==='Name') {
            setNameDeDupeData(POSContactData?.deDupPayload[index]?.deDupPayload);
          }
  
        }
    }
  }

   const handleDedupeMatch =(item)=>{
      setDeDupeModalOpen(true);
  }
  
  const handleClaimPayout = (key, value) => {
    setClaimPayout((prev) => (
      {
        ...prev,
        [key] : value
      }
    ))
  };

  const totalPayoutAmount = Object.entries(claimPayout).reduce((sum, [key, val]) => {
    const numericVal = parseFloat(val) || 0;
    let totalAmount;
  
    if (key === "premiumRecovery" || key === "survivalBenefit") {
      totalAmount = sum - numericVal;
    } else {
      totalAmount = sum + numericVal;
    }
  
    return !isNaN(totalAmount) ? totalAmount : sum;
  }, deathSumAssured);
  
  const formattedTotalPayoutAmount = totalPayoutAmount.toFixed(2);
    

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
        {selectedSubType === "claimsrequest" && <>
          <Tabs
            tabPosition="left"
            type="card"
            activeKey={activeTabKey}
            onChange={handleTabChange}
          >
            <TabPane
              tab={
                <span>
                  Policy Details
                </span>
              }
              key="1"
            >
                 <Form
        form={form}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("Primary_Policy_Details")}
                    <div className="mb-16 mt-16">
                   <h4 className="subtype-headings fs-16 fw-500">
                   View Rider Details
                      </h4>{"  "}
                  <div className="table-container email-table">
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th>Rider Name</th>
                          <th>Rider Sum Assured</th>
                          <th>Issue Date</th>
                          <th>Last Reinstatement Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      {isRiderData?.map((row,index) => (
                         row.sumins > 0 && (
                          <tr  key={index}>
                            <td>{row.longdesc|| "-"} </td>
                            <td>{row.sumins|| "-"} </td>
                            <td>{convertDate(details?.policyDetailsObj?.saDetails?.rcd)|| "-"} </td>
                            <td className="date-picker">
                                          <Form.Item
                                            name={['isRiderData', index, 'LastReinstatementDate']}
                                            className="inputs-label mb-0"
                                            initialValue={row?.LastReinstatementDate ? dayjs(row?.LastReinstatementDate, 'DD/MM/YYYY') : null}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Select Last Reinstatement Date",
                                              },
                                            ]}
                                          >
                                            <DatePicker
                                              allowClear={false}
                                              style={{ width: "100%" }}
                                              className="cust-input"
                                              placeholder="Select Last Reinstatement Date"
                                              format={dateFormat}
                                              value={row?.LastReinstatementDate}
                                              onChange={(e) => handleDobChange(e, index)}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            name={['isRiderData', index, 'StatusonDateofDeath']}
                                            className="inputs-label mb-0"
                                            initialValue={row?.StatusonDateofDeath ? parseInt(row?.StatusonDateofDeath) : null}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Select Status on Date of Death",
                                              },
                                            ]}
                                          >
                                            <Select
                                              className={`inputs-label cust-input select-width`}
                                              placeholder="Status on Date of Death"
                                              options={policyStatusDOBLU}
                                              value={row?.StatusonDateofDeath}
                                            onChange={(value) => handleStatsDateofDeath(index, value, row)}
                                            />
                                          </Form.Item>
                                        </td>
                          </tr>
                         )
                        ))}

                      
                        {isRiderData?.length === 0 && (
                          <tr>
                            <td colSpan="5">
                              <div className="text-center">
                                <span>No data available</span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
               
               </div>
                    <div className="contact-details-btn">
                      <Button type="primary" className="primary-btn" htmlType="submit">
                       Save
                      </Button>
                    </div>
                  </Form>

            </TabPane>
            <TabPane
              tab={
                <span>
                  Claim Details
                </span>
              }
              key="2"
            >
                 <Form
        form={claimDetailsForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("Primary_Claim_Details")}
                    <div className="contact-details-btn">

                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                      Save
                      </Button>

                    </div>
                 
                  </Form>
            </TabPane>
            <TabPane  tab={<span>Intimation Details</span> } key="3">
            <Form
        form={form}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("Primary_Intimation_Details")}
                    <div className="contact-details-btn">

                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                       Save
                      </Button>

                    </div>
                  </Form>
            </TabPane>
            <TabPane
              tab={
                <span>
                 Policy Checks
                </span>
              }
              key="4"
            >
              {(selectedSubType === "claimsrequest") &&
                <>
                 <Form
        form={policyCheckForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("PolicyChecks_Claim_Details")}
                    <div className="contact-details-btn">

                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                       Save
                      </Button>

                    </div>
                  </Form>

                </>}
            </TabPane>
            <TabPane  tab={<span>Reinstatement Details</span> } key="5">
            <Form
        form={reInstatementForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("Reinstatement_Details_Claim_Details")}
                    <div className="reuirement mt-16">
                    <h4 className="subtype-headings fs-16 fw-500">
                   View Medical Disclosures
                      </h4>{"  "}
           <table className="table responsive-table assistanceTable">
          <thead>
            <th>Date</th>
            <th>Remarks</th>
          </thead>
           <tbody>
                {followUpsData?.map((item, index) => (
                    <tr key={index + 1}>
                       <td>{item.reminderDate? convertDate(item.reminderDate):""}</td>
                       <td>{item.remarks}</td>
                    </tr>
                  ))}
                {followUpsData?.length === 0 &&
                  <tr>
                    <td colSpan={2}>
                      <div className="text-center"><span>No Data Available</span></div>
                    </td>
                  </tr>}
              </tbody>
           </table>
         </div>
                    <div className="contact-details-btn">

                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                       Save
                      </Button>

                    </div>
                  </Form>
            </TabPane>
            {details?.policyDetailsObj?.assigneeDetails?.isPolicyAssigned === 'Y' &&<>
            <TabPane  tab={<span>Assignment Details</span> } key="6">
            <Form
        form={assignmentForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("AssignmentDetails_Claim_Details")}
                    <div className="contact-details-btn">

                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                       Next
                      </Button>

                    </div>
                  </Form>
            </TabPane></>}
            {(ClaimTypee  !== "CI" && ClaimTypee  !== "TPD") &&<>
            <TabPane
              tab={
                <span>
                  Beneficiary Details
                </span>
              }
              key="7"
            >
              
              <Form
        form={nomineeform}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
           <div className="mb-16">
                  {renderDetailsForm("BeneficiaryDetails_Claim_Details")}
                  </div>

              <div className="mb-16">
              {/* <Form.Item label="Beneficiary Changed ?">
                    <Radio.Group onChange={e=>setIsBeneficiaryChangeRequired(e.target.value)}
                        value={isBeneficiaryChangeRequired}  disabled>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item> */}
                   <h4 className="subtype-headings fs-16 fw-500">
                   View Existing Beneficiary Details
                      </h4>{"  "}
                  <div className="table-container email-table">
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th>Nominee First Name</th>
                          <th>Nominee Last Name</th>
                          <th>Date of Birth</th>
                          <th>Role</th>
                          <th>Relationship</th>
                          <th>% Share</th>
                          <th>OFAC</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isShowPOSScreen&&<>
                      {existingNomineeData?.map((row,index) => (
                          <tr  key={index}>
                            <td>{row.NomineeFirstName_Old} </td>
                            <td>{row.NomineeLastName_Old} </td>
                            <td>{row.NomineeDOB_Old} </td>
                            <td>{row.Role_Old} </td>
                            <td>
                              {row.RealtionshipWithPolicyowner_Old} 
                              </td>
                            <td>{row.Share_Old} </td>
                            <td>
                            
                            <Form.Item
                              label={
                                <span>
                                  <span className="link-txt" onClick={()=>handleOFAVCHECK()}>{"OFAC Check"}</span> 
                                </span>
                              }
                              name=""
                              className={`inputs-label radio-grp fs-16 fw-400`}
                              rules={[
                                {
                                  required: false,
                                  message: "",
                                },
                               
                              ]}
                            >
                                  {/* {item?.title? <span className="link-txt" onClick={()=>handleRadioLink(item)}>{item?.title}</span> : ""} */}
                            </Form.Item>
                         
                                        </td>
                          </tr>
                        ))}
                      
                        {existingNomineeData?.length === 0 && (
                          <tr>
                            <td colSpan="6">
                              <div className="text-center">
                                <span>No data available</span>
                              </div>
                            </td>
                          </tr>
                        )}
                        </>}
                      </tbody>
                    </table>
                  </div>
               
               
               </div>
               <div className="mb-16">
               <Form.Item label="Is Existing Nominee Alive?"
               name="isExistingNomineeAlive" // name attribute for form binding
              // initialValue={isBeneficiaryChangeRequired} // set initial value here
               
               >
                    <Radio.Group 
                    //onChange={e=>setIsBeneficiaryChangeRequired(e.target.value)}
                    disabled={true} 
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {!isBeneficiaryChangeRequired&&<>
                    <h4 className="subtype-headings fs-16 fw-500">
                   View New Beneficiary Details
                   <span>
                   {Edit && <EditOutlined       onClick={() => {handleEdit('edit');setIsEdit(false)}} className="editIconn"/>}

{!Edit && <CloseOutlined   onClick={() => {handleEdit('close'); setIsEdit(true)}} className="editIconn" />}
                   </span>
                      </h4>{"  "}
                    <div className="table-container email-table">
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th>Beneficiary First Name</th>
                          <th>Beneficiary Last Name</th>
                          <th>Date of Birth</th>
                          <th>Role</th>
                          <th>Relationship</th>
                          <th>% Share</th>
                          <th>OFAC</th>
                        </tr>
                      </thead>
                      <tbody>
                    
                          {posUpdateNomineeData?.map((row, index) => (
            <tr key={row.id} className="nominee-input">
<td>
<Form.Item
name={['posUpdateNomineeData', index, 'NomineeFirstName']}
className="inputs-label mb-0"
initialValue={row?.NomineeFirstName} 
rules={[
{
required: true,
message: "Enter Nominee  First Name",
},
]}
>
<Input
placeholder="Enter Nominee First Name"
className="cust-input"
disabled={!isEditNominee}
maxLength={100}
onChange={(e) => handleNomineeFirstNameChange(index, e.target.value)}

/>
</Form.Item>

  </td>
  <td>
  <Form.Item
name={['posUpdateNomineeData', index, 'NomineeLastName']}
className="inputs-label mb-0"
initialValue={row?.NomineeLastName} // Set the initial value here
rules={[
{
required: true,
message: "Enter Nominee  Last Name",
},
]}
>
<Input
placeholder="Enter Nominee Last Name"
className="cust-input"
disabled={!isEditNominee}
maxLength={100}
onChange={(e) => handleNomineeLastNameChange(index, e.target.value)}

/>
</Form.Item>
  </td>

             <td className="date-picker">
             <Form.Item
name={['posUpdateNomineeData', index, 'NomineeDOB']}
className="inputs-label mb-0"
initialValue={
  row?.NomineeDOB && typeof row.NomineeDOB === 'string' 
    ? dayjs(row.NomineeDOB, 'DD/MM/YYYY') 
    : null
}
rules={[
{
required: true,
message: "Select a DOB",
validator: (_, value) => {
  if (!value) {
  return Promise.resolve();  // Allow empty value for the first record
  }
  return Promise.resolve();
  },
},
]}
>
             <DatePicker
             allowClear={false}
     style={{ width: "100%" }}
     className="cust-input"
     placeholder="Select a DOB"
     format={dateFormat}
     
     value={row?.NomineeDOB}
     //onChange={(e) => handleDobChange(e, index)}
    disabled={!isEditNominee}
   />
   </Form.Item>
             </td>
             <td>
             <Form.Item
name={['posUpdateNomineeData', index, 'Role']}
className="inputs-label mb-0"
initialValue={row.Role}
rules={[
{
required: true,  // Make it required only if index is not 0
message: 'Select a Role',
validator: (_, value) => {
if (index === 0 && !value) {
return Promise.resolve();  // Allow empty value for the first record
}
if (index === 0 && value !== 'nominee') {
return Promise.reject('The first record must have "nominee" as the Role');
}
return Promise.resolve();
},
},
]}
>
<Select
className={`inputs-label cust-input select-width`}
placeholder="Select a Role"
value={row?.Role}
disabled
options={[
{
value: "nominee",
label: "Nominee",
},
{
value: "appointee",
label: "Appointee",
},
]}
//onChange={(value) => handleRoleChange(index, value,row)}
/>
</Form.Item>

               </td>
             <td>
             <Form.Item
name={['posUpdateNomineeData', index, 'RealtionshipWithPolicyowner']}
className="inputs-label mb-0"
initialValue={row?.RealtionshipWithPolicyowner}
rules={[
{
required: true,
message: "Select a RelationShip",
validator: (_, value) => {
  if (index === 0 && !value) {
  return Promise.resolve();  // Allow empty value for the first record
  }
  return Promise.resolve();
  },
},
]}
>
             <Select
                className={`inputs-label cust-input select-width`}
                 placeholder="Select a RelationShip"
                 options={relationShipLU}
                 value={row?.RealtionshipWithPolicyowner}
                 onChange={(value) => handleRelationshipChange(index, value,row)}
                disabled={!isEditNominee}
               />
               </Form.Item>
               </td>
             <td>
             <Form.Item
name={['posUpdateNomineeData', index, 'Share']}
className="inputs-label mb-0"
initialValue={row?.Share}
rules={[
{
required: true,
message: "Enter a Share",
validator: (_, value) => {
  if (index === 0 && !value) {
  return Promise.resolve();  // Allow empty value for the first record
  }
  return Promise.resolve();
  },
},
]}
>
             <Input
   className="cust-input"
   value={row?.Share}
   placeholder="Enter a Share"
   maxLength={20}
   onChange={(e) => handleShareChange(index, e.target.value,row)}
   onKeyDown={(e) => handleKeyDown("numbersOnly",  e)}
   disabled
 />
 </Form.Item>
               </td>
               <td>
                            
                            <Form.Item
                              label={
                                <span>
                                  <span className="link-txt" onClick={()=>handleRadioLink()}>{"OFAC Check"}</span> 
                                </span>
                              }
                              name=""
                              className={`inputs-label radio-grp fs-16 fw-400`}
                              rules={[
                                {
                                  required: false,
                                  message: "",
                                },
                               
                              ]}
                            >
                            </Form.Item>
                         
                                        </td>
           </tr>
          ))}
          {posUpdateNomineeData?.length === 0 && (
            <tr>
              <td colSpan="6">
                <div className="text-center">
                  <span>No data available</span>
                </div>
              </td>
            </tr>
          )}
                      </tbody>
                    </table>
                  </div>
                  </>}
               </div>
                  {
                    isPolicyAssigned?.isPolicyAssigned === "Y" && 
                    <div className="mb-10">
                      <h4 className="subtype-headings fs-16 fw-500">
                        View Assignee Details
                      </h4>{"  "}
                      <table className="claims-table">
                          <thead>
                            <th>Assignee Name</th>
                            <th>Assignee ID</th>
                            <th>Assignee Email</th>
                            <th>Assignee Mobile Number</th>
                          </thead>                    
                          <tbody>
                            <td>{isPolicyAssigned?.assigneeName}</td>
                            <td>{isPolicyAssigned?.assigneeID}</td>
                            <td>{isPolicyAssigned?.assigneeEmailID}</td>
                            <td>{isPolicyAssigned?.assigneeMobileNo}</td>
                        </tbody>
                      </table>
                    </div>
                  }
                      <div className="d-flex">
                    <h4 className="subtype-headings fs-16 fw-500">
                    View Personal Details of Beneficiary
                    <span>
                    {isBeneficiaryEdit && <EditOutlined       onClick={() => {handleEdit('edit',"beneficiary");setIsBeneficiaryEdit(false)}} className="editIconn"/>}

{!isBeneficiaryEdit && <CloseOutlined   onClick={() => {handleEdit('close',"beneficiary"); setIsBeneficiaryEdit(true)}} className="editIconn" />}
                    </span>
                      </h4>{"  "}
                      </div>
                    <table className="claims-table">
      {beneficiaryDetailsData?.map((row, index) => (
           <tbody className="nominee-section">
          <tr className="nominee-input">
            <td>Nominee PAN</td>
            <td>
              <Form.Item
                name={['beneficiaryDetailsData', index, 'NomineePANNumber']}
                className="inputs-label mb-0"
                key={index}
                initialValue={row?.NomineePANNumber}
                rules={[
                  {
                    required: true,
                    message: 'Enter Nominee PAN',
                  },
                  {
                    validator: validatePANNumber,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Nominee PAN"
                  className="cust-input upper"
                  maxLength={11}
                  minLength={11}
                  disabled={!isEditBeneficiary}
                  onKeyDown={(e) => handleKeyDown("charactersOnly",e,"pannumber")}
                  onChange={(e) => handleAccNumberChange(index, 'NomineePANNumber', e.target.value)}
                  onBlur={(e) => {
                    CheckPANdetails(e.target.value, row,index);
                    e.target.value = e.target.value.toUpperCase();
                  }}
                />
              </Form.Item>
            </td>
            <td>Name on PAN</td>
            <td>
              <Form.Item
                name={['beneficiaryDetailsData', index, 'NameonPAN']}
                className="inputs-label mb-0"
                initialValue={row?.NameonPAN}
                rules={[
                  {
                    required: false,
                    message: 'Name on PAN',
                  },
                ]}
              >
                <Input
                  placeholder="Name on PAN"
                  className="cust-input"
                  disabled
                  maxLength={100}
                  value={row?.NameonPAN}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td>PAN Validation Result</td>
            <td>
            <Form.Item
                name={['beneficiaryDetailsData', index, 'PANValidationResult']}
                className="inputs-label mb-0"
                initialValue={row?.PANValidationResult}
                rules={[
                  {
                    required: false,
                    message: 'PAN Validation Result',
                  },
                ]}
              >
                <Input
                  placeholder="PAN Validation Result"
                  className="cust-input"
                  disabled
                  maxLength={100}
                  value={row?.PANValidationResult}
                />
              </Form.Item>
            </td>
            <td>Nominee Address</td>
            <td>
              <Form.Item
                 name={['beneficiaryDetailsData', index, 'address']}
                className="inputs-label mb-0"
                initialValue={row?.address}
                rules={[
                  {
                    required: false,
                    message: 'Enter Nominee Address',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Nominee Address"
                  className="cust-input"
                  maxLength={100}
                  disabled={!isEditBeneficiary}
                  onChange={(e) => handleAccNumberChange(index, 'address', e.target.value)}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td>Nominee Mobile</td>
            <td>
              <Form.Item
                name={['beneficiaryDetailsData', index, 'NomineeMobile']}
                className="inputs-label mb-0"
                initialValue={row?.NomineeMobile}
                rules={[
                  {
                    required: true,
                    message: 'Enter Nominee Mobile',
                  },
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Nominee Mobile"
                  className="cust-input"
                  maxLength={10}
                  minLength={10}
                  disabled={!isEditBeneficiary}
                  onKeyDown={(e) => handleKeyDown("numbersOnly",  e)}
                  onChange={(e) => handleAccNumberChange(index, 'NomineeMobile', e.target.value)}
                />
              </Form.Item>
            </td>
            <td>Nominee Email</td>
            <td>
              <Form.Item
                name={['beneficiaryDetailsData', index, 'NomineeEmail']}
                className="inputs-label mb-0"
                initialValue={row?.NomineeEmail}
                rules={[
                  {
                    required: true,
                    message: 'Enter Nominee Email',
                  },
                  {
                    validator(_, value) {
                      if (emailExist) {
                        return Promise.reject("Email already exist");
                      } else if (
                        value &&
                        !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$/.test(
                          value
                        )
                      ) {
                        return Promise.reject("Invalid email");
                      } 
                      else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter Nominee Email"
                  className="cust-input"
                  maxLength={100}
                  minLength={100}
                  disabled={!isEditBeneficiary}
                  onChange={(e) => handleAccNumberChange(index, 'NomineeEmail', e.target.value)}
                />
              </Form.Item>
            </td>
          </tr>
          </tbody>
      ))}
    </table>
              <div className="contact-details-btn">
              <Button type="primary" className="primary-btn" onClick={() => handleBackClick()}>
                  Back
                </Button>
                <Button type="primary" className="primary-btn" htmlType="submit">
                  Save
                </Button>
              </div>
              </Form>
            </TabPane>
            </>}

            <TabPane
              tab={
                <span>
                  Beneficiary Bank Details
                </span>
              }
              key="8"
            >
              <Form
        form={nomineebankform}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                <div className="d-flex">
  <h4 className="subtype-headings fs-16 fw-500">
    Beneficiary Bank Details
    <span>
    {isBeneficiaryBankEdit && <EditOutlined       onClick={() => {handleBankEdit('edit');setIsBeneficiaryBankEdit(false)}} className="editIconn"/>}
{!isBeneficiaryBankEdit && <CloseOutlined   onClick={() => {handleBankEdit('close'); setIsBeneficiaryBankEdit(true)}} className="editIconn" />}
                    </span>
  </h4>{" "}
 
</div>
                    <table className="claims-table">
      {beneficiaryBankData?.map((row, index) => (
        <React.Fragment key={row?.id}>
           <tbody className="nominee-section">
          <tr className="nominee-input">
            <td>IFSC</td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'IFSC']}
                className="inputs-label mb-0"
                initialValue={row?.IFSC}
                rules={[
                  {
                    required: true,
                    message: 'Enter IFSC',
                  },
                    {
                      validator: validateIFSCNumber,
                    },
                ]}
              >
                <Input
                  placeholder="Enter IFSC"
                  className="cust-input upper"
                  maxLength={11}
                  minLength={11}
                  disabled={!isBankEditable}
                  onKeyDown={(e) => handleKeyDown("charactersOnly",e)}
                  onBlur={(e)=>getIFSCBankDetails(e.target.value,row,index)}
                  onChange={(e) => handleBeneficiaryBankDetailsChange(index, 'IFSC', e.target.value)}
                />
              </Form.Item>
            </td>
            <td>Bank Name</td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'BankName']}
                className="inputs-label mb-0"
                initialValue={row?.BankName}
                rules={[
                  {
                    required: false,
                    message: 'Bank Name',
                  },
                ]}
              >
                <Input
                  placeholder="Bank Name"
                  className="cust-input"
                  disabled
                  maxLength={100}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td>Branch Name</td>
            <td>
            <Form.Item
                name={['beneficiaryBankData', index, 'BranchName']}
                className="inputs-label mb-0"
                initialValue={row?.BranchName}
                rules={[
                  {
                    required: false,
                    message: 'Branch Name',
                  },
                ]}
              >
                <Input
                  placeholder="Branch Name"
                  className="cust-input"
                  disabled
                  maxLength={100}
                />
              </Form.Item>
            </td>
            <td>Enter Account Number</td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'AccountNumber']}
                className="inputs-label mb-0"
                initialValue={row?.AccountNumber}
                rules={[
                  {
                    required: true,
                    message: 'Account Number',
                  },
                ]}
              >
                <Input
                  placeholder="Account Number"
                  className="cust-input"
                  maxLength={20}
                  disabled={!isBankEditable}
                  onBlur={(e) => handleBankAccNumber(e.target.value,"AccountNumber",row)}
                  onKeyDown={(e) => handleKeyDown("numbersOnly",  e)}
                  onChange={(e) => handleBeneficiaryBankDetailsChange(index, 'AccountNumber', e.target.value)}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td>Re-Enter Account Number</td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'ReAccountNumber']}
                className="inputs-label mb-0"
                initialValue={row?.ReAccountNumber}
                rules={[
                  {
                    required: true,
                    message: 'Re-Enter Account Number',
                  },
                ]}
              >
                <Input
                  placeholder="Re-Enter Account Number"
                  className="cust-input"
                  maxLength={20}
                  disabled={!isBankEditable}
                  onBlur={(e) => handleBankAccNumber(e.target.value,"ReAccountNumber",row)}
                  onKeyDown={(e) => handleKeyDown("numbersOnly",  e)}
                  onChange={(e) => handleBeneficiaryBankDetailsChange(index, 'ReAccountNumber', e.target.value)}
                />
              </Form.Item>
            </td>
            <td>Account Holder Name</td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'AccountHolderName']}
                className="inputs-label mb-0"
                initialValue={row?.AccountHolderName}
                rules={[
                  {
                    required: true,
                    message: 'Account Holder Name',
                  },
                ]}
              >
                <Input
                  placeholder="Account Holder Name"
                  className="cust-input"
                  maxLength={100}
                  disabled={!isBankEditable}
                  onChange={(e) => handleBeneficiaryBankDetailsChange(index, 'AccountHolderName', e.target.value)}
                />
              </Form.Item>
            </td>
            </tr>
            <tr>
            <td>
            <a
                        onClick={() => InitiatePennyDropp(row)}
                        style={{ color: "#b3201f" }}
                        className="text-label"
                      >
              Initiate Penny Drop
              </a>
              </td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'InitiatePennyDrop']}
                className="inputs-label mb-0"
                initialValue={row?.InitiatePennyDrop}
                rules={[
                  {
                    required: true,
                    message: 'Initiate Penny Drop',
                  },
                ]}
              >
                <Input
                  placeholder="Initiate Penny Drop"
                  className="cust-input"
                  maxLength={100}
                  disabled={!isBankEditable}
                  onChange={(e) => handleBeneficiaryBankDetailsChange(index, 'InitiatePennyDrop', e.target.value)}
                />
              </Form.Item>
            </td>
            <td>Name as per Penny Drop</td>
            <td>
              <Form.Item
                name={['beneficiaryBankData', index, 'NameasperPennyDrop']}
                className="inputs-label mb-0"
                initialValue={row?.NameasperPennyDrop}
                rules={[
                  {
                    required: false,
                    message: 'Name as per Penny Drop',
                  },
                ]}
              >
                <Input
                  placeholder="Name as per Penny Drop"
                  className="cust-input"
                  maxLength={100}
                  disabled
                />
              </Form.Item>
            </td>
            </tr>
            <tr>
  <td>Name Match</td>
  <td>
    <Form.Item
      name={['beneficiaryBankData', index, 'NameMatch']}
      className="inputs-label mb-0"
      initialValue={row?.NameMatch}
      rules={[
        {
          required: true,
          message: 'Name Match is required',
        },
      ]}
    >
      <Radio.Group
        onChange={(e) => handleBeneficiaryBankDetailsChange(index, 'NameMatch', e.target.value)}
        className="radio-check"
        disabled={!isBankEditable}
      >
        <Radio className="fs-16 fw-400"
        value="yes">
          Yes
        </Radio>
        <Radio className="fs-16 fw-400"
        value="no">
          No
        </Radio>
      </Radio.Group>
    </Form.Item>
  </td>
  <td>
  <Form.Item
                  label={
                    <span>
                      <span className="link-txt" onClick={()=>handleDedupeMatch()}>{"Dedupe Match Details"}</span> 
                    </span>
                  }
                  name=""
                  className={`inputs-label radio-grp fs-16 fw-400`}
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                   
                  ]}
                >
                      {/* {item?.title? <span className="link-txt" onClick={()=>handleRadioLink(item)}>{item?.title}</span> : ""} */}
                </Form.Item>
  </td>
</tr>

          </tbody>
        </React.Fragment>
      ))}
    </table>
              <div className="contact-details-btn">
                <Button type="primary" className="primary-btn" onClick={() => handleBackClick()}>
                  Back
                </Button>
                <Button type="primary" className="primary-btn" htmlType="submit">
                  Save
                </Button>
              </div>
              </Form>
            </TabPane>
            <TabPane  tab={<span>Claim Payment Details</span> } key="9">
            <Form
        form={reInsureForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {/* {renderDetailsForm("Claim_Payment_Details")} */}
                    <div className="d-flex mb-16">
                                <h4 className="subtype-headings fs-16 fw-500">
                                Re-Insurer Details
                                </h4>{"  "}
                                <span className="d-flex justify-center" style={{ paddingLeft: "10px" }}><i class="bi bi-plus-circle-fill c-pointer text-color fs-18" onClick={() => handleAdd()}></i></span>
                              </div>
                              <div className="table-container email-table mb-16">
                              <table className="responsive-table">
                                <thead>
                                <tr>
            <th>Within FGLI Retention</th>
            <th>FGLI Retention Amount</th>
            <th>Re-Insurer Name</th>
            <th>Re-Insurer Participation</th>
            <th>Action</th>
          </tr>
                                </thead>
                                <tbody>
           {reInsureDetails?.map((row, index) => (
                                      <tr key={row.id} className="nominee-input">
                                        <td>
                                          <Form.Item
                                            name={['reInsureDetails', row.id, 'withinRetention']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Select With in Retention",
                                              },
                                            ]}
                                          >
                                            <Select
                                              className={`inputs-label cust-input select-width`}
                                              placeholder="Select a With in Retention"
                                              options={[
                                                {
                                                  value: "1",
                                                  label: "Yes",
                                                },
                                                {
                                                  value: "2",
                                                  label: "No",
                                                },
                                              ]}
                                              onChange={(value) => handleRetentionChange(index, value, row)}
                                              />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            name={['reInsureDetails', row.id, 'retentionAmount']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required:  !row?.isDisable,
                                                message: "Enter Retention Amount",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Retention Amount"
                                              className="cust-input"
                                              value={row.retentionAmount} 
                                              maxLength={100}
                                              disabled={row?.isDisable}
                                              onChange={(e) => handleRoleChange(index, e.target.value, row)}
                                             onKeyDown={(e) => handleKeyDown("numbersOnly", e)}
                                           />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            name={['reInsureDetails', row.id, 'reInsurerName']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required: !row?.isDisable,
                                                message: "Enter Retention Name",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Retention Name"
                                              className="cust-input"
                                              value={row.reInsurerName}
                                              maxLength={100}
                                              disabled={row?.isDisable}
                                              onChange={(e) => handleReInsureNameChange(index, e.target.value, row)}
                                             onKeyDown={(e) => handleKeyDown("alphabatesOnly", e)}
                                           />
                                            
                                          </Form.Item>

                                        </td>
                                        <td>
                                        <Form.Item
                                            name={['reInsureDetails', row.id, 'participation']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required: !row?.isDisable,
                                                message: "Enter Participation",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Participation"
                                              className="cust-input"
                                              value={row.participation}
                                              maxLength={100}
                                              disabled={row?.isDisable}
                                              onChange={(e) => handleParticipationChange(index, e.target.value, row)}
                                             onKeyDown={(e) => handleKeyDown("numbersOnly", e)}
                                           />
                                          </Form.Item>
                                        </td>
                                        

                                        <td>
                                          {index !== 0 && <>
                                            <i
                                              class="bi bi-trash3-fill"
                                              onClick={() => handleDelete(row.id, index)}
                                              style={{ color: "#b3201f", cursor: "pointer" }}
                                            ></i>
                                          </>}
                                        </td>

                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            {renderDetailsForm("Primary_ClaimPayable_Details")}
                    
                    <table className="custom-table table-input">
                      <tbody>
                          <tr>
                            <td>Death Sum Assured</td>
                            <td>{deathSumAssured}</td>
                          </tr>
                          <tr>
                            <td>Add: Rider Sum Assured</td>
                            <td>
                              <input type="number" min="0" step="0.01" onChange={(e) => handleClaimPayout("riderSumAssured", e.target.value)}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Premiums to be Waved</td>
                            <td>
                              <input type="number" min="0" step="0.01" onChange={(e) => handleClaimPayout("premiumsToBeWaved", e.target.value)} />
                            </td>
                          </tr>
                          <tr>
                            <td>Add: Premium Suspense</td>
                            <td>
                              <input type="number" min="0" step="0.01" onChange={(e) => handleClaimPayout("premiumSuspense", e.target.value)}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Add: Interest Charges</td>
                            <td>
                              <input type="number" step="0.01" min="0" onChange={(e) => handleClaimPayout("interestCharges", e.target.value)}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Add: Penal Interest Charges</td>
                            <td>
                              <input type="number" min="0" step="0.01" onChange={(e) => handleClaimPayout("penalInterestCharges", e.target.value)}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Less: Premium Recovery</td>
                            <td>
                              <input type="number" step="0.01" min="0" onChange={(e) => handleClaimPayout("premiumRecovery", e.target.value)}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Less: Survival Benefit/Withdrawals</td>
                            <td>
                              <input type="number" min="0" step="0.01" onChange={(e) => handleClaimPayout("survivalBenefit", e.target.value)}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Payout Amount Payable</td>
                            <td>{formattedTotalPayoutAmount}</td>
                          </tr>
                      </tbody>
                    </table>
                   
                    <div className="contact-details-btn">
                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                       Save
                      </Button>

                    </div>
                  </Form>
            </TabPane>
         
            <TabPane
              tab={
                <span>
                  Raise Requirements
                </span>
              }
              key="10"
            >
                   <Form
        form={form}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    <div className="mb-16 mt-16">
              <Form.Item label="Raise Requirement">
                    <Radio.Group onChange={e=>setIsShowRequirements(e.target.value)}
                        value={isShowRequirements}  >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {isShowRequirements && <>
                  <h4 className="subtype-headings fs-16 fw-500">
                   Add Requirements
                      </h4>{"  "}
                    <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={handleChange}
        >
            {options.map(option => (
                <Option key={option.key} value={option.value}>
                    {option.value}
                </Option>
            ))}
        </Select>
        <Form.Item label="Add Any Other Related Documents" className="mb-16 mt-16">
                    <Radio.Group onChange={e=>setIsShowOtherDocument(e.target.value)}
                        value={isShowOtherDocument}  >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {isShowOtherDocument && <>
                    <Form.Item
                  label=""
                  name= "otherInput"
                  className="inputs-label mb-0"
                  rules={[
                    {
                      required: true,
                      message: "requied",
                    },   
                  ]}
                >
                  <TextArea rows={5}  maxLength={1000} placeholder={"Enter Text"} />
                </Form.Item>

                  </>}
        </>}
               </div>
              
                    <div className="contact-details-btn">
                      <Button type="primary" className="primary-btn" htmlType="submit">
                       Save
                      </Button>
                      {showRaiseRequirementBtn && <>
                      <Button
                            type="primary"
                            className="primary-btn"
                           // onClick={() => getRaiseRequirements()}
                           onClick={()=>handleRequirementSubmit()}
                          >
                            Raise Requirement
                          </Button>
                          </>}
                    </div>
                  
                  </Form>
                </TabPane>
                {selectedRequirements?.length > 0 && <>
                <TabPane  tab={<span>View Requirements</span> } key="11">
                <div>
                <Form
        form={form}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
      <h2>View Requirements</h2>

      {selectedRequirements?.map((requirement,index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <span style={{ width: 150 }}>{requirement}</span>
          
          <Checkbox 
            onChange={e => handleCheckboxChange(e, index, 'received')}
            style={{ marginRight: 20 }}
          >
            Received
          </Checkbox>

          <Checkbox 
            onChange={e => handleCheckboxChange(e, index, 'notReceived')}
            style={{ marginRight: 20 }}
          >
            Not Received
          </Checkbox>

          <Select
            style={{ width: 200 }}
            onChange={value => handleSelectChange(value, index)}
            placeholder="Accept / Reject / Waive"
          >
            <Option value="accept">Accept</Option>
            <Option value="reject">Reject</Option>
            <Option value="waive">Waive</Option>
          </Select>
        </div>
      ))}
        {isShowRequirements && <>
                    <div className="contact-details-btn">
                      <Button type="primary" className="primary-btn" htmlType="submit">
                       Save
                      </Button>
                    </div>
                    </>}
                    </Form>
    </div>
                </TabPane>
                </>}
                <TabPane  tab={<span>Referrel Sheet</span> } key="12">
                <Form
        form={referrelSheetForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                    {renderDetailsForm("Referrel_Sheet_Details")}
                    {hideInvestDetails && <>
                    <div className="d-flex mb-16 mt-16">
                                <h4 className="subtype-headings fs-16 fw-500">
                                Add Investigator
                                </h4>{"  "}
                                <span className="d-flex justify-center" style={{ paddingLeft: "10px" }}><i class="bi bi-plus-circle-fill c-pointer text-color fs-18" onClick={() => handleInvestAdd()}></i></span>
                              </div>
                    <div className="table-container email-table mb-16">
                              <table className="responsive-table">
                                <thead>
                                <tr>
            <th>Investigator Name</th>
            <th>Investigation Start Date</th>
            <th>Investigation End Date</th>
            <th>Action</th>
          </tr>
                                </thead>
                                <tbody>
           {investDetails?.map((row, index) => (
                                      <tr key={row.id} className="nominee-input">
                                        <td>
                                          <Form.Item
                                            name={['investDetails', row.id, 'investigatorName']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Select Investigator Name",
                                              },
                                            ]}
                                          >
                                            <Select
                                              className={`inputs-label cust-input select-width`}
                                              placeholder="Select a Investigator Name"
                                              options={investigatorLU}
                                              onChange={(value) => handleInvestNameChange(index, value, row)}
                                              />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            name={['investDetails', row.id, 'investigationStartDate']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Select investigation Start Date",
                                              },
                                            ]}
                                          >
                                             <DatePicker
                                              allowClear={false}
                                              disabledDate={(e)=>disabledDate(e)}
                                              style={{ width: "100%" }}
                                              className="cust-input"
                                              placeholder="Investigation Start Date"
                                              format={dateFormat}
                                              value={row.investigationStartDate}
                                              onChange={(e) => handleInvestStartDOB(e, index)}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            name={['investDetails', row.id, 'investigationEndDate']}
                                            className="inputs-label mb-0"
                                            rules={[
                                              {
                                                required: false,
                                                message: "Investigation End Date",
                                              },
                                            ]}
                                          >
                                                <DatePicker
                                              allowClear={false}
                                              disabledDate={(e)=>disabledDate(e)}
                                              style={{ width: "100%" }}
                                              className="cust-input"
                                              placeholder="Investigation End Date"
                                              format={dateFormat}
                                              value={row.investigationEndDate}
                                              onChange={(e) => handleInvestEndDOB(e, index)}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          {index !== 0 && <>
                                            <i
                                              class="bi bi-trash3-fill"
                                              onClick={() => handleInvestDelete(row.id, index)}
                                              style={{ color: "#b3201f", cursor: "pointer" }}
                                            ></i>
                                          </>}
                                        </td>

                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            </>}
                            {renderDetailsForm("Referrel_Sheet_ReferTo")}
                            <div className="text-area mt-16">
  <Form.Item
    label="Referral Comments"
    name="referralViewComments" // Ensure this matches the key in `setFieldsValue`
    className="inputs-label mb-0"
    rules={[
      {
        required: false,
        message: "Referral Comments are required",
      },
    ]}
  >
   
    {/* <TextArea
      rows={8}
      placeholder="Enter referral comments here"
     // readOnly // Makes the field read-only
     value={referrelSheetForm.getFieldValue("referralViewComments")}
     onChange={(e) => handleReferralCmtCahnge(e)}
     disabled={!!referrelSheetForm.getFieldValue("referralViewComments")}
    /> */}
    {/* <div
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
        textAlign: 'right',
      }}
    >
      <a
        onClick={() => handleAddComments()}
        style={{
          display: 'block',
          marginBottom: '5px',
          color: "#b3201f",
        }}
      >
        Add Comments
      </a>
      <a
        onClick={() => handleViewComments()}
        style={{
          display: 'block',
          marginBottom: '5px',
          marginRight: '-40px',
          color: "#b3201f",
        }}
      >
        View Past Comments
      </a>
    </div> */}
  </Form.Item>
    <div className="table-container1">
      <table className="custom-table comments-tb">
        <thead>
          <tr>
            <th>Date</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {
            allComments?.length > 0 ? 
            allComments.map((item) => (
              <tr key={item.commentID}>
                <td>{convertDate(item?.createdDate)}</td>
                <td>{item?.comments}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan="2">
                  <div className="text-center">
                    <span>No data available</span>
                  </div>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
</div>

                    <div className="contact-details-btn">

                      <Button type="primary" className="primary-btn" htmlType="submit"
                      >
                       Save
                      </Button>

                    </div>
                  </Form>
                </TabPane>
                <TabPane
              tab={
                <span>
                 Assessors Recommendation
                </span>
              }
              key="13"
            >
                 <Form
        form={assessorsForm}
        name="wrap"
        labelCol={{
          flex: "35%",
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={handleSubmit}
        autoComplete="off"
      >
                {renderDetailsForm("AssessorsRecommendation_Referrel_Details")}
                <div className="text-area mt-16">
                    <Form.Item
                      label="Referral Comments"
                      name="ReferralComments"
                      className="inputs-label mb-0"
                      rules={[
                        {
                          required: false,
                          message: "Referral Comment",
                        },
                      ]}
                    >
                      <TextArea rows={6} placeholder="Comment Box" />
                    </Form.Item>
                  </div>
                <div className="contact-details-btn">
                      <Button type="primary" className="primary-btn" onClick={()=>handleAssessmentSave()}>
                       Save
                      </Button>
                      <Button type="primary" className="primary-btn" htmlType="submit"
                       disabled={!showSubmitBtn}>
                       Submit
                      </Button>
                    </div>
                    </Form>
                </TabPane>
          </Tabs>
        </>}

      {/* </Form> */}
      {showAlert && (
        <PopupAlert
          alertData={alertData}
          title={alertTitle}
          getAdvance={props?.propsData?.getAdvance}
          navigate={navigateTo}
          setShowAlert={setShowAlert}
          isShow={true}
        ></PopupAlert>
      )}

<Modal
       open={lifeAsiaTransactionModal}
       destroyOnClose={true}
       width={1200}
       closeIcon={false}
       footer={null}
     >

       <div>

         <div className="reuirement">
           <table className="table responsive-table assistanceTable">
          <thead>
            <th>Transaction #</th>
            <th>Effective Date</th>
            <th>Description</th>
            <th>GL Code</th>
            <th>User ID</th>
            <th>Transaction Date</th>
            <th>Transaction Amount</th>
          </thead>
           <tbody>
                {lifeTransactionData?.map((item, index) => (
                    <tr key={index + 1}>
                       <td>{item.transaction}</td>
                       <td>{item.effectiveDate?convertDate(item.effectiveDate):""}</td>
                       <td>{item.description}</td>
                       <td>{item.glCode}</td>
                       <td>{item.userId}</td>
                        <td>{item.transactionDate?convertDate(item.transactionDate):""}</td>
                        <td>{item.transactionAmount}</td>

                    </tr>
                  ))}
                {lifeTransactionData?.length === 0 &&
                  <tr>
                    <td colSpan={7}>
                      <div className="text-center"><span>No Data Available</span></div>
                    </td>
                  </tr>}
              </tbody>
           </table>
         </div>
         <div className="contact-details-btn">
           <Button
             type="primary"
             className="primary-btn"
             onClick={() => setLifeAsiaTransactionModal(false)}>
             OK
           </Button>
         </div>
       </div>
     </Modal>
     <Modal
        title="Negative List"
        open={NameDeDupeModal}
        destroyOnClose={true}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setNameDeDupeModal(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
        <div className="table-container" style={{ marginTop: "0px" }}>
          <table className="responsive-table">
            <tr>
              <th>Name</th>
              <th>Program
              </th>
              <th>Type</th>

            </tr>
            {NameDeDupeData?.map((item, index) => (
              <tr key={index}>
                <td>
                  {item?.name}
                </td>
                <td>
                  {item?.program}
                </td>

                <td>{item?.type}</td>

              </tr>
            ))}
            {NameDeDupeData?.length === 0 &&
              <tr>
                <td colspan="4">

                  <div className="text-center"><span>No data available</span></div>
                </td>
              </tr>}
          </table>
        </div>
      </Modal>
      <Modal
        title="Add Comments"
        open={showCommentsModal}
        destroyOnClose={true}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setShowCommentsModal(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
        <div className="table-container" style={{ marginTop: "0px" }}>
          <TextArea rows={5}  maxLength={1000} placeholder={"Add your comments"} value={addComments} onChange={(e) => setAddComments(e.target.value)}/>
          <div className="contact-details-btn">
           <Button
             type="primary"
             className="primary-btn"
             onClick={handlePastComments}
            >
             Save
           </Button>
           </div>
        </div>
      </Modal>
      <Modal
        title="Requirements"
        open={raiseRequirementOpen}
        destroyOnClose={true}
        width={1200}
        closeIcon={false}
        footer={null}
      >
        <Spin spinning={requirementModalLoader}>
          <div  >
            <Form onFinish={handleRequirementSubmit}>
              <div className="reuirement">

              
              <table className="responsive-table">
                <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Description</th>
                  <th className="z-index">Select</th>
                </tr></thead>
                <tbody>
                  {raiseRequerimentList && raiseRequerimentList?.map((item, ind) => (
                    <tr key={ind + 1}>
                      <td>{ind + 1}</td>

                      <td>{item.raiseReqDesc}</td>
                      <td>
                        {" "}
                        <Checkbox
                          type="checkbox"
                          onChange={(e) => (item.status = e.target.checked)}
                        />
                      </td>
                    </tr>
                  ))}
                  {raiseRequerimentList?.length === 0 && (
                    <tr>
                      <td colspan="13">
                        <div className="text-center">
                          <span>No data available</span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
              {/* <div className="text-area mt-16">
             <Form.Item
                      // label={<span>{"Comment"} <sup>*</sup>
                      // </span>}
                      name="requirementCmnt"
                      className="inputs-label mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Enter Comments",
                        },
                      ]}
                    >
                       <TextArea rows={2} value={requirementCmnt} placeholder="Comments" onChange={(e)=>setRequirementCmnt(e.currentTarget.value)}/>
                    </Form.Item>
                  </div> */}
          <div className="contact-details-btn">
                <Button
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  onClick={()=>handleRequirementSubmit()}
                >
                  Submit
                </Button>

                <Button
                  type="primary"
                  className="primary-btn"
                  onClick={() => setRaiseRequirementOpen(false)}
                >
                  Close
                </Button>
              </div>
            </Form>
          </div>

          
        </Spin>
      </Modal>

      <Modal
      title={<span style={{ color:"#b21f1f", fontWeight: 'bold' }}>OFAC List Check Details</span>}
        open={NameDeDupeModal}
        destroyOnClose={true}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setNameDeDupeModal(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
         <div className="table-container" style={{ marginTop: "0px" }}>
          <table className="responsive-table">
            <tr>
              <th>Name</th>
              <th>Program
</th>
              <th>Type</th>
            
            </tr>
            {NameDeDupeData?.map((item,index) => (
            <tr key={index}>
            <td>
              {item?.name}
            </td>
            <td>
              {item?.program}
            </td>
            
              <td>{item?.type}</td>
             
            </tr>
          ))}
           {NameDeDupeData?.length === 0  &&
               <tr>
                  <td colspan="4">
               
                <div className="text-center"><span>No data available</span></div> 
        </td>
        </tr>}
          </table>
        </div>
      </Modal>
      <Modal
        title="View Life Asia Transactions"
        open={viewTransactionModal}
        destroyOnClose={true}
        width={1200}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setViewTransactionModal(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
         <Spin spinning={viewTransLoader}>
         <div className="table-container" style={{ marginTop: "0px" }}>
          <table className="responsive-table">
            <tr>
              <th>Transaction No</th>
              <th>Effective Date</th>
              <th>Description</th>
              <th>GL Code</th>
              <th>User ID</th>
              <th>Transaction Date</th>
            
            </tr>
            {viewTransactionData?.map((item,index) => (
            <tr key={index}>
            <td>
              {item?.tranno}
            </td>
            <td>
              {item?.effdate? convertDate(item?.effdate) : null}
            </td>
            
              <td>{item?.description}</td>
              <td>{}</td>

              <td>{item?.userid}</td>
              <td>{item?.datime}</td>
             
            </tr>
          ))}
           {viewTransactionData?.length === 0  &&
               <tr>
                  <td colspan="6">
               
                <div className="text-center"><span>No data available</span></div> 
        </td>
        </tr>}
          </table>
        </div>
        </Spin>
      </Modal>
      <Modal
        title="Agent Sourcing Details"
        open={showAgentSourcing}
        destroyOnClose={true}
        width={600}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setShowAgentSourcing(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
         <Spin spinning={viewTransLoader}>
         <div className="table-container" style={{ marginTop: "0px" }}>
          <table className="responsive-table">
            <tr>
              <th>Sr No</th>
              <th>Policy No</th>
              <th>Policy Status</th>
            </tr>
            {showAgentSourcingDetails.length > 0 ? showAgentSourcingDetails.map((item,index) => (
              <tr key={index}>
              <td>
                {index + 1}
              </td>
              <td>
                {item?.chdrnum}
              </td>
              <td>
                {item?.statcode}
              </td> 
              </tr>
            ))
           :
              <tr>
                <td colspan="6">
                  <div className="text-center"><span>No data available</span></div> 
                </td>
              </tr>}
          </table>
        </div>
        </Spin>
      </Modal>
      <Modal
        title=""
        open={deDupeModalOpen}
        destroyOnClose={true}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setDeDupeModalOpen(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
        <div className="table-container" style={{ marginTop: "20px" }}>
          <table className="responsive-table">
            <tr>
              <th>Policy Number</th>
              <th>Account Number</th>
              <th>Account Holder Name</th>
              <th>Customer Name</th>
            </tr>
            {BankduDupeData?.map((item,index) => (
            <tr key={index}>
            <td>{item?.LA_PolicyNo}</td>
              <td>{item?.Acc_Number}</td>
              <td>{item?.Acc_HldrName}</td>
              <td>{item?.CustomerName}</td>
            </tr>
          ))}
           {BankduDupeData?.length === 0  &&
               <tr>
                  <td colspan="4">
               
                <div className="text-center"><span>No data available</span></div> 
        </td>
        </tr>}
          </table>
        </div>
      </Modal>
      {/* <Modal
        title="Negative List"
        open={ofcListModal}
        destroyOnClose={true}
        closeIcon={
          <Tooltip title="Close">
            <span onClick={() => setOfcListModal(false)}>
              <img src={CloseIcon} alt=""></img>
            </span>
          </Tooltip>
        }
        footer={null}
      >
        <div className="table-container" style={{ marginTop: "0px" }}>
          <table className="responsive-table">
            <tr>
              <th>Name</th>
              <th>Program
              </th>
              <th>Type</th>

            </tr>
            {negativeList?.map((item, index) => (
              <tr key={index}>
                <td>
                  {item?.name}
                </td>
                <td>
                  {item?.program}
                </td>

                <td>{item?.type}</td>

              </tr>
            ))}
            {negativeList?.length === 0 &&
              <tr>
                <td colspan="4">

                  <div className="text-center"><span>No data available</span></div>
                </td>
              </tr>}
          </table>
        </div>
      </Modal> */}
    </>
  );
}

export default ClaimsPrimaryAssesment;