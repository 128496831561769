import React, { useState,useEffect,useRef } from 'react';
import { Card,Form,Select,Row,Col, Table,Spin, message,Checkbox, Space,Button,Modal,Tooltip,Input,DatePicker } from 'antd';
import apiCalls from "../../api/apiCalls";
import moment from 'moment';
import { useSelector } from 'react-redux';
import CloseIcon from "../../assets/images/close-icon.png";


const ComplaintsDashboard = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const loggedUser = useSelector(state => state?.userProfileInfo?.profileObj);
  const [isLoading,setIsLoading] = useState(false);
  const [data,setData] = useState([]);
  const [showTotalPages,setShowTotalpages] = useState(null);
  const [callTypeLU,setCallTypeLU] = useState([]);
  const [selectedCallType,setSelectedCallType] = useState("");
  const [selectedSubType,setSelectedSubType] = useState(null);
  const [selectedCaseType,setSelectedCaseType] = useState(null);
  const [subTypeLU,setSubTypeLU] = useState([]);
  const [masterData,setMasterData] = useState([]);
  //const [selectionType, setSelectionType] = useState('checkbox');
  const [countData,setCountData] = useState({});
  const [caseStatusLU,setCaseStatusLU] = useState([]);
  const [clientIDLU,setClientIDLU] = useState([]);
  const [usersListLU,setUsersListLU] = useState([]);
  const [selectionList,setSelectionList] = useState([]);
  const [selectedUserName,setSelectedUserName] = useState(null);
  const [userAssigned,setUserAssigned] = useState(false);
  const [tableKey, setTableKey] = useState(0); // Key to force remount
  const [isAdvanceSearchModalOpen,setIsAdvanceSearchModalOpen] = useState(false);
  const [Ruless,setRuless] = useState();
  const dateFormat = "DD/MM/YYYY";
  const [CALL_TyPES, setCALL_TyPES] = useState([]);
  const [requestModeLU, setRequestModeLU] = useState([]);
  const [complaintCount,setComplaintCount]=useState([]);
  const [isShowAssignCases, setIsShowAssignCases] = useState(false);
  const [assignSelectedUserName, setAssignSelectedUserName] = useState("");
 const [selectedRowKeys, setSelectedRowKeys] = useState([]);
 const [ageingDetails, setAgeingDetails] = useState([]);
 const [showAgeingModal, setShowAgeingModal] = useState(false);
 const [ageingAdmindata, setAgeingAdmindata] = useState([]);




  const statusLU = [
    { label: 'Closed', value: 'closed' },
    { label: 'Pending', value: 'pending' },
    { label: 'Closed With Requirements', value: 'closedwithrequirements' },
    { label: 'Failed', value: 'failed' },
    ];

    const complaintTypeLU = [
      { label: 'IGMS', value: 'IGMS' },
      { label: 'Potential', value: 'Potential' },
      ];

    const statusIGMSLU = [
      { label: 'New', value: 'New' },
      { label: 'Acknowledged', value: 'Acknowledged' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Attended To', value: 'Attended To' },
      { label: 'Escalated', value: 'Escalated' },
      { label: 'Re-open', value: 'Re-open' },
      { label: 'Closed', value: 'Closed' },
      ];

  const options = [
    {value: "chetan",label:"Chetan N"},
    {value: "john",label:"John"},
    {value: "vishnu",label:"Vishnu"},
  ]
  const defaultColumns = [
    {
      title: 'Select',
      dataIndex: 'select',
      key: 'select',
      render: (_, record) => (
        <Checkbox
          onChange={() => onSelectChange(record)}
          checked={selectedRowKeys?.includes(record?.srvReqRefNo)}
        />
      ),
    },
    {
      title: "Service Request ID",
      dataIndex: "srvReqRefNo",
      key: 'srvReqRefNo',
    },
    {
      title: "Policy Number",
      dataIndex: "policyNo",
      key: 'policyNo',
      // showSorterTooltip: false,
      // sorter: {
      //   compare: (a, b) => a.policyNo - b.policyNo,
      // },
    },
    {
      title: "PO Name",
      dataIndex: "pO_Name",
      key: 'pO_Name',
    },
    // {
    //   title: "LA Name",
    //   dataIndex: "lA_Name",
    //   key: 'lA_Name',
    // },
    {
      title: "Call Type",
      dataIndex: "callType",
      key: 'callType',
    },
    {
      title: "Sub Type",
      dataIndex: "subType",
      key: 'subType',
    },
    {
      title: "Complaint Type",
      dataIndex: "compl_Type",
      key: 'compl_Type',
    },
    {
      title: "Complaint Token ID",
      dataIndex: "irdA_Token_Number",
      key: 'irdA_Token_Number',
    },
    {
      title: "Status",
      dataIndex: "currentStatus",
      key: 'currentStatus',
    },
    {
      title: "IGMS Status",
      dataIndex: "igmsStatus",
      key: 'igmsStatus',
    },
    {
      title: "Mode",
      dataIndex: "reqModeName",
      key: 'reqModeName',
    },  
    {
      title: "Assign To",
      dataIndex: "userId",
      editable: true,
    //   render: (text, record) => (
    //     <Select
    //  // mode="multiple"
    //   allowClear
    //   style={{ width: "100%" }}
    //   placeholder="Please select"
    //   onChange={(item)=>handleChange(item,record)}
    // >
    //    {usersListLU?.map((users, idx) => (
    //                 <Option key={idx} value={users?.userId}>
    //                   {users?.userName}
    //                 </Option>
    //               ))}
    //               </Select>
    //   ),
    },
//     {
//       title: "Action",
//       dataIndex: "action",
//       render: (_, record) => (
//         <Space size="middle">

// <a className="editIcon"> <i  onClick={() => saveAssignTo(record)} className="bi bi-send"></i></a>

//          {/* <Button
//                         type="primary"
//                         className="primary-btn panvalidate-btn"
//                         onClick={() => handleAction(record.serviceNo)}
//                       >
//                         View
//                       </Button> */}
//         </Space>
//       ),
//     },
   
  ];
  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        //handleSave,
      }),
    };
  });
  const tableData = [
    {key: 0,srno:1, serviceNo: "DDMMYYYXXXX01", policyNo: "00110825", poName: "", laName: "",callType:"Contact Details Update",subType:"Mobile Number Update",caseStatus:"Resolved",payoutVlaue:"",clientIdType:"Corporate",assignTo:"Chetan"},
    {key: 1,srno:2,  serviceNo: "DDMMYYYXXXX02",policyNo: "00110826", poName: "", laName: "", callType:"Payment Related",subType:"Payment Link",caseStatus:"New Request",payoutVlaue:"",clientIdType:"Individual",assignTo:""},
    {key: 2,srno:3, serviceNo: "DDMMYYYXXXX03", policyNo: "00110827", poName: "", laName: "", callType:"Bank Details",subType:"Updation",caseStatus:"Pending",payoutVlaue:"",clientIdType:"Individual",assignTo:""},
    {key: 3,srno:4,  serviceNo: "DDMMYYYXXXX04",policyNo: "00110828", poName: "", laName: "", callType:"",subType:"",caseStatus:"",payoutVlaue:"",clientIdType:"",assignTo:""},
    {key: 4,srno:5,  serviceNo: "DDMMYYYXXXX05",policyNo: "00110829", poName: "", laName: "",callType:"",subType:"",caseStatus:"",payoutVlaue:"",clientIdType:"",assignTo:""},
  ]

  const onSelectChange = (record) => {
    let newSelectedRowKeys = []; // Initialize an empty array to hold the new selected row keys
    if (selectedRowKeys.includes(record?.srvReqRefNo)) {
      // If the clicked checkbox is already selected, deselect it
      setSelectedRowKeys([]);
      setSelectionList([]);
    } else {
      // If the clicked checkbox is not already selected, clear the existing selection and select the clicked checkbox
      newSelectedRowKeys = [record?.srvReqRefNo];
      setSelectedRowKeys(newSelectedRowKeys);
      let val = [];
      val?.push(record);
      setSelectionList(val);
    }
  };

  const shouldLog = useRef(true);
  useEffect(() => {
    if(shouldLog.current||userAssigned){
      shouldLog.current = false;
      getCTST();
      getAdminData();
      getUserdData()
      getGridData();
      GetComplaintDashboardCountApi();
    }
  }, [userAssigned]); //eslint-disable-line react-hooks/exhaustive-deps

  // rowSelection object indicates the need for row selection
// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//   setSelectionList(selectedRows);
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
// };

const GetComplaintDashboardCountApi=()=>{

  let response = apiCalls.GetComplaintDashboardCount(24, loggedUser?.userName, 23);
  response.then((val) => {
    if (val?.data) {
      setComplaintCount(val?.data?.dashboardSummaries)
    } else {
      message.destroy();
      message.error({
        content: val?.data?.responseHeader?.message || "Something went wrong please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
    setIsLoading(false);
  }).catch((err) => {
    setIsLoading(false);
  })
}
const GetAgeingCount = ()=>{
  let response = apiCalls.GetAgeingCount(24,null, 23);
  response.then((val) => {
    if (val?.data) {
      setAgeingAdmindata(val?.data);
    } else {
      message.destroy();
      message.error({
        content: val?.data?.responseHeader?.message || "Something went wrong please try again!",
        className: "custom-msg",
        duration: 2,
      });
    }
    setIsLoading(false);
  }).catch((err) => {
    setIsLoading(false);
  })
}
const selectionType = {
  onChange: (selectedRowKeys, selectedRows) => {
    setSelectionList(selectedRows);
    //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
};

const transformData = (data, keyy) => {
  const filteredData = data?.filter((ele) => ele.key === keyy);
  return filteredData[0]?.value?.map((item, index) => {
    let obj;

    if(keyy==='CALL_TYP'){
      obj= {
        ...item,
        label: item.mstDesc,
        value: item.mstID,
        //isCallType:true
      }
    }else if(keyy==='SUB_TYP'){   
      obj = {
        ...item,
        label: item.mstDesc,
         value: item.mstID,
        //isSubType:true
      }
          }else{
      obj = {
        ...item,
        label: item.mstDesc,
        value: item.mstID,
      }
    }
    return obj
  }
  );
};

  const getCTST=() => {
    setIsLoading(true);
    let obj =
    {
      "MasterRequest": [
          "CALL_TYP","SUB_TYP","CASE_STATUS","CLIENTIDTYPE"
      ]
  }
    let CTST = apiCalls.ctst(obj);
    CTST.then((val)=>{
      setMasterData(val.data);
      const transformedData1 = transformData(val.data, "CALL_TYP");
      let data = val.data?.filter((ele) => ele.key === "CALL_TYP");
      let transformedData = data[0]?.value.filter((ele)=> ele.mstID === 24).map((item) => ({
        ...item,
        label: item.mstDesc,
        value: item.mstID
      }));
      let caseStatus = val.data?.filter((ele) => ele.key === "CASE_STATUS");
      let caseStatusData = caseStatus[0]?.value.map((item) => ({
        ...item,
        label: item.mstDesc,
        value: item.mstID
      }));
      let clientIDs = val.data?.filter((ele) => ele.key === "CLIENTIDTYPE");
      let clientIDData = clientIDs[0]?.value.map((item) => ({
        ...item,
        label: item.mstDesc,
        value: item.mstID
      }));
      setCallTypeLU(transformedData);
      setCALL_TyPES(transformedData1);
      setCaseStatusLU(caseStatusData);
      setClientIDLU(clientIDData);
     // setIsLoading(false);
    }).catch((err)=>{
     // setIsLoading(false);
      message.destroy()
      message.error({
        content: err?.data?.responseBody?.errormessage,
        className: "custom-msg",
        duration: 2,
      });
    })

  };

  const getUserdData = async () => {
    setIsLoading(true);
    let response = apiCalls.GetPOSExecRoles();
    response
      .then((val) => {
        if (val?.data) {
          setUsersListLU(val?.data);
        } else {
          message.destroy();
          message.error({
            content:
              val?.data?.responseHeader?.message ||
              "Something went wrong please try again!",
            className: "custom-msg",
            duration: 2,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleCallTypeChange = (value) => {
    setSelectedCallType(value);
    setSubTypeLU([]);
    setSelectedSubType(null);
    subTypeDropdown(value);
    form.setFieldsValue({subType: null})
    // getGridData(value,null,selectedCaseType);
  };

  const subTypeDropdown =async (value)=>{
    let SUB_TYP = masterData?.filter((ele) => ele.key === "SUB_TYP");
    let data = SUB_TYP[0]?.value?.filter((ele) => ele?.mstParentID === value);
    let transformedData = data?.map((item) => ({
      ...item,
      label: item.mstDesc,
      value: item.mstID
    }));
    setSubTypeLU(transformedData);
  }

  const handleSubTypeChange = (e)=>{
    setSelectedSubType(e)
    getGridData(selectedCallType,e,selectedCaseType);
  }
  const handleCaseStatusChange =(e)=>{
    setSelectedCaseType(e);
    getGridData(selectedCallType,selectedSubType,e);
  }

  const onChange = (value) => {
    //console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
   // console.log('search:', value);
  };
  const handleUserNameChange = (e)=>{
    setSelectedUserName(e);
  }
 
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const getAdminData = async () => {
      //setIsLoading(true);
      let response = apiCalls.GetSerReqStatus(22);
      response
        .then((val) => {
          if (val?.data) {
            setCountData(val?.data?.serReqStatus);
            if(val?.data?.posAdminRoles?.length>0){
              setUsersListLU(val?.data?.posAdminRoles);
            }
          } else {
            message.destroy();
            message.error({
              content:
                val?.data?.responseHeader?.message ||
                "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          }
         // setIsLoading(false);
        })
        .catch((err) => {
          //setIsLoading(false);
        });
    };

    
    const getGridData = async (callType,subType,caseStatus,PolicyNo,SRequestID,complaintsType,userName, IGMSstatus) => {
      setIsLoading(true);
      let response = apiCalls.GetSerReqByFilters(24,subType,caseStatus,PolicyNo,SRequestID,complaintsType,userName, 23, IGMSstatus);
      response
        .then((val) => {
          if (val?.data) {
            const newData = val.data?.map((item, i) => ({
              ...item, // Spread the existing properties of the item
              key: i,  // Add a new property 'key' with the index value
            }));
            setData(newData);
          } else {
            message.destroy();
            message.error({
              content:
                val?.data?.responseHeader?.message ||
                "Something went wrong please try again!",
              className: "custom-msg",
              duration: 2,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };


    const saveAssignTo = async () => {
      // if(selectionList?.length === 0){
      //   message.destroy();
      //   message.warning("Please select atleast one record");
      //   return;
      // }
      // else 
      if(!selectedUserName){
        message.destroy();
        message.warning("Please select user name");
        return;
      }
      const filteredList = usersListLU?.filter(value => {
        if (value && value?.usrID === selectedUserName) {
          return value.roleID;
        } else {
          return null;
        }
      });
      let obj = {
        "SrvReqID": null,
        "UsrID": selectedUserName ||null,
        "RoleID":  22,
        "AllocatedOn": new Date(),
        "ClosedOn": null,
        "BranchID": null,
        "ReqSignedOn": null
      }
          let mappedObjects = selectionList.map((item, i) => ({
            ...obj, 
            "SrvReqID": item.srvReqID,
            "UsrID": selectedUserName,
            "ReqSignedOn": new Date(),
          }));
          setIsLoading(true);
      let response = apiCalls.saveAssignToPOS(mappedObjects);
      response
        .then((val) => {
          if (val?.data) {
            setUserAssigned(true);
          // window.location.reload();
        // After successful save, clear the selected rows
      setSelectionList([]);
      setSelectedRowKeys([]);
      setIsShowAssignCases(false);

      // Force remount of the Table component to clear the selection
      setTableKey((prevKey) => prevKey + 1);
          getCTST();
          getAdminData();
          getGridData();
           message.success("Users Assigned Successfully");
          } else {
            message.destroy();
            message.error({
              content:
                val?.data ||
                "Something went wrong please try again!",
              className: "custom-msg",
              duration: 3,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };

  const searchData=()=>{
    const formData = form.getFieldsValue(); 
    let status=formData?.status || ""
    let complaintsType=formData?.complaintsType || ""
    let PolicyNo=formData?.PolicyNo || "";
    let userName=formData?.userName || "";
    let SRequestID=formData?.serviceRequestId || ""
    let IGMSstatus = formData?.IGMSstatus
    getGridData(selectedCallType,null,status,PolicyNo,SRequestID,complaintsType,userName, IGMSstatus);
    form.resetFields(); 
     setIsAdvanceSearchModalOpen(false);
  }

  const handleCloseAdvance = () => {
    form.resetFields(); 
    setIsAdvanceSearchModalOpen(false);
  };

  const handleAssignCases = () => {
    setIsShowAssignCases(true);
  };

  const handleViewUserAgeing = () => {
    setShowAgeingModal(true);
    GetAgeingCount();
  };

  return (
    <>
      <div className='w-94'>
      <Spin spinning={isLoading}>
      {/* <Row gutter={[16,16]} className='admin-cards'>
        <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
          <Card className="resolved-card" bordered={false}>
            <p className="resolved-text">Resolved</p>
            <div>{countData?.resloved || 0}</div>
          </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
          <Card className="escalated-card" bordered={false}>
            <p className="resolved-text">Escalated</p>
            <div>{countData?.escalated || 0}</div>
          </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
          <Card className='pending-card' bordered={false}>
            <p className="resolved-text">Pending</p>
            <div>{countData?.pending || 0}</div>
          </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xxl={6}>
          <Card className="newrequest-card" bordered={false}>
            <p className="resolved-text">New Request</p>
            <div>{countData?.new_Request || 0}</div>
          </Card>
          </Col>
        </Row> */}
        <Form
          form={form}
          name="wrap"
          labelCol={{
            flex: "35%",
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={false}
          //onFinish={handleSubmit}
          autoComplete="off"
        >
          {/* <Row gutter={[16,16]} className='dashboard-filters'>
            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
              <Form.Item
                label="Call Type"
                name="callType"
                className="input-label mb-0"
              >
                <Select
                  showSearch
                  placeholder="Select a Call Type"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={callTypeLU}
                  onChange={(e) => handleCallTypeChange(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
              <Form.Item
                label="Sub Type"
                name="subType"
                className="input-label mb-0"
              >
                <Select
                  showSearch
                  placeholder="Select a Sub Type"
                  optionFilterProp="children"
                  onChange={(e) => handleSubTypeChange(e)}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={subTypeLU}
                />
              </Form.Item>
            </Col>
          
            <Col xs={12} sm={12} md={4} lg={4} xxl={4}>
              <Form.Item
                label="User Name"
                name="userName"
                className="input-label mb-0"
              >
                <Select
                  showSearch
                  placeholder="Select a User Name"
                  optionFilterProp="children"
                  onChange={(e)=>handleUserNameChange(e)}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  style={{ width: '100%' }}  // Set width to 100%
                >
                   {usersListLU?.map((users, idx) => (
                    <Option key={idx} value={users?.usrID}>
                      {users?.userName}
                    </Option>
                  ))}
                  </Select>
              </Form.Item>
    
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} xxl={1}>
            <span className='assignto-icon'>
              <span className="editIcon c-pointer"> <i  onClick={() => saveAssignTo()} className="bi bi-send"></i></span>
              </span>
            </Col>
          </Row> */}
            <Row gutter={[16, 16]} className="mb-16">
            <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
              <table className="table table-bodered h-100">
                <thead>
                  <tr >
                    <th colSpan={2} className="pl-24 text-center" >Complaints Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-24">Potential Complaint</td>
                    <td className="p-24 text-red">{complaintCount[0]?.potentionalComplaint || 'XX'}</td>
                    
                  </tr>
                  <tr>
                    <td className="p-24">IGMS Complaint</td>
                    <td className="p-24 text-red">{complaintCount[0]?.igmsComplaint || 'XX'}</td>
                  </tr>
                
                </tbody>
              </table>
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xxl={11}>
              <table className="table table-bodered h-100">
                <thead>
                  <tr>
                    <th colSpan="6" className="pl-24 text-center">IGMS Complaints Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-24">New</td>
                    <td className="p-24 text-red">{complaintCount[0]?.newTicketsCount || 'XX'}</td>
                    <td className="p-24">Attended To</td>
                    <td className="p-24 text-red">XX</td>
                    <td className="p-24">Closed </td>
                    <td className="p-24 text-red">XX</td>
                  </tr>
                  <tr>
                    <td className="p-24">Acknowledged</td>
                    <td className="p-24 text-red">{complaintCount[0]?.acknowledgedTicketsCount || 'XX'}</td>
                    <td className="p-24">Escalated</td>
                    <td className="p-24 text-red">XX</td>
                    <td className="p-24"></td>
                    <td className="p-24 text-red"></td>
                  </tr>
                  <tr>
                    <td className="p-24">Pending</td>
                    <td className="p-24 text-red">{complaintCount[0]?.pendingTicketsCount || 'XX'}</td>
                    <td className="p-24">Re-Open</td>
                    <td className="p-24 text-red">XX</td>
                    <td className="p-24"></td>
                    <td className="p-24 text-red"></td>
                  </tr>


                </tbody>
              </table>

            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xxl={4}>
            <div className="button-container mt-5">
              <p className="complain-ageing" onClick={handleViewUserAgeing}>View User Ageing Bucket</p>
              <Button
                type="primary"
                className="primary-btn mt-5"
                onClick={() => setIsAdvanceSearchModalOpen(!isAdvanceSearchModalOpen)}
              >
                Advance Search
              </Button>

              <Button
                      type="primary"
                      className="primary-btn move-search"
                      onClick={handleAssignCases}
              >
              Assign Cases
              </Button>

              {/* <Form.Item
                    label="Assign To"
                    name="userName"
                    className="input-label mb-0"
                  >
                    <Select
                      showSearch
                      placeholder="Select a User Name"
                      optionFilterProp="children"
                      onChange={(e)=>handleUserNameChange(e)}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      style={{ width: '100%' }}  // Set width to 100%
                    >
                      {usersListLU?.map((users, idx) => (
                        <Option key={idx} value={users?.usrID}>
                          {users?.userName}
                        </Option>
                      ))}
                      </Select>
                  </Form.Item> */}

            </div>
            </Col>
          </Row>

          <Modal
            title={<span style={{ color:"#b21f1f", fontWeight: 'bold' }}>Ageing Count</span>}
            open={showAgeingModal}
            destroyOnClose={true}
            closeIcon={
              <Tooltip title="Close">
                <span onClick={() => setShowAgeingModal(false)}>
                  <img src={CloseIcon} alt=""></img>
                </span>
              </Tooltip>
            }
            footer={null}
            width={900}
            height={500}
            className='mt-62'
            >
            <div className='ageing-modal'>
              <table>
                <thead className='border-1'>
                  <th className='ageing-modal-td'>User Name</th>
                  <th className='ageing-modal-td'>&lt;24 Hrs</th>
                  <th className='ageing-modal-td'>Less than 3 days</th>
                  <th className='ageing-modal-td'>Less than 5 days</th>
                  <th className='ageing-modal-td'>Less than 7 days</th>
                  <th className='ageing-modal-td'>7-15 days</th>
                  <th className='ageing-modal-td'>&gt;15 days</th>
                </thead>
                <tbody className='border-1'>
                  {/* <tr>
                    <td>{ageingAdmindata?.userName}</td>
                          <td>{ageingAdmindata?.oneDay}</td>
                          <td>{ageingAdmindata?.lessThanThreeDays}</td>
                          <td>{ageingAdmindata?.lessThanFiveDays}</td>
                          <td>{ageingAdmindata?.lessThanSevenDays}</td>
                          <td>{ageingAdmindata?.sevenToFifteenDays}</td>
                          <td>{ageingAdmindata?.greaterthanFifteenDays}</td>
                  </tr> */}

                  {ageingAdmindata?.length > 0 ? (
                      ageingAdmindata.map((item, ind) => (
                        <tr key={ind}>
                          <td>{item?.userName}</td>
                          <td>{item?.oneDay}</td>
                          <td>{item?.lessThanThreeDays}</td>
                          <td>{item?.lessThanFiveDays}</td>
                          <td>{item?.lessThanSevenDays}</td>
                          <td>{item?.sevenToFifteenDays}</td>
                          <td>{item?.greaterthanFifteenDays}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <span>No Data Available</span>
                        </td>
                      </tr>
                    )}
                    
                  {/* <tr>
                    <td>user1</td>
                    <td>user1</td>
                    <td>user1</td>
                    <td>user1</td>
                    <td>user1</td>
                    <td>user1</td>
                  </tr> */}
                  
                </tbody>
              </table>
            </div>
            <button
      style={{
        margin:'10px',
        backgroundColor: "#b21f1f",
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setShowAgeingModal(false)}
    >
      Close
    </button>
          </Modal>

          <Modal
            title="Assign Cases"
            open={isShowAssignCases}
            destroyOnClose={true}
            closeIcon={
              <Tooltip title="Close">
                <span onClick={() => setIsShowAssignCases(false)}>
                  <img src={CloseIcon} alt=""></img>
                </span>
              </Tooltip>
            }
            footer={null}
          >
            <div>
            <Form.Item
                        label="Assigned To"
                        name="assignedTo"
                        className="inputs-label mb-0"
                      >
                        <Select
                        showSearch
                        placeholder="Select a Assigned To"
                        optionFilterProp="children"
                        onChange={(e)=>handleUserNameChange(e)}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        style={{ width: '100%' }}  // Set width to 100%
                      >
                          {
                          usersListLU?.map((users,idx)=>{
                            return <Option key={idx} value={users?.usrID}>{users?.userName}</Option>
                          })
                        }
                        </Select>
                        <div className="text-center modal-validate">
                  <Button
                    type="primary"
                    className="primary-btn"
                    onClick={() => saveAssignTo()}
                  >
                  Submit
                  </Button>
                  <Button
                    type="primary"
                    className="primary-btn"
                    onClick={() => setIsShowAssignCases(false)}
                  >
                  Cancel
                  </Button>
            </div>
                      </Form.Item>
            </div>
          </Modal>

        </Form>
      
      <div className="main-start">
        <div className="w-94">
          <div className="table-container dashboard">
          {/* <Table
           key={tableKey}
            //  rowSelection={{
            //   type: 'checkbox',
            //   ...selectionType,
            // }}
          rowKey={(record) => record.key}
          hideSelectAll={false}
        columns={columns}
        dataSource={data}
        rowClassName={() => 'editable-row'}
        //bordered={true}
       // x={true}
        pagination={{
          //pageSizeOptions: ["5", "10", "15", "15"],
          pageSize: 5,
          //showSizeChanger: true,
          defaultPageSize: 5,
         // size:"small",
           total: {showTotalPages},
          //showTotal: `Total ${showTotalPages} items`
        }}
      /> */}

          <Table
            //   rowSelection={{
            //     type: 'checkbox',
            //     ...selectionType,
            //   }}
          //  rowSelection={{ ...rowSelection, type: 'checkbox' }}
            //   rowSelection={{
            //     hideSelectAll: true, // hide the checkbox in the header
            //   }}
        
            //rowKey={(record) => record.key}
              columns={columns}
              dataSource={data}
              locale={{
                emptyText: 'No Data Available',
              }}
              //bordered={true}
              x={true}
              pagination={{
                //pageSizeOptions: ["5", "10", "15", "15"],
                pageSize: 10,
                //showSizeChanger: true,
                defaultPageSize: 5,
                // size:"small",
                total: showTotalPages,
                //showTotal: `Total ${showTotalPages} items`
              }}
            />

      </div>
      </div>
      </div>
      </Spin>
      </div>
      <Modal
        title="Advance Search"
        open={isAdvanceSearchModalOpen}
        destroyOnClose={true}
        width={500}
        closeIcon={
            <Tooltip title="Close">
              <span onClick={() => handleCloseAdvance()}>
                <img src={CloseIcon} alt=""></img>
              </span>
            </Tooltip>
          }
        footer={null}
      >
     
     {/* <Card title="Apply Filters" className="mb-16 text-center"> */}
  <Form
    name="wrap"
    labelCol={{
      flex: "35%",
    }}
    labelAlign="left"
    labelWrap
    wrapperCol={{
      flex: 1,
    }}
    colon={false}
    form={form}
    onFinish={searchData}
    autoComplete="off"
  >
    <Row gutter={[12, 12]} className="mb-16">
   
     
        <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>

        <Form.Item
          name="PolicyNo"
          label="Policy No"
          className="inputs-label mb-0"
          rules={[
            {
              required: false,
              message: "Enter Policy No",
            },
          ]}
        >
          <Input
            placeholder="Enter Policy No"
            className="cust-input modal-input"
            maxLength={100}
          />
        </Form.Item>
      </Col>
      <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
    <Form.Item
          name="serviceRequestId"
          label="Service Request ID"
          className="inputs-label mb-0"
          rules={[
            {
              required: false,
              message: "Enter Service Request ID",
            },
          ]}
        >
          <Input
            placeholder="Enter Service Request ID"
            className="cust-input modal-input"
            maxLength={100}
          />
        </Form.Item>
        </Col>
      <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  label="Call Type"
                  name="callType"
                  className="inputs-label mb-"
                >
                  <Select
                    showSearch
                    allowClear={true}
                    className="cust-input"
                    maxLength={100}
                    placeholder="Select Call Type"
                    onSearch={onSearch}
                    options={callTypeLU}
                    filterOption={filterOption}
                    onChange={(value, option) => handleCallTypeChange(value, option)}
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  label="Sub Type"
                  name="subType"
                  className="inputs-label mb-0 subtype right-colsize"
                >
                  <Select
                    showSearch
                    allowClear={true}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Sub Type"
                    onSearch={onSearch}
                    options={subTypeLU}
                    filterOption={filterOption}
                    //onChange={(e) => {handleSubTypeChange(e); }}
                  ></Select>
                      
                </Form.Item>
          
              </Col>
              <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  label="Complaints Type"
                  name="complaintsType"
                  className="inputs-label mb-0"
                >
                  <Select
                    showSearch
                    allowClear={true}
                    className="cust-input"
                    maxLength={100}
                    placeholder="Select Complaints Type"
                    options={complaintTypeLU}
                  ></Select>
                </Form.Item>
              </Col>
              <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  label="Status"
                  name="status"
                  className="inputs-label mb-0 subtype right-colsize"
                >
                  <Select
                    showSearch
                    allowClear={true}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Status"
                    options={statusLU}
                  ></Select>
                      
                </Form.Item>
                <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  label="IGMS Status"
                  name="IGMSstatus"
                  className="inputs-label mb-0 subtype right-colsize"
                >
                  <Select
                    showSearch
                    allowClear={true}
                    className="cust-input calltype-select"
                    maxLength={100}
                    placeholder="Select Status"
                    options={statusIGMSLU}
                  ></Select>
                      
                </Form.Item>
                </Col>
          
              </Col>
              <Col className="m-10" xs={24} sm={24} md={24} lg={24} xxl={24}>
                <Form.Item
                  label="Assigned To"
                  name="assignedTo"
                  className="inputs-label mb-0"
                >
                  <Select
                    showSearch
                    allowClear={true}
                    className="cust-input"
                    maxLength={100}
                    placeholder="Select Assigned To"
                    options={requestModeLU}
                  ></Select>
                </Form.Item>
              </Col>
     
    
      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
        <Form.Item className="mb-0">
          <div className="d-flex justify-end">
          <Button
            type="primary"
            className="primary-btn mr-12"
            htmlType="submit"
          >
            Search
          </Button> { " "}
                </div>
        </Form.Item>
      </Col>
    </Row>
  </Form>
{/* </Card> */}

      </Modal>
    </>
  );
}

export default ComplaintsDashboard;
